<div class="modal-header">
  <h4 class="modal-title" [ngSwitch]="action">
    Modification en masse d'équipements
  </h4>
</div>

<div class="modal-body">
  <div class="alert alert-info" role="alert">Vous êtes sur le point de modifier {{ selectedCount }} équipement(s)</div>
  <form class="form-horizontal" [formGroup]="formGroup">
    <div [ngSwitch]="action">

      <div *ngSwitchCase="'owner'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Propriétaire <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <select class="form-control" formControlName="owner">
              <option *ngFor="let opt of ownerOptions | keyvalue" [ngValue]="opt.key">{{ opt.value }}</option>
            </select>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'equipmentModel'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Modèle d'équipement<i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-equipment-model-field class="wcm-highlight-req" formControlName="model"></app-equipment-model-field>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'reservedFor'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Réservé pour<i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-entity-field formControlName="reservedFor"
                              [filters]="reservedForList.filters"
                              [disabledButtons]="reservedForList.disabledButtons">
            </app-entity-field>
          </div>
        </div>
      </div>

      <div *ngSwitchCase="'equipmentLocation'">
        <div class="form-group">
          <label class="col-sm-3 control-label">
            Site Logistique <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-logistics-site-field class="wcm-highlight-req" formControlName="logisticsSite">
            </app-logistics-site-field>
          </div>
        </div>

        <div class="form-group">
          <label class="col-sm-3 control-label">
            Emplacement <i class="required">*</i>
          </label>
          <div class="col-sm-9">
            <app-equipment-location-field class="wcm-highlight-req"
                                          formControlName="equipmentLocation"
                                          [filters]="equipmentLocationOptions.filters"
                                          [disabledColumns]="equipmentLocationOptions.disabledColumns">
            </app-equipment-location-field>
          </div>
        </div>
      </div>

    </div>
  </form>
</div>

<div class="modal-footer">
  <button class="btn btn-primary" [disabled]="formGroup.invalid" (click)="confirm()">Confirmer</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>
