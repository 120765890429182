<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Relations</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Lien opérateur
        </label>
        <div class="col-sm-4">
          <app-operator-line-field class="wcm-highlight-req" required="true"
                                  [disabled]="mode === 'normal' || !entity.code || formIsReadOnly"
                                  [filters]="{entity__code: entity.code}"
                                  [customLink]="true" [mode]="mode"
                                  name="operatorLine" [(ngModel)]="operatorLine"
                                  (ngModelChange)="onOperatorLineChange(operatorLine)">
          </app-operator-line-field>
        </div>
      </div>

      <div class="form-group" *hasPermissions="'Antoine:ProviderTraceabilityFinanceCanEdit'">
        <label class="col-sm-2 control-label">
          Service fournisseur
        </label>
        <div class="col-sm-4">
          <app-provider-traceability-field class="wcm-highlight-req" name="provider_traceability"
                                           [modalDisplay]="true" [disabled]="mode === 'normal'"
                                           [(ngModel)]="operatorLine.provider_traceability"
                                           *ngIf="operatorLine?.provider_traceability">
          </app-provider-traceability-field>
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-notify-client [woi]="woi" [operatorLine]="operatorLine" [mode]="mode"></app-notify-client>
    </div>

    <div class="field-container">
      <div class="page-header header-xs">
        <h4>Informations fournies par le client</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Raison sociale
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{entity.name}}</p>
        </div>

        <label class="col-sm-2 control-label">
          Adresse d'installation
        </label>
        <div class="col-sm-4">
          <p class="form-control-static greyed-readonly">{{locationStr}}</p>
        </div>
      </div>
    </div>

    <div class="field-container" *ngIf="woi.metadata.order_ref">
      <div class="page-header header-xs">
        <h4>Suivi</h4>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Référence de prestation
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{refPrestation}}</p>
        </div>

        <label class="col-sm-2 control-label">
          Référence de commande
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.order_ref}}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Date de commande
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.order_date | amParse: 'YYYY-MM-DDTHH:mm:ss' | amDateFormat: 'L LT'}}</p>
        </div>

        <label class="col-sm-2 control-label">
          Dernière mise à jour
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.last_updated | amParse:'YYYY-MM-DDTHH:mm:ss' | amDateFormat:'L LT'}}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Date de rendez-vous
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.appointment_date | amDateFormat: 'L LT'}}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Constitutions
        </label>
        <div class="col-sm-4">
          <p class="form-control-static">{{woi.metadata.fci_details?.OPABPNRESULT?.value}}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Suivi opérateur
        </label>
        <div class="col-sm-10">
          <p class="form-control-static" style="white-space: pre-line;">{{providerComments}}</p>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Commentaires pilote
        </label>
        <div class="col-sm-10">
          <p class="form-control-static" style="white-space: pre-line">{{pilotComments}}</p>
        </div>
      </div>
    </div>

    <div class="field-container">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Opérateur <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true"
                  name="provider" [(ngModel)]="woi.metadata.provider"
                  [disabled]="mode === 'normal' || formIsReadOnly">
            <option value="orange">Orange France</option>
          </select>
        </div>

        <label class="col-sm-2 control-label">
          Type de lien <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                  name="technology" [(ngModel)]="technology" (ngModelChange)="onTechnologyChange()">
            <optgroup label="SDSL_EFM">
              <option value="C2E">C2E</option>
              <option value="C2E_CN2">C2E_CN2</option>
            </optgroup>
            <optgroup label="SDSL_ATM">
              <option value="DSLE">DSLE</option>
            </optgroup>
          </select>
        </div>
      </div>

      <div class="form-group" *ngIf="technology && operatorLine">
        <label class="col-sm-2 control-label">
          Action <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <select class="form-control wcm-highlight-req" required="true" [disabled]="mode === 'normal'  || formIsReadOnly || filteredActions.length === 0"
                  name="action" [(ngModel)]="woi.metadata.action" (ngModelChange)="onActionChange()"
                  [compareWith]="compareActionFn">
            <option *ngFor="let option of filteredActions" [ngValue]="option">
              {{option.label}}
            </option>
          </select>
        </div>
      </div>

      <div *ngIf="woi.metadata.action?.value">
        <div class="form-group" *ngIf="woi.metadata.action?.value !== 'C'">
          <label class="col-sm-2 control-label">
            Référence de prestation (aka num_install) <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <input class="form-control wcm-highlight-req" required="true"
                   [readonly]="mode === 'normal' || formIsReadOnly" pattern="^[A-Z0-9]+$"
                   name="numInstall" [(ngModel)]="woi.metadata.num_install" />
          </div>
        </div>

        <div class="form-group" *ngIf="!['C', 'R'].includes(woi.metadata.action?.value)">
          <label class="col-sm-2 control-label">
            Référence de prestation ou ND de la ligne cédée (aka old_service_ref/presta_ou_ND_cession)<i class="required"></i>
          </label>
          <div class="col-sm-4">
            <input class="form-control wcm-highlight-req" required="true"
                   [readonly]="mode === 'normal' || formIsReadOnly" pattern="^[A-Z0-9]{1,10}$"
                   name="oldServiceRef" [(ngModel)]="woi.metadata.old_service_ref" />
          </div>
        </div>

        <div class="form-group" *ngIf="woi.metadata.action?.value === 'MSO' && technology !== 'DSLE'">
          <label class="col-sm-2 control-label">
            Type de modification <i class="required"></i>
          </label>
          <div class="col-sm-4">
            <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                    name="typeModif" [(ngModel)]="woi.metadata.type_modif">
              <option value="modif_acces_debit_ou_profil">Modification débit et/ou profil</option>
              <option value="modif_option_gtr_s1">Modification option GTR S1</option>
            </select>
          </div>
        </div>

        <div>
          <div class="form-group" *ngIf="technology !== 'C2E_CN2' ">
            <label class="col-sm-2 control-label">
              Offre <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly" required="true"
                      name="offer" [(ngModel)]="woi.metadata.offer" [compareWith]="compareOfferFn">
                <option *ngFor="let option of offers[technology]" [ngValue]="option">
                  {{option.label}}
                </option>
              </select>
            </div>
          </div>

          <div *ngIf="technology === 'DSLE' && woi.metadata.action?.value === 'C'">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Code département de collecte
              </label>
              <div class="col-sm-4">
                <input class="form-control" [readonly]="true" placeholder="Rempli automatiquement à la commande"
                       name="collectDeptCode" [(ngModel)]="woi.metadata.collect_dept_code" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Référence de collecte (Porte DSLE)
              </label>
              <div class="col-sm-4">
                <input class="form-control" [readonly]="true" placeholder="Rempli automatiquement à la commande"
                       name="collectRef" [(ngModel)]="woi.metadata.collect_ref" />
              </div>
            </div>
            <div class="form-group">
              <label class="col-sm-2 control-label">
                VLAN de collecte
              </label>
              <div class="col-sm-4">
                <input class="form-control" [readonly]="true" placeholder="Rempli automatiquement à la commande"
                       name="collectVlan" [(ngModel)]="woi.metadata.collect_vlan" />
              </div>
            </div>
          </div>

          <div class="form-group" *ngIf="technology !== 'DSLE' && woi.metadata.action?.value !== 'R'">
            <label class="col-sm-2 control-label">
              GTR Opérateur<i [ngClass]="woi.metadata.action?.value !== 'MSO' ? 'required': ''"></i>
            </label>
            <div class="col-sm-4">
              <select class="form-control wcm-highlight-req" [disabled]="mode === 'normal' || formIsReadOnly"
                      [required]="woi.metadata.action?.value !== 'MSO'"
                      name="gtr" [(ngModel)]="woi.metadata.gtr">
                <option value="GTR S1">GTR S1 7j/7 24h/24</option>
                <option value="GTR S2">GTR S2 6j/7 8h-18h</option>
                <option value="" *ngIf="woi.metadata.action?.value === 'MSO'">(Inchangé)</option>
              </select>
            </div>
          </div>

          <div class="form-group" *ngIf="technology && woi.metadata.action?.value === 'C'">
            <label class="col-sm-2 control-label">
              Desserte interne à réaliser par
            </label>
            <div class="col-sm-4">
              <select class="form-control" [disabled]="mode === 'normal' || formIsReadOnly"
                      name="desserteInterne" [(ngModel)]="woi.metadata.desserte_interne">
                <option value="orange_forfait">Orange sur forfait</option>
                <option value="installateur_prive">Installateur privé</option>
              </select>
            </div>
          </div>

          <div class="form-group" *ngIf="woi.metadata.action?.value !== 'R'">
            <label class="col-sm-2 control-label">
              Locaux disponibles au <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <div *ngIf="(mode === 'normal' || formIsReadOnly); else siteAvailabilityCalendar">
                <p class="form-control-static">{{woi.metadata.site_availability_date ? (woi.metadata.site_availability_date | amParse:'DD/MM/YYYY' | amDateFormat:'L') : null}}</p>
              </div>
              <ng-template #siteAvailabilityCalendar>
                <div class="input-group" style="width: 100%;">
                  <app-date-field name="siteAvailabilityDate" [(ngModel)]="siteAvailabilityDate"
                                  (ngModelChange)="onSiteAvailabilityDateChange()"
                                  [mediumSize]="true" [minDate]="minSiteAvailabilityDate">
                  </app-date-field>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button"
                            title="Sélectionner la date du jour" [disabled]="mode === 'normal'"
                            (click)="setDefaultSiteAvailabilityDate()">
                      Aujourd'hui
                    </button>
                  </span>
                </div>
              </ng-template>
            </div>
          </div>

          <div class="form-group" *ngIf="woi.metadata.action?.value !== 'R'">
            <label class="col-sm-2 control-label">
              Date désirée <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <div *ngIf="(mode === 'normal' || formIsReadOnly); else availabilityCalendar">
                <p class="form-control-static">{{woi.metadata.availability_date ? (woi.metadata.availability_date | amParse:'DD/MM/YYYY' | amDateFormat:'L') : null}}</p>
              </div>
              <ng-template #availabilityCalendar>
                <div class="input-group" style="width: 100%;">
                  <app-date-field name="availabilityDate" [(ngModel)]="availabilityDate"
                                  (ngModelChange)="onAvailabilityDateChange()"
                                  [mediumSize]="true" [minDate]="minAvailabilityDate">
                  </app-date-field>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button"
                            title="Sélectionner aujourd'hui + 15j" [disabled]="mode === 'normal'"
                            (click)="setDefaultAvailabilityDate()">
                      Aujourd'hui + 15j
                    </button>
                  </span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>

        <div *ngIf="woi.metadata.action?.value ==='R'">
          <div class="form-group">
            <label class="col-sm-2 control-label">
              Date désirée (Orange ne garantit pas une résiliation avant 7j) <i class="required"></i>
            </label>
            <div class="col-sm-4">
              <div *ngIf="(mode === 'normal' || formIsReadOnly); else terminationCalendar">
                <p class="form-control-static">{{woi.metadata.termination_date ? (woi.metadata.termination_date | amParse:'DD/MM/YYYY' | amDateFormat:'L') : null}}</p>
              </div>
              <ng-template #terminationCalendar>
                <div class="input-group" style="width: 100%;">
                  <app-date-field name="terminationDate" [(ngModel)]="terminationDate"
                                  (ngModelChange)="onTerminationDateChange()"
                                  [mediumSize]="true" [minDate]="minTerminationDate">
                  </app-date-field>
                  <span class="input-group-btn">
                    <button class="btn btn-default" type="button"
                            title="Sélectionner la date de demain" [disabled]="mode === 'normal'"
                            (click)="setDefaultTerminationDate()">
                      Demain
                    </button>
                  </span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-client-contact [woi]="woi" [mode]="mode"></app-client-contact>
    </div>

    <div class="field-container" *ngIf="woi.metadata.action?.value !== 'R'">
      <div class="page-header header-xs flex">
        <h4 class="flex-left">Adresse d'installation</h4>
        <button class="btn btn-sm btn-default flex-right" type="button"
                (click)="extractLocation()" *ngIf="mode === 'edition'"
                [disabled]="!woi.location">
          Copier depuis l'adresse client
        </button>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Voie <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req" type="text"
                 [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                 name="address" [ngModel]="woi.metadata.location?.address"
                 (ngModelChange)="woi.metadata.location.address = $event"/>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          Code postal <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req" type="text"
                 [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                 name="zipcode" [ngModel]="woi.metadata.location?.zipcode"
                 (ngModelChange)="woi.metadata.location.zipcode = $event"/>
        </div>

        <label class="col-sm-2 control-label">
          Ville <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req" type="text"
                 [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                 name="city" [ngModel]="woi.metadata.location?.city"
                 (ngModelChange)="woi.metadata.location.city = $event"/>
        </div>
      </div>

      <div class="form-group">
        <label class="col-sm-2 control-label">
          NDI <i class="required"></i>
        </label>
        <div class="col-sm-4">
          <input class="form-control wcm-highlight-req" [readonly]="mode === 'normal' || formIsReadOnly" required="true"
                 name="ndi" [ngModel]="woi.metadata.location?.phone"
                 [pattern]="frenchPhoneNumberPattern" (blur)="phoneNumberUpdatedOnBlur()"
                 (ngModelChange)="woi.metadata.location.phone = $event; phoneNumberUpdatedDebounced()" />
          <div class="error-message" *ngIf="f.controls.ndi?.errors?.pattern && mode === 'edition'">
            Format du NDI invalide. Le numéro doit contenir exactement 10 chiffres et commencer par un 0.
          </div>
        </div>

        <label class="col-sm-2 control-label">
          Portable
        </label>
        <div class="col-sm-4">
          <input class="form-control" [readonly]="mode === 'normal' || formIsReadOnly"
                 name="locationMobile" [ngModel]="woi.metadata.location?.mobile"
                 [pattern]="frenchPhoneNumberPattern" (blur)="phoneNumberUpdatedOnBlur()"
                 (ngModelChange)="woi.metadata.location.mobile = $event; phoneNumberUpdatedDebounced()" />
          <div class="error-message" *ngIf="f.controls.locationMobile?.errors?.pattern && mode === 'edition'">
            Format du numéro de portable invalide. Le numéro doit contenir exactement 10 chiffres et commencer par un 0.
          </div>
        </div>
      </div>
    </div>

    <div class="field-container">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Précisions complémentaires
        </label>
        <div class="col-sm-4">
          <input class="form-control" type="text" [readonly]="mode === 'normal' || formIsReadOnly" maxlength="1000"
                 name="comment" [(ngModel)]="woi.metadata.comment" />
        </div>
      </div>
    </div>

    <div class="field-container">
      <app-order-counter [woi]="woi"></app-order-counter>
    </div>

    <div>
      <app-waiting-list [woi]="woi" [mode]="mode" [woiRefreshDetail]="woiRefreshDetail" [metadataName]="metadataName"></app-waiting-list>
    </div>

  </form>
</div>
