import { Component, Input, OnInit, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { SignalsService } from '@core/services/signals.service';

import { EQP_LOCATIONS, DEPRECATED_EQUIPMENT_MODEL_TYPES, EQUIPMENT_MODEL_TYPES, FormMode } from '@core/constants';
import { IGenericListOptions, ITinyEquipment, IWorkOrderItems } from '@app/core/interfaces';
import { EquipmentsModalComponent } from '@views/equipments/equipments-modal.component';

interface IPneylu {
  equipments: ITinyEquipment[];
}

@Component({
  selector: 'app-pneylu-metadata',
  templateUrl: './pneylu-metadata.component.html',
  styleUrls: ['../work-order-items-detail-metadata.component.less']
})
export class PneyluMetadataComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public mode: FormMode = 'normal';
  @Input() public woi: IWorkOrderItems;
  @Input() public woiSave: Function; // woi save method to call on seq update

  public locationOptions: {[key: string]: string} = EQP_LOCATIONS;
  public eqpModelTypeOptions: {[key: string]: string};
  public loading: boolean;
  public items: ITinyEquipment[] = [];
  private eqpListOptions: IGenericListOptions;
  private metadataBackup: IPneylu;
  private woiEditionCancelledSignalHandler: Subscription;

  constructor(
    private signalsService: SignalsService,
    private ngbModal: NgbModal
  ) { }

  public ngOnInit(): void {
    this.woi.metadata = this.woi.metadata || {};

    this.woiEditionCancelledSignalHandler = this.signalsService.subscribe('woi-edition-cancelled', () => {
      this.woi.metadata = {
        ...this.woi.metadata,
        ...this.metadataBackup,
      };
      this._init();
    });

    this._init();
  }

  /**
   *  This function will be called for every input change, so the mode will trigger a change too,
   *   but we can't properly detect if the woi has changed because it's structure is too complex
   *  Handle the metadata update from the parent view (ex: 'cancel' action that does a backup)
   */
  public ngOnChanges(changes: SimpleChanges): void {
    const previousMode = changes?.mode?.previousValue;
    const currentMode = changes?.mode?.currentValue;

    if (previousMode === 'normal' && currentMode === 'edition') {
      this.metadataBackup = JSON.parse(JSON.stringify(this.woi.metadata));
    }

    // Check if the woi.metadata and his eqp list has been instantiated too late after the template
    //  and if it was, re-init the eqpList of the template
    const isNotFirstChangeOnWoi = (changes.woi && !changes.woi.firstChange);
    const isPrevWoiEqpListNotEmpty = changes?.woi?.previousValue?.metadata?.equipments?.length > 0;
    const isCurWoiEqpListNotEmpty = changes?.woi?.currentValue?.metadata?.equipments?.length > 0;
    if (isNotFirstChangeOnWoi && !isPrevWoiEqpListNotEmpty && isCurWoiEqpListNotEmpty) {
      this._initEqpList();
    }
  }

  public ngOnDestroy(): void {
    this.woiEditionCancelledSignalHandler.unsubscribe();
  }

  public addItems(): void {
    const entityCode = this.woi.work_order?.entity?.code;
    this.eqpListOptions.filters = {
      ...this.eqpListOptions.filters,
      entity__code: entityCode,
      id__nin: this.items.map(item => item.id) || null
    };

    const modal = this.ngbModal.open(EquipmentsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledButtons = this.eqpListOptions.disabledButtons;
    modal.componentInstance.disabledColumns = this.eqpListOptions.disabledColumns;
    modal.componentInstance.filters = this.eqpListOptions.filters;
    modal.componentInstance.isMultiselectListModal = true;

    modal.result.then(
      equipments => {
        const eqpArr = Object.keys(equipments).map(index => equipments[index]);
        this.items = [...this.items, ...eqpArr];
        this._updateMetadataWithEqpItems();
        this.woiSave();
      },
      () => {});
  }

  public delete(index: number): void {
    this.items.splice(index, 1);
    this._updateMetadataWithEqpItems();
    this.woiSave();
  }

  private _init(): void {
    this.eqpListOptions = {
      filters: { limit: 10 },
      disabledButtons: {
        create: true,
        delete: true,
        export: true,
        exactSearch: true,
        assign: true,
        history: true,
        assignTags: true
      },
      disabledColumns: {
        selection: false,
        model__branded_name:  true,
        entity__parent__name:  true,
        entity__parent__code:  true,
        entity__code:  true,
        entity__customer_ref:  true,
        entity__location__city:  true,
        reserved_for__name:  true,
        reserved_for__code:  true,
        provider_order_number__or__provider_order__order_number:  true,
        created_at:  true,
        last_stock_activity:  true,
        mac_address:  true,
        owner:  true,
        model__type_label: false,
        has_accounting_equipment:  true,
        price_untaxed:  true,
        tags:  true,
        action:  true
      }
    };

    this._initEqpList();
    this._initEqpModelTypeList();
  }

  private _initEqpList() {
    this.items = this.woi.metadata.equipments as ITinyEquipment[] || [];
  }

  private _initEqpModelTypeList() {
    this.eqpModelTypeOptions = {};
    const typeList = [...EQUIPMENT_MODEL_TYPES, ...DEPRECATED_EQUIPMENT_MODEL_TYPES].sort();
    typeList.forEach((typeStr: string) => this.eqpModelTypeOptions[typeStr] = typeStr);
  }

  private _updateMetadataWithEqpItems(): void {
    this.woi.metadata.equipments = [];
    this.items.forEach(item => {
      const tinyItem: ITinyEquipment = {
        id: item.id,
        code: item.code,
        model: {
          name: item.model.name || null,
          type_label: item.model.type_label || null,
          brand: {
            name: item.model.brand.name || null
          }
        },
        serial_number: item.serial_number || null,
        entity: {
          code: item.entity.code || null,
          name: item.entity.name || null
        },
        location: item.location || null
      };
      (this.woi.metadata.equipments as ITinyEquipment[]).push(tinyItem);
    });
  }

}
