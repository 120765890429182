<app-wcm-table #wcmTable [api]="apiShiva.equipment_models" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="equipment-models-list-refresh" urlUpdateSignal="equipment-models-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="equipment-models"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Modèles d'équipement" targetRouterLink="/equipment-models/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <button type="button" class="btn btn-default" (click)="toggleExactSearch()">
      <i class="fa" [ngClass]="exactSearch ? 'fa-toggle-on' : 'fa-toggle-off'" aria-hidden="true"></i>
      Recherche exacte
    </button>

    <button type="button" class="btn btn-default" (click)="exportResults()" *ngIf="hasPermissions('Antoine:ExportAdmin')">
      <i class="far fa-file-excel" aria-hidden="true"></i>
      Exporter
    </button>
    <button class="btn btn-default" (click)="create()"
            *ngIf="!localDisabledButtons.create && hasPermissions('Wira:EqpAdmin')">
      <i class="fas fa-plus"></i> Ajouter un modèle d'équipement
    </button>
  </ng-template>

  <app-wcm-col id="detail" label="Détail" type="none" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="Détail" link="/equipment-models/detail/{{item.id}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="type_label" header="Type" class="text-center"
                type="select" [additionalArgs]="{options: typeOptions}">
  </app-wcm-col>

  <app-wcm-col field="name" header="Nom {{exactSearch ? '(exact)' : ''}}" class="text-center" [focus]="true">
    <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
      <div *ngIf="exactSearch; then exactSearchBlock else searchBlock"></div>
      <ng-template #exactSearchBlock>
        <input type="text" class="input-sm form-control" [(ngModel)]="filters['exact_name']" (ngModelChange)="dt.filter(filters['exact_name'], 'exact_name', null)" name="exactName">
      </ng-template>
      <ng-template #searchBlock>
        <input type="text" class="input-sm form-control" [(ngModel)]="filters['name']" (ngModelChange)="dt.filter(filters['name'], 'name', null)" name="name">
      </ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="branded_name" header="Réf. constructeur" class="text-center"></app-wcm-col>

  <app-wcm-col field="brand__name" header="Constructeur" class="text-center"></app-wcm-col>

  <app-wcm-col field="category__label" header="Catégorie" class="text-center"
                type="select" [additionalArgs]="{options: categoryOptions}">
  </app-wcm-col>

  <app-wcm-col field="ref_externe_erp" header="Référence ERP" class="text-center" type="advancedText" width="130">
    <ng-template appWcmColBody let-item="item">
      {{item.ref_externe_erp}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="os" header="OS" class="text-center" type="select" [additionalArgs]="{options: osOptions}"></app-wcm-col>

  <app-wcm-col field="template_builder_code" header="Code template builder" class="text-center"></app-wcm-col>

  <app-wcm-col field="last_stock_activity" header="Dernière activité" class="text-center" type="date"></app-wcm-col>


  <app-wcm-col field="stock_alert" sortField="stock_count" header="Stock" type="select" class="text-center"
              [additionalArgs]="{options: stockOptions}" width="90">
    <ng-template appWcmColBody let-item="item">
      <app-equipment-models-stock [equipmentModel]="item"></app-equipment-models-stock>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="stock_threshold" header="Seuil d'alerte" class="text-center" type="none"></app-wcm-col>

</app-wcm-table>
