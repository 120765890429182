<ul class="nav navbar-nav" data-toggle="collapse" data-target="#topLevelNavbar.in">
  <li class="dropdown" [ngClass]="getIsCurrentClass('/personal-menus')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" data-cy="personalMenusMenu">
      Mes menus <span class="caret"></span>
    </a>
    <ul class="dropdown-menu" role="menu" data-cy="personalMenuDropdownMenu">
      <li>
        <a routerLink="/personal-menus/list" data-cy="personalMenusList">
          Éditer
        </a>
      </li>

      <li role="separator" class="divider" *ngIf="personalMenuList.length > 0"></li>

      <ng-container *ngFor="let menu of personalMenuList">
        <li class="dropdown-submenu" *ngIf="menu.items?.length > 0">
          <a class="dropdown-toggle" data-toggle="dropdown" role="button">
            {{menu.label}}
          </a>

          <ul class="dropdown-menu" role="menu" *ngIf="menu.items?.length > 0">
            <li>
              <a [routerLink]="'/personal-menus/detail/' + menu.code">
                Gérer
              </a>
            </li>
            <li role="separator" class="divider" *ngIf="menu.items?.length > 0"></li>
            <li *ngFor="let item of menu.items">
              <a [href]="item.url">
                {{item.label}}
              </a>
            </li>
          </ul>
        </li>
      </ng-container>
    </ul>
  </li>

  <li class="dropdown" [ngClass]="getIsCurrentClass('/entities', '/locations', '/contacts', '/entity')"
      *ngIf="hasPermissions('Antoine:EntityCanDisplay', 'Antoine:TechnicianCanDisplay',
            'Antoine:LocationCanDisplay', 'Antoine:ContactCanDisplay')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="entitiesMenu">
      Entités <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="entitiesDropdownMenu">
      <li class="dropdown-header" *ngIf="hasPermissions('Antoine:EntityCanDisplay')">Entités<li>

      <li *ngIf="hasPermissions('Antoine:EntityCanDisplay')">
        <a routerLink="/entities/list" [queryParams]="{is_customer: true}" id="entitiesClientsList">
          Clients
        </a>
      </li>
      <li *ngIf="hasPermissions('Antoine:EntityCanDisplay')">
        <a routerLink="/entities/list"  [queryParams]="{parent__isnull: false}"  id="entitiesShopsList">
          Sites
        </a>
      </li>
      <li *ngIf="hasPermissions('Antoine:EntityCanDisplay')">
        <a routerLink="/entities/list" data-cy="entitiesList">
          Toutes les entités
        </a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:EntityCanDisable') && hasPermissions('Antoine:EntityCanDisplay')">
        <a routerLink="/disabled-entities/list" data-cy="deactivatedEntitiesList">
          Entités désactivées
        </a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:EntityCanMerge') && hasPermissions('Antoine:EntityCanDisplay')">
        <a routerLink="/entities-merge/" data-cy="entitiesMerge">
          Fusion d'entités
        </a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:EntityCanBulkImport') && hasPermissions('Antoine:EntityCanDisplay')">
        <a href="#/entity-bulk-imports/list" data-cy="entityBulkImportsList">
          Création de sites en masse
        </a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:EntityTypesCanManage') && hasPermissions('Antoine:EntityCanDisplay')">
        <a routerLink="/entity-types/list" data-cy="entityTypesList">Types d'entités</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Antoine:EntityCanDisplay')"></li>

      <li *ngIf="hasPermissions('Antoine:LocationCanDisplay')">
        <a routerLink="/locations/list" data-cy="locationsList">
          Adresses
        </a>
      </li>
      <li *ngIf="hasPermissions('Antoine:ContactCanDisplay')">
        <a routerLink="/contacts/list" data-cy="contactsList">
          Contacts
        </a>
      </li>
      <li *ngIf="hasPermissions('Antoine:TechnicianCanDisplay')">
        <a routerLink="/technicians/list" data-cy="techniciansList">
          Techniciens
        </a>
      </li>
    </ul>
  </li>

  <li class="dropdown" [ngClass]="getIsCurrentClass('/products', '/bill-of-materials', '/product-categories')" *ngIf="hasPermissions('Antoine:ProductCanDisplay')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="productsMenu">
      Produits <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="productsDropdownMenu">
      <li class="dropdown-header">
        Catalogues
      </li>

      <li>
        <a routerLink="/products/list"
            [queryParams]="{for_sale: 'true', is_active: 'true', ordering: 'name', col: 'internal_description'}" >
          Ventes
        </a>
      </li>
      <li>
        <a routerLink="/products/list"
            [queryParams]="{for_purchase: 'true', is_active: 'true', ordering: 'name'}">
          Achats
        </a>
      </li>
      <li>
        <a routerLink="/products/list" data-cy="productsList"
          [queryParams]="{is_active: 'true', ordering: 'name'}">
          Tous les produits
        </a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Wira:ProductCanConfigure', 'Wira:ProductCategoryCanConfigure')"></li>

      <li *ngIf="hasPermissions('Wira:ProductCanConfigure')">
        <a routerLink="/bill-of-materials/list" data-cy="billOfMaterialsList">
          Nomenclatures
        </a>
      </li>
      <li *ngIf="hasPermissions('Wira:ProductCategoryCanConfigure')">
        <a routerLink="/product-categories/list" [queryParams]="{ordering: 'name'}" data-cy="productCategoriesList">
          Catégories
        </a>
      </li>
    </ul>
  </li>

  <li class="dropdown"
      [ngClass]="getIsFinanceCurrentClass()"
      *ngIf="hasPermissions('Alexia:FinanceOrder', 'Alexia:FinanceInvoice', 'Alexia:FinanceBinder', 'Alexia:FinanceSage', 'Alexia:FinanceSendInvoice', 'Alexia:FinanceConfig')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="financeMenu">
      Finance <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="financeDropdownMenu">
      <li *ngIf="hasPermissions('Alexia:FinanceOrder') && hasPermissions('Antoine:OrderCanDisplay')">
        <a routerLink="/orders/list" data-cy="ordersList">Commandes (Finance)</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceInvoice')">
        <a routerLink="/invoices/list" [queryParams]="{type:'customer-refund', col:'-payment_status'}">Avoirs</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceInvoice')">
        <a routerLink="/invoices/list" [queryParams]="{type:'customer-invoice'}" data-cy="invoicesList">Factures</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceInvoice')">
        <a routerLink="/invoice-archives/list" data-cy="invoiceArchivesList">Anciennes Factures</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceInvoice')">
        <a routerLink="/billing-uploads/list" data-cy="billingUploadsList">Consommation Variable</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Alexia:FinanceBinder', 'Alexia:FinanceSage')"></li>

      <li class="dropdown-header" *ngIf="hasPermissions('Alexia:FinanceBinder', 'Alexia:FinanceSage')">
        Binders
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceBinder')">
        <a routerLink="/binders/list" [queryParams]="{ordering: '-created_at'}" data-cy="bindersList">Binders</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceBinder')">
        <a routerLink="/control-files/list" data-cy="controlFilesList">Fichiers de contrôle</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceSage')">
        <a href="#/invoice-sage-exports/list?ordering=-date" data-cy="sageExportsList">Exports Sage des factures</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceSage')">
        <a href="#/refund-sage-exports/list" data-cy="refundSageExportsList">Exports Sage des avoirs</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:InvoiceTransmissionCanDisplay')">
        <a routerLink="/invoice-transmissions/list" data-cy="invoiceTransmissionsList">Envoi Dynamics des factures</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasAccountingEquipmentPermission()"></li>

      <li *ngIf="hasPermissions('ShivaBackend:AccountingEquipmentAdmin', 'ShivaBackend:AccountingEquipmentLogistic')">
        <a routerLink="/accounting-equipments/list" data-cy="accountingEquipmentsList">Équipements comptables</a>
      </li>
      <li *ngIf="hasPermissions('ShivaBackend:ProviderOrderAdmin', 'ShivaBackend:ProviderOrderLogistic')">
        <a routerLink="/provider-orders/list" data-cy="providerOrdersList">Commandes fournisseur</a>
      </li>
      <li *ngIf="hasPermissions('ShivaBackend:ProviderInvoiceAdmin')">
        <a routerLink="/provider-invoices/list" data-cy="providerInvoicesList">Factures fournisseur</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Alexia:FinanceSendInvoice')"></li>

      <li class="dropdown-header" *ngIf="hasPermissions('Alexia:FinanceSendInvoice')">
        Tableaux de bord
      </li>

      <li *ngIf="hasPermissions('Alexia:FinanceSendInvoice')">
        <a routerLink="/invoice-send-events/list" data-cy="invoiceSendEventsList">Envois de factures</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Alexia:FinanceConfig')"></li>

      <li class="dropdown-header" *ngIf="hasPermissions('Alexia:FinanceConfig')">
        Configuration
      </li>

      <li *ngIf="hasPermissions('Alexia:FinanceConfig')">
        <a routerLink="/accounts/list" data-cy="accountsList">Comptes</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceConfig')">
        <a routerLink="/currencies/list" data-cy="currenciesList">Devises</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceConfig')">
        <a routerLink="/payment-terms/list" data-cy="paymentTermsList">Modalités de paiement</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceConfig')">
          <a routerLink="/payment-methods/list" data-cy="paymentMethodsList">Méthodes de paiement</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceConfig')">
        <a routerLink="/fiscal-positions/list" data-cy="fiscalPositionsList">Positions fiscales</a>
      </li>
      <li *ngIf="hasPermissions('Alexia:FinanceConfig')">
        <a routerLink="/taxes/list" data-cy="taxesList">Taxes</a>
      </li>
    </ul>
  </li>

  <li class="dropdown" [ngClass]="getIsCurrentClass('/quotes', '/price-books', '/opportunity-exports')"
       *ngIf="hasPermissions('EvaBackend:SalesUser','EvaBackend:ModifyPriceBook')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="salesMenu">
      Commerce <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="salesDropdownMenu">
      <li>
        <a routerLink="/quotes/list" data-cy="quotesList"
            [queryParams]="{ordering: '-created_at'}">Devis</a>
      </li>
      <li>
      <li *ngIf="hasPermissions('Antoine:OpportunityExport')">
        <a routerLink="/opportunity-exports/list">Export des opportunités</a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:ModifyPriceBook')">
        <a routerLink="/price-books/list" data-cy="priceBooksList">Conditions de vente</a>
      </li>
    </ul>
  </li>

  <li class="dropdown"
      [ngClass]="getIsProductionCurrentClass()">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="productionMenu">
      Production <span class="caret"></span>
    </a>

    <ul class="dropdown-menu multi-level" role="menu" data-cy="productionDropdownMenu">
      <li *ngIf="hasPermissions('Antoine:OrderCanDisplay')">
        <a routerLink="/orders-mrp/list" data-cy="ordersList">Commandes (Production)</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:WorkOrderCanDisplay')">
        <a routerLink="/work-orders/list" data-cy="workOrdersList">Ordres de production</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:TraceabilityCanDisplay')">
        <a routerLink="/traceabilities/list" data-cy="traceabilitiesList">Services</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:ProviderTraceabilityCanDisplay')">
        <a routerLink="/provider-traceabilities/list" data-cy="providerTraceabilitiesList">Services Fournisseurs</a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:ProjectCanEdit', 'Antoine:ProjectCanDisplay')">
        <a routerLink="/projects/list" data-cy="projectsList">Projets</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:LogisticsRequestCanDisplay')">
        <a routerLink="/logistics-requests/list" data-cy="logisticsRequestsList">Demandes logistiques</a>
      </li>

      <li role="separator" class="divider"></li>

      <li *ngIf="hasPermissions('Antoine:ProviderCanDisplay')">
        <a routerLink="/providers/list" data-cy="providersList">Fournisseurs</a>
      </li>

      <li *ngIf="hasPermissions('ShivaBackend:ContractorUser', 'ShivaBackend:ContractorAdmin') && hasPermissions('Antoine:ContractorCanDisplay')">
        <a routerLink="/contractors/list" data-cy="contractorsList">Prestataires</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:CanDisplayInterventionCalendar')">
        <a routerLink="/intervention-calendar/main" data-cy="interventionCalendar">Calendrier interventions</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:TechnologyCanDisplay')">
        <a routerLink="/technologies/list" data-cy="technologiesList">Technologies</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:OfferCanDisplay')">
        <a href="#/offers/list" data-cy="offersList">Offres</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Antoine:ProviderCanDisplay', 'Antoine:ContractorCanDisplay', 'Antoine:TechnologyCanDisplay', 'Antoine:OfferCanDisplay')"></li>

      <li *ngIf="hasPermissions('Antoine:WorkOrderItemCanDisplay')">
        <a [href]="woiListBaseUrl + '&assigned-to-me&assignee=' + username">
          <i class="fa fa-user"></i> Mes tâches
        </a>
      </li>

      <li *ngIf="hasPermissions('Antoine:WorkOrderItemCanDisplay')">
        <a [href]="woiListBaseUrl" data-cy="workOrderItemsList">
          <i class="fas fa-tasks"></i> Toutes les tâches
        </a>
      </li>

      <li class="dropdown-header">
        Vues filtrées
      </li>

      <li class="dropdown-submenu" *ngFor="let menu of configurableMenuList">
        <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false">
          {{menu.label}}
        </a>

        <ul class="dropdown-menu" role="menu" *ngIf="menu.items?.length > 0">
          <li *ngFor="let item of menu.items">
            <a [href]="item.url">
              <i class="fa fa-tag"></i> {{item.label}}
            </a>
          </li>
        </ul>
      </li>
    </ul>
  </li>

  <li class="dropdown" [ngClass]="getIsNetworkCurrentClass()" *ngIf="hasPermissions('Antoine:NetworkCanDisplay')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="networkMenu">
      Réseau & Télécom <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="networkDropdownMenu">
      <li *ngIf="hasPermissions('Antoine:NetworkDeviceCanDisplay')">
        <a routerLink="/network-devices/list" data-cy="networkDevicesList">Équipements réseaux</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:LineCanDisplay')">
        <a routerLink="/lines/list" data-cy="linesList">Lignes réseau WAN</a>
      </li>

      <li *ngIf="hasPermissions('Provitool:CanConfigure')">
        <a routerLink="/config-templates/list" data-cy="configTemplatesList">Modèles de configuration</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:CollectionNodeCanDisplay')">
        <a routerLink="/collection-nodes/list" data-cy="collectionNodesList">Portes de collecte</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:BackboneEquipmentCanDisplay')">
        <a routerLink="/backbone-equipments/list" [queryParams]="{ordering: 'dns'}" data-cy="backboneEquipmentsList">Équipements backbone</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:ServerVRFCanDisplay')">
        <a routerLink="/vrfs/list" data-cy="vrfsList">VRF</a>
      </li>

      <li>
        <a routerLink="/argos-event-mutes/list" data-cy="argosEventMutesList">Fenêtres de maintenance</a>
      </li>

      <li role="separator" class="divider"></li>

      <li *ngIf="hasPermissions('Antoine:OperatorLineCanDisplay')">
        <a routerLink="/operator-lines/list" data-cy="operatorLinesList">Liens opérateur</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:SdaCanDisplay')">
        <a routerLink="/sdas/list" data-cy="sdasList">SDAs</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Antoine:ProviHistoryCanDisplay')"></li>

      <li *ngIf="hasPermissions('Antoine:ConsoleCanDisplay')">
        <a routerLink="/consoles/list" data-cy="consolesList">Consoles</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:ProviHistoryCanDisplay')">
        <a routerLink="/provi-histories/list" data-cy="proviHistoriesList">Historique des provisionnements</a>
      </li>

      <li role="separator" class="divider" *ngIf="hasPermissions('Antoine:MerakiCanDisplay')"></li>

      <!-- TODO Remove all the meraki views created for dev purpose -->
      <li *ngIf="hasPermissions('Antoine:MerakiCanDisplay')">
        <a routerLink="/meraki-organizations/list" data-cy="merakiOrganizationsList">Organisations Meraki</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:MerakiCanDisplay')">
        <a routerLink="/meraki-networks/list" data-cy="merakiNetworksList">Réseaux Meraki</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:MerakiCanDisplay')">
        <a routerLink="/meraki-config-templates/list" data-cy="merakiConfigTemplatesList">Templates de configuration Meraki</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:MerakiCanDisplay')">
        <a routerLink="/meraki-switch-profiles/list" data-cy="merakiSwitchProfilesList">Profiles de switch Meraki</a>
      </li>
    </ul>
  </li>

  <li class="dropdown" [ngClass]="getIsLogisticsClass()" *ngIf="hasPermissions('Antoine:LogisticsMenuCanDisplay')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="logisticsMenu">
      Logistique <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="logisticsDropdownMenu">
      <li *ngIf="hasPermissions('Antoine:LogisticsRequestCanDisplay')">
        <a [href]="logisticsRequestListBaseUrl" data-cy="logisticsRequestsList">Demandes logistiques</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:ExternalEquipmentOrderCanDisplay')">
        <a routerLink="/external-equipment-orders/list" data-cy="externalEquipmentOrdersList">Commandes Cassiopée</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:LogisticsRequestCanDisplay')" role="separator" class="divider"></li>


      <li *ngIf="hasPermissions('Antoine:EquipmentCanDisplay')">
        <a routerLink="/equipments/list" data-cy="equipmentsList">
          Équipements
        </a>
      </li>

      <li *ngIf="hasPermissions('Antoine:EquipmentModelCanDisplay')">
        <a routerLink="/equipment-models/list" data-cy="equipmentModelsList">
          Modèles d'équipement
        </a>
      </li>

      <li *ngIf="hasPermissions('Antoine:EquipmentConstructorCanDisplay')">
        <a routerLink="/equipment-constructors/list" data-cy="equipmentConstructorsList">
          Constructeurs d'équipement
        </a>
      </li>

      <li *ngIf="hasPermissions('Wira:EqpCanDoInventory') && hasPermissions('Antoine:EquipmentCanDisplay')">
        <a routerLink="/equipments/inventory" data-cy="equipmentsIventory">
          Inventaire des équipements
        </a>
      </li>

      <li *ngIf="hasPermissions('Wira:EqpModelCanMerge') && hasPermissions('Antoine:EquipmentCanDisplay')">
        <a routerLink="/equipment-models-merge/" data-cy="equipmentModelsMerge">
          Fusion de modèles d'équipement
        </a>
      </li>

      <li *hasPermissions="['Antoine:EquipmentCanDisplay', 'Antoine:LogisticsSiteCanDisplay', 'Antoine:CarrierAccountCanDisplay']"
          role="separator" class="divider">
      </li>

      <li *ngIf="hasPermissions('Antoine:LogisticsSiteCanDisplay')">
        <a routerLink="/logistics-sites/list" data-cy="logisticsSitesList">
          Sites logistiques
        </a>
      </li>

      <li *ngIf="hasPermissions('Antoine:CarrierAccountCanDisplay')">
        <a routerLink="/carrier-accounts/list" data-cy="carrierAccountList">
          Comptes Transporteur
        </a>
      </li>

    </ul>
  </li>

  <li [ngClass]="getIsToolCurrentClass()" class="dropdown" *ngIf="hasPermissions('Antoine:ToolsCanDisplay')">
    <a class="dropdown-toggle" data-toggle="dropdown" role="button" aria-expanded="false" data-cy="toolsMenu">
      Outils <span class="caret"></span>
    </a>

    <ul class="dropdown-menu" role="menu" data-cy="toolsDropdownMenu">
      <!-- Link hidden until we take a decision about the Adidas'API -->
      <li *ngIf="hasPermissions('Antoine:EligibilityCanDisplay')">
        <a routerLink="/eligibility/" data-cy="eligibility">Éligibilité</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:EligibilityConfigurationCanDisplay')">
        <a routerLink="/eligibility/configuration" data-cy="eligibilityConfiguration">Configuration d'éligibilité</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:WaitingConfigurationCanDisplay')">
        <a routerLink="/waiting/configuration" data-cy="waitingConfiguration">Configuration de causes de retard</a>
      </li>

      <li *ngIf="hasPermissions('Antoine:ArgosUserCanDisplay')">
        <a routerLink="argos-users/list">Utilisateurs Argos</a>
      </li>

      <li role="separator" class="divider"></li>

      <li class="dropdown-header">
        Configuration
      </li>

      <li *ngIf="hasPermissions('Wira:MenuAdmin')">
        <a routerLink="/configurable-menus/list" data-cy="configurableMenusList">Menus configurables</a>
      </li>
      <li *ngIf="hasPermissions('Wira:TagAdmin')">
        <a routerLink="/tags/list" data-cy="tagsList">Tags</a>
      </li>
      <li *ngIf="hasPermissions('Wira:SequenceCanCreate')">
        <a routerLink="/sequences/list" data-cy="sequencesList">Séquences</a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:CompanyCanCreate')">
        <a routerLink="/companies/list" data-cy="companiesList">Sociétés</a>
      </li>
      <li *ngIf="hasPermissions('EvaBackend:TemplateAdmin')">
        <a routerLink="/templates/list" data-cy="templatesList">Templates</a>
      </li>
      <li *ngIf="hasPermissions('Antoine:TemplateEmailCanDisplay')">
        <a routerLink="/template-emails/list" data-cy="templateEmailsList">Templates d'email</a>
      </li>
    </ul>
  </li>

  <li (click)="showHelp()" role="button" style="outline: none;">
    <a>Aide <i class="fas fa-question-circle" aria-hidden="true"></i></a>
  </li>
</ul>
