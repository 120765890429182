<app-wcm-table #wcmTable [api]="apiProvitool.lines" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="lines-list-refresh" urlUpdateSignal="lines-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="enableRowClick"
               [disableUrlUpdate]="disableUrlUpdate" id="lines-list"
               [allowPreferences]="allowPreferences">
  <ng-template appWcmHeaderTitle text="Lignes réseau WAN" targetRouterLink="/lines/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <app-button label="Exporter" icon="fa-file-excel" (click)="export(wcmTable.filters)" [disabled]="disableTaskExport"
                *ngIf="!localDisabledButtons.export && hasPermissions('Provitool:NetworkLineCanExport') && !isNDInactive">
    </app-button>

    <app-button label="Importer des lignes réseau" icon="fa-file-excel" (clicked)="showImportLinesModal(wcmTable)"
                *ngIf="!localDisabledButtons.import && hasPermissions('Provitool:NetworkAdmin') && !isNDInactive">
      <app-button-child label="Télécharger le template" (clicked)="downloadTemplate()"></app-button-child>
      <app-button-child label="Exporter le template d'import" [disabled]="exportBtnDisabled"
                        (clicked)="exportImportTemplate(wcmTable.selectedItems)">
      </app-button-child>
    </app-button>

    <app-button label="Ajouter une ligne" icon="fa-plus" (clicked)="create()"
                *ngIf="!localDisabledButtons.create && hasPermissions('Wira:NetworkLineAdmin', 'Provitool:NetworkAdmin') && !isNDInactive">
    </app-button>

    <app-button label="Ajouter une ligne secondaire" icon="fa-plus" (clicked)="createSecondary()"
                *ngIf="!localDisabledButtons.createSecondary && hasPermissions('Wira:NetworkLineAdmin', 'Provitool:NetworkAdmin') && !isNDInactive"
                [disabled]="hasSecondaryLine || !hasSecondaryOperatorLine || !hasDataLine || isEditing">
    </app-button>
  </ng-template>

  <app-wcm-col field="id" header="Sélection" class="text-center" type="checkbox" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120" [focus]="true">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/lines/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
      <i *ngIf="item.has_active_argos_event_mute"
         class="fas fa-wrench" style="color: red; font-size: 1.2em;"
         data-toggle="tooltip" title="Maintenance en cours" >
      </i>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="login" header="Login PPP" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="wan_ip__address" header="IP WAN" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="network_device__code" header="Équipement réseau" class="text-center" type="advancedText"
               *ngIf="!disabledColumns?.network_device__code" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.network_device?.code}}"
                link="/network-devices/detail/{{item.network_device?.code}}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="network_device__operator_line__offer__name" header="Offre" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link *ngIf="item.usage !== 'secondary'" name="{{item.network_device?.operator_line?.offer?.name}}"
                link="/offers/detail/{{item.network_device?.operator_line?.offer?.code}}"
                [disabled]="disableRouterLink"></app-link>
      <app-link *ngIf="item.usage === 'secondary'" name="{{item.network_device?.secondary_operator_line?.offer?.name}}"
                link="/offers/detail/{{item.network_device?.secondary_operator_line?.offer?.code}}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="server_vrf__name" header="VRF" class="text-center" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.server_vrf?.name}}"
                link="/vrfs/detail/{{item.server_vrf?.id}}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="network_device__entity__name_or_code" sortField="network_device__entity__name" header="Client">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.network_device?.entity?.code}} / {{item.network_device?.entity?.name}}" *ngIf="item.network_device?.entity?.code"
                link="/entities/detail/{{item.network_device?.entity?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="network_device__entity__parent__name_or_code" sortField="network_device__entity__parent__name"
               header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.network_device.entity?.parent?.code}}{{item.network_device.entity?.parent?.name ? ' / ' + item.network_device.entity?.parent?.name : ''}}"
                link="/entities/detail/{{item.network_device.entity?.parent?.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="network_device__operator_line__collection_node__code" [preventSort]="true"
               header="Porte de collecte" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.network_device?.operator_line?.collection_node?.code}}" *ngIf="item.network_device?.operator_line?.collection_node?.code"
              link="/collection-nodes/detail/{{item.network_device?.operator_line?.collection_node?.code}}"
              [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="network_device__operator_line__collection_node__backbone_equipment__dns" [preventSort]="true"
               header="Equipement Backbone" type="advancedText">
    <ng-template appWcmColBody let-item="item">
      {{item.network_device?.operator_line?.collection_node?.backbone_equipment?.dns}}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="interface_l3" header="Interface L3" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="external_label" header="Label monitoring (client)" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="internal_label" header="Label monitoring (interne)" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="sub_interface" header="Sous interface" class="text-center" type="nullableText" type="advancedText"></app-wcm-col>

  <app-wcm-col field="usage" type="select" [additionalArgs]="{options: usageOptions}"
               header="Utilisation" class="text-center"></app-wcm-col>

  <app-wcm-col field="quarantine" type="yesNo"
               header="Quarantaine" class="text-center"></app-wcm-col>

  <app-wcm-col field="server_vrf__proxy_client" header="Machine de rebond" class="text-center" type="advancedText"></app-wcm-col>

  <app-wcm-col field="is_active" header="Ligne active" type="yesNo" class="text-center"></app-wcm-col>

  <app-wcm-col field="is_up" header="Statut" type="none" class="text-center"
               [preventSort]="true" width="1" *ngIf="!localDisabledColumns.is_up">
    <ng-template appWcmColBody let-item="item">
      <i class="fas fa-square" [ngClass]="{'wcm-warning': item.is_up===null, 'wcm-danger': item.is_up===false, 'wcm-success': item.is_up}"></i>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="state" header="Statut" type="multipleStates" class="text-center" width="120"
    [additionalArgs]="{workflows: ['lines']}" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col header="Action" id="action" type="none" class="text-center" [preventSort]="true" width="1"
               *ngIf="hasPermissions('Provitool:NetworkAdmin')">
    <ng-template appWcmColBody let-item="item">
      <div class="action-btn-bar">
        <button class="btn btn-default btn-xs" title="Supprimer" (click)="confirmDelete(wcmTable, item)" [disabled]="loadingItemAction">
          <i class="fa fa-trash-alt"></i>
        </button>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
