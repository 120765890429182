import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { MarkdownService } from 'ngx-markdown';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiWinterService } from '@core/apis/api-winter.service';
import { EmailModalComponent } from '@core/components/email-modal/email-modal.component';
import { EMAIL_TEMPLATES_OPTIONS } from '@core/constants';
import { IContact, IListResult, IOperatorLine, ITemplateEmail, IWorkOrderItems } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';
import { WcmUsersService } from '@core/services/wcm-users.service';
import { TemplateEmailsModalComponent } from '@views/template-emails/template-emails-modal.component';
import { WorkOrderItemsStateService } from '@views/work-order-items/work-order-items-state.service';
import * as moment from 'moment';
import { WaycomHttpErrorResponse } from '@app/core/services/waycom-http-error-response';

interface IContext {
  address_from: string;
  address_to: string[];
  message_html: string;
  message_attachment_ids: string[];
  cc_addresses_list: string[];
  subject: string;
}

interface IEmailModal {
  attachments: Array<number>;
  cc: Array<string>;
  content: string;
  emailTo: string;
  senderAddress: string;
  subject: string;
}

interface IUtils {
  city: string;
  notif_reminder_date: string;
  provider: string;
  code_woi: string;
  site_customer_ref: string;
  site_name: string;
  technology: string;
  contact?: IContact;
}

@Component({
  selector: 'app-reminder-email-button',
  templateUrl: './reminder-email-button.component.html',
})
export class ReminderEmailButtonComponent implements OnInit{

  @Input() public label?: string;
  @Input() public type: keyof typeof EMAIL_TEMPLATES_OPTIONS;
  @Input() public woi: IWorkOrderItems;
  @Input() public operatorLine: IOperatorLine;
  @Input() public disabled: boolean;

  constructor(
    private readonly promisesService: PromisesService,
    private readonly toastr: ToastrService,
    private readonly ngbModal: NgbModal,
    private readonly signalsService: SignalsService,
    private readonly apiShiva: ApiShivaService,
    private readonly apiWinter: ApiWinterService,
    private readonly userService: UserService,
    private readonly wcmUsersService: WcmUsersService,
    private readonly workOrderItemsStateService: WorkOrderItemsStateService,
    private readonly markdownService: MarkdownService,
  ) {
  }

  public ngOnInit(): void {
    this._getShippingMailData(this.woi);
  }

  public sendReminder(): void {
    const emailTo: string | undefined = this.woi?.contact?.email;
    if (!emailTo) {
      this.toastr.error('Impossible de récupérer le mail du contact. Vérifiez le contact de la tâche avant de continuer.');

    } else {
      this._getTemplateEmail()
        .then((templateEmail: ITemplateEmail) => this._openEmailModal(templateEmail, emailTo))
        .then((context: IContext) => this.apiShiva.work_order_items.send_mail(this.woi.id, context)
          .then(() => this.toastr.success('Le mail a correctement été envoyé.'))
          .catch((err) => Promise.reject(err))
        )
        .catch(() => {
        });
    }
  }

  private _getTemplateEmail(): Promise<ITemplateEmail> {
    return this.apiShiva.template_emails.list({ type: this.type })
      .then((res: IListResult<ITemplateEmail>) => {
        // if only one template email we don't need to open the template modal chooser
        if (res.count === 1) {
          return res.results[0];
        } else {
          const templateModal = this.ngbModal.open(TemplateEmailsModalComponent, {
            backdrop: 'static',
            size: 'lg'
          });
          templateModal.componentInstance.disabledButtons = { create: true };
          templateModal.componentInstance.filters = { type: this.type };
          return templateModal.result;
        }
      })
      .catch((err) => Promise.reject(err));
  }

  private _openEmailModal(templateEmail: ITemplateEmail, emailTo: string): Promise<IContext> {
    const deferred = this.promisesService.defer();
    const utilsFillingVar = {
      city: this.woi?.work_order?.entity?.location?.city || '[[Ville entité]]',
      notif_reminder_date: this._generateRDVdate(),
      provider: this.operatorLine?.offer?.provider?.name || '[[fournisseur_du_lien]]',
      code_woi: this.woi?.code || '[[code_woi]]',
      site_customer_ref: this.woi?.work_order?.entity?.customer_ref || '[[Ref client entité]]',
      site_name: this.woi?.work_order?.entity?.name || '[[nom_du_site]]',
      technology: this.operatorLine?.offer?.technology?.name || '[[techno_du_lien]]',
      contact: this.woi.contact,
    };

    const emailSubject: string = this._generateEmailSubject(utilsFillingVar, templateEmail.subject);

    const emailsCcAddress: string[] = templateEmail['cc_address']?.split(',').map(value => value.trim()) || ['delivery@waycom.net'];
    // if an assignee (not the one on our woi but on the order) is detected we add him to the cc list
    if (this?.woi?.work_order?.order?.assignee) {
      const assignee = this.wcmUsersService.get(this.woi.work_order.order.assignee);
      emailsCcAddress.push(assignee.email);
    }

    const modal: NgbModalRef = this.ngbModal.open(EmailModalComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.attachmentsApi = this.apiShiva.attachments_ng;
    modal.componentInstance.attachmentModel = 'work-order-items';
    modal.componentInstance.attachmentModelPk = this.woi.id;
    modal.componentInstance.defaultEmailSubject = emailSubject;
    modal.componentInstance.templateEmailCcAddress = emailsCcAddress;
    modal.componentInstance.emailTo = emailTo;
    modal.componentInstance.fillFunction = (template: string): string => this._fillFunction(template);
    modal.componentInstance.modalSendButton = `Envoyer la notification`;
    modal.componentInstance.modalTitle = `Mail pour notifier le client`;
    modal.componentInstance.templateEmailId = templateEmail.id;
    modal.componentInstance.templateEmailLastAttachmentId = templateEmail.last_attachment_id;
    modal.componentInstance.templateEmailSenderAddress = templateEmail.sender_address;
    modal.componentInstance.type = 'delivery';
    modal.componentInstance.typeEmail = this.woi.product.code;

    modal.result
      .then(
        (res: IEmailModal) => {
          const context: IContext = {
            address_from: res.senderAddress,
            address_to: [res.emailTo],
            message_html: res.content,
            message_attachment_ids: res.attachments.map((attachmentId: number): string => `${attachmentId}`),
            cc_addresses_list: res.cc,
            subject: res.subject
          };
          this.signalsService.broadcast('attachments-list-refresh');
          // Resolving the transition with email content in context

          deferred.resolve(context);
        },
        () => {
        })
      .catch(() => deferred.reject());
    return deferred.promise;
  }

  private _getShippingMailData(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    this.apiShiva.work_order_items.get_shipping_mail_data(woi.id)
      .then(res => {
        woi.dataForDynamicVar = res;
        deferred.resolve();
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'EXPED_AND_EXPER_ARE_MISSING') {
            this.toastr.error(`Cette tâche est liée à une demande logistique qui ne possède ni tâche d'expédition ni tâche de retour.`);
            return;
          }
        }
        this.toastr.error('Erreur lors du chargement des variables dyamiques.');
        Promise.reject(err);
      });
    return deferred.promise;
    }

  private _generateEmailSubject(utils: IUtils, subject: string): string {
    const defaultEmailSubject: string = subject ? subject : `[IMPORTANT] ADISTA – RDV D’INTERVENTION LIVRAISON LIAISON INTERNET ${utils.technology} ${utils.provider} – Réf : ${utils.code_woi}  ${utils.site_name} - ${utils.city} - ${utils.site_customer_ref}`;

    const finalSubject: string = this._fillSubjectFunction(defaultEmailSubject);
    if (finalSubject.includes('[[')) {
      this.toastr.warning(`Attention avant d'envoyer votre mail car votre OBJET possède encore des variables à modifier`);
    }
    return finalSubject;
  }

  private _fillSubjectFunction(templateEmail: string): string {
    const displayDate = this.woi.provisional_start_date ? moment(this.woi.provisional_start_date).format('DD/MM/YYYY') : '';
    const displayTime = this.woi.provisional_start_date ? moment(this.woi.provisional_start_date).format('LT') : '';

    // to avoid 'undefined' display on location
    const displayLocation =
      `${this.woi.location?.address || ''},
      ${this.woi.location?.zipcode || ''}
      ${this.woi.location?.city || ''}`;

    // convert markdown note to html
    const displayNote = this.woi.note ? this.markdownService.compile(this.woi.note) : '';

    // get winter href url
    const validationUrl = this.apiWinter.validateIntervention(this.woi.metadata?.receipt_code);

    return templateEmail
      .replace('[[techno du lien]]', this.operatorLine?.offer?.technology?.name || '[[techno_du_lien]]')
      .replace('[[fournisseur du lien]]', this.operatorLine?.offer?.provider?.name || '[[fournisseur_du_lien]]')
      .replace('[[date_rendezvous]]', this._generateRDVdate())
      .replace('[[created_by]]', this.userService.getUserFullName())
      .replace('[[code_woi]]', this.woi.code)
      .replace('[[Nom entité]]', this.woi.work_order?.entity?.name || '')
      .replace('[[Ville entité]]', this.woi.work_order?.entity?.location?.city || '')
      .replace('[[Ref client entité]]', this.woi.work_order?.entity?.customer_ref || '')
      .replace('[[provisional_start_date]]', displayDate)
      .replace('[[provisional_start_time]]', displayTime)
      .replace('[[location]]', displayLocation)
      .replace('[[contact]]', this.workOrderItemsStateService.formatContactForEmail(this.woi.contact))
      .replace('[[note]]', displayNote)
      .replace('[[validation_url]]', validationUrl)
      .replace('[[contractor__intervention_cost]]', this.woi.contractor?.intervention_cost || '')
      .replace('[[date_envoi_pexped]]', this.woi.dataForDynamicVar['sending_woi_exped_date'] || '[[date_envoi_pexped]]')
      .replace('[[type_eqp_envoye]]', this.woi.dataForDynamicVar['eqp_type'] || '[[type_eqp_envoye]]')
      .replace('[[modele_eqp_envoye]]', this.woi.dataForDynamicVar['sending_eqp_model'] || '[[modele_eqp_envoye]]')
      .replace('[[SN_eqp_envoye]]', this.woi.dataForDynamicVar['sending_sn'] || '[[SN_eqp_envoye]]')
      .replace('[[type_eqp_remplacer]]', this.woi.dataForDynamicVar['eqp_type'] || '[[type_eqp_remplacer]]')
      .replace('[[modele_eqp_remplacer]]', this.woi.dataForDynamicVar['replace_eqp_modele'] || '[[modele_eqp_remplacer]]')
      .replace('[[SN_eqp_remplacer]]', this.woi.dataForDynamicVar['replace_sn'] || '[[sn_eqp_remplacer]]')
      .replace('[[nom_parent]]', this.woi.dataForDynamicVar['parent_name'] || '[[nom_parent]]')
      .replace('[[nom_site]]', this.woi.dataForDynamicVar['entity_name'] || '[[nom_site]]')
      .replace('[[ville]]', this.woi.dataForDynamicVar['city'] || '[[ville]]')
      .replace('[[shipment_ref_pexper]]', this.woi.dataForDynamicVar['shipment_ref_woi_exper'] || '[[shipment_ref_pexper]]')
      .replace('[[shipment_ref_pexped]]', this.woi.dataForDynamicVar['shipment_ref_woi_exped'] || '[[shipment_ref_pexped]]')
      .replace('[[libelle_statut_cible]]', this.woi.dataForDynamicVar['libelle_statut_cible'] || '[[libelle_statut_cible]]')
      .replace('[[due_date]]', this.woi.dataForDynamicVar['due_date'] || '[[due_date]]')
      .replace('[[due_time]]', this.woi.dataForDynamicVar['due_time'] || '[[due_time]]')
      .replace('[[list_equipments]]', this.woi.dataForDynamicVar['list_equipments'] || '[[list_equipments]]');
  }

  private _fillFunction(templateEmail: string): string {
    const address = this._generateAddress();
    const validationUrl: string = this.apiWinter.validateDelivery(this.woi?.metadata?.receipt_code);

    const displayDate = this.woi.provisional_start_date ? moment(this.woi.provisional_start_date).format('DD/MM/YYYY') : '';
    const displayTime = this.woi.provisional_start_date ? moment(this.woi.provisional_start_date).format('LT') : '';

    // to avoid 'undefined' display on location
    const displayLocation =
      `${this.woi.location?.address || ''},
      ${this.woi.location?.zipcode || ''}
      ${this.woi.location?.city || ''}`;

    // convert markdown note to html
    const displayNote = this.woi.note ? this.markdownService.compile(this.woi.note) : '';

    const html: string = templateEmail
      .replace('[[techno du lien]]', this.operatorLine?.offer?.technology?.name || '[[techno_du_lien]]')
      .replace('[[fournisseur du lien]]', this.operatorLine?.offer?.provider?.name || '[[fournisseur_du_lien]]')
      .replace('[[date_rendezvous]]', this._generateRDVdate())
      .replace('[[nom_site]]', `<b>${this.woi?.work_order?.entity?.name || '[[nom_du_site]]'}</b>`)
      .replace('[[adresse]]', `<b>${address}</b>`)
      .replace('[[created_by]]', this.userService.getUserFullName())
      .replace('[[code_woi]]', this.woi.code)
      .replace('[[Nom entité]]', this.woi.work_order?.entity?.name || '')
      .replace('[[Ville entité]]', this.woi.work_order?.entity?.location?.city || '')
      .replace('[[Ref client entité]]', this.woi.work_order?.entity?.customer_ref || '')
      .replace('[[provisional_start_date]]', displayDate)
      .replace('[[provisional_start_time]]', displayTime)
      .replace('[[location]]', displayLocation)
      .replace('[[contact]]', this.workOrderItemsStateService.formatContactForEmail(this.woi.contact))
      .replace('[[note]]', displayNote)
      .replace('[[validation_url]]', validationUrl)
      .replace('[[contractor__intervention_cost]]', this.woi.contractor?.intervention_cost || '')
      .replace('[[date_envoi_pexped]]', this.woi.dataForDynamicVar['sending_woi_exped_date'] || '[[date_envoi_pexped]]')
      .replace('[[type_eqp_envoye]]', this.woi.dataForDynamicVar['eqp_type'] || '[[type_eqp_envoye]]')
      .replace('[[modele_eqp_envoye]]', this.woi.dataForDynamicVar['sending_eqp_model'] || '[[modele_eqp_envoye]]')
      .replace('[[SN_eqp_envoye]]', this.woi.dataForDynamicVar['sending_sn'] || '[[SN_eqp_envoye]]')
      .replace('[[type_eqp_remplacer]]', this.woi.dataForDynamicVar['eqp_type'] || '[[type_eqp_remplacer]]')
      .replace('[[modele_eqp_remplacer]]', this.woi.dataForDynamicVar['replace_eqp_modele'] || '[[modele_eqp_remplacer]]')
      .replace('[[SN_eqp_remplacer]]', this.woi.dataForDynamicVar['replace_sn'] || '[[sn_eqp_remplacer]]')
      .replace('[[nom_parent]]', this.woi.dataForDynamicVar['parent_name'] || '[[nom_parent]]')
      .replace('[[nom_site]]', this.woi.dataForDynamicVar['entity_name'] || '[[nom_site]]')
      .replace('[[ville]]', this.woi.dataForDynamicVar['city'] || '[[ville]]')
      .replace('[[shipment_ref_pexper]]', this.woi.dataForDynamicVar['shipment_ref_woi_exper'] || '[[shipment_ref_pexper]]')
      .replace('[[shipment_ref_pexped]]', this.woi.dataForDynamicVar['shipment_ref_woi_exped'] || '[[shipment_ref_pexped]]')
      .replace('[[libelle_statut_cible]]', this.woi.dataForDynamicVar['libelle_statut_cible'] || '[[libelle_statut_cible]]')
      .replace('[[due_date]]', this.woi.dataForDynamicVar['due_date'] || '[[due_date]]')
      .replace('[[due_time]]', this.woi.dataForDynamicVar['due_time'] || '[[due_time]]')
      .replace('[[list_equipments]]', this.woi.dataForDynamicVar['list_equipments'] || '[[list_equipments]]');

    if (html.includes('[[')) {
      this.toastr.warning(`Attention avant d'envoyer votre mail car votre CONTENU possède encore des variables à modifier`);
    }

    return html;
  }

  private _generateAddress(): string {
    let address = '[[adresse]]';
    const location = this.woi?.work_order?.entity?.location;
    if (location && location.address && location.zipcode && location.city && location.country) {
      address = `${this.woi.work_order.entity.location.address}, ${this.woi.work_order.entity.location.zipcode} ${this.woi.work_order.entity.location.city} ${this.woi.work_order.entity.location.country}`;
    }
    return address;
  }

  private _generateRDVdate(): string {
    if (!this.woi.metadata.notif_reminder_date || !this.woi.metadata.notif_reminder_end_date) {
      return '[[date_rendezvous]]';
    }
    const date: Date = new Date(this.woi.metadata.notif_reminder_date as string);
    const hour: number = date.getHours();
    const minute: string | number = date.getMinutes() < 10 ? '0' + date.getMinutes().toString() : date.getMinutes();
    const today: Date = new Date();
    const endTime: Date = new Date(today.toDateString() + ' ' + this.woi.metadata.notif_reminder_end_date);
    const endHour: number = endTime.getHours();
    const endMinute: string | number = endTime.getMinutes() < 10 ? '0' + endTime.getMinutes().toString() : endTime.getMinutes();
    const dateString: string = date.toLocaleDateString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });

    return `${dateString} de ${hour}:${minute} à ${endHour}:${endMinute}`;
  }
}
