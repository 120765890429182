import { Injectable } from '@angular/core';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { MarkdownService } from 'ngx-markdown';

import { ApiProvitoolService } from '@core/apis/api-provitool.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiWinterService } from '@core/apis/api-winter.service';
import { EmailModalComponent } from '@core/components/email-modal/email-modal.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { IContact, IInterventionTag, IListResult, ITemplateEmail, IWaiting, IWorkOrderItems, WaitingTypeEnum } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';
import { SignalsService } from '@core/services/signals.service';
import { UserService } from '@core/services/user.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { TemplateEmailsModalComponent } from '@views/template-emails/template-emails-modal.component';
import { WaitingEditionModalComponent } from '@views/waiting/waiting-edition-modal.component';

import { FopInvalidAppointmentDateModalComponent } from './fop-invalid-appointment-date-modal.component';
import { WorkOrderItemsAddCommentModalComponent } from './work-order-item-add-comment-modal.component';
import { ITransitionPayload, WorkOrderItemsCompletionDateModalComponent } from './work-order-items-completion-date-modal.component';
import { WorkOrderItemsStateCancelComponent } from './work-order-items-state-cancel-modal.component';
import { WorkOrderItemsValidateTeamComponent } from './work-order-items-validate-team-modal.component';

interface IEmailModal {
  emailTo: string;
  content: string;
  attachments: Array<number>;
  cc: Array<string>;
  senderAddress: string;
  subject: string;
}

@Injectable({
  providedIn: 'root'
})
export class WorkOrderItemsStateService {
  private mappingEmailCC: { intervention: string[], recovery: string[] };

  constructor(
    private apiProvitool: ApiProvitoolService,
    private apiShiva: ApiShivaService,
    private apiWinter: ApiWinterService,
    private markdownService: MarkdownService,
    private ngbModal: NgbModal,
    private promisesService: PromisesService,
    private signalsService: SignalsService,
    private toastr: ToastrService,
    private userService: UserService,
    private wcmModalsService: WcmModalsService
  ) {}

  // ----------------------------------------
  // Functions from the stateConfirmFunctMap
  // ----------------------------------------
  /**
   * This function must return a promise to be used by the state field directive
   */
  public orderStateTransition(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    if (woi.product.code === 'P-ORFOP' && woi.processing === 'automatic') {
      this._checkFOPAppointmentDate(woi)
        .then(() => deferred.resolve())
        .catch(() => deferred.reject());
    } else if (['P-LINKS', 'P-INTER'].includes(woi.product.code)) {
      this._checkGenericLineOrderTransition(woi)
        .then(() => deferred.resolve())
        .catch(() => deferred.reject());
    } else {
      // Other product are not impacted by this check
      deferred.resolve();
    }
    return deferred.promise;
  }

  public setCauseKo(woi: IWorkOrderItems): Promise<ITransitionPayload> {
    const modal = this.ngbModal.open(WorkOrderItemsCompletionDateModalComponent, { backdrop: 'static' });
    modal.componentInstance.isTaskKo = true;
    modal.componentInstance.woi = woi;
    modal.componentInstance.isInterFormNeeded = woi.product?.code === 'P-QPEWV' && woi.state.name === 'in-progress-validate';
    return modal.result;
  }

  public setInProgress(woi: IWorkOrderItems): Promise<ITransitionPayload> {
    const modal = this.ngbModal.open(WorkOrderItemsCompletionDateModalComponent, { backdrop: 'static' });
    modal.componentInstance.isTaskKo = false;
    modal.componentInstance.woi = woi;
    modal.componentInstance.displayEndTask = false;
    modal.componentInstance.isInterFormNeeded = woi.product?.code === 'P-QPEWV' && woi.state.name === 'in-progress-validate';
    return modal.result;
  }

  public setCompletionDateOnFinish(woi: IWorkOrderItems): Promise<ITransitionPayload> {
    const deferred = this.promisesService.defer();
    const modal = this.ngbModal.open(WorkOrderItemsCompletionDateModalComponent, { backdrop: 'static' });
    modal.componentInstance.woi = woi;
    modal.componentInstance.isInterFormNeeded = woi.product?.code === 'P-QPEWV' && woi.state.name === 'in-progress-validate';
    modal.result
      .then(res => deferred.resolve(res))
      .catch(() => deferred.reject());

    return deferred.promise;
  }

  /**
   * This function must return a promise to be used by the state field directive
   * Used by P-QPEWV and P-NEYLU products, others products are not impacted by this
   *  check but a confirmation popup for state change is displayed
   */
  public sendEmail(woi: IWorkOrderItems): Promise<Record<string, unknown>> {
    const deferred = this.promisesService.defer();
    const mailObject = {
      'P-QPEWV': {
        type: 'intervention',
        modalTitle: `Démarrage de la tâche - Envoi de l'email d'intervention`,
        modalSendButton: `Envoyer l'email d'intervention`,
        emailTo: woi.contractor?.location?.email,
        emailsCC: ['deploiement@waycom.net'],

      },
      'P-NEYLU': {
        type: 'recovery',
        modalTitle: `Mail de relance`,
        modalSendButton: `Envoyer l'email de relance`,
        emailTo: woi.contact?.email,
        emailsCC: ['logistique@waycom.net'],
      }
    };

    // Intérvention OR Récupération not new
    const shouldDisplayEmailModal = (
      woi.product.code === 'P-QPEWV' ||
      (woi.product.code === 'P-NEYLU' && woi.state.name !== 'new')
    );

    if (shouldDisplayEmailModal) {
      // Intérvention should have a contractor, otherwise template will have missing information
      const contractorMissing = woi.product.code === 'P-QPEWV' && !woi.contractor;
      const customerEmailMissing = woi.product.code === 'P-QPEWV' && !woi.metadata?.customer_emails;

      if (contractorMissing || customerEmailMissing) {
        const messages: string[] = [];
        if (contractorMissing) {
          messages.push(`Veuillez sélectionner un prestataire avant de démarrer la tâche.`);
        }
        if (customerEmailMissing) {
          messages.push(`Veuillez saisir au moins une adresse email client.`);
        }

        this.wcmModalsService.alert(
          `Démarrage de la tâche bloqué`,
          messages.join('<br />'),
        );
        deferred.reject();

      } else {
        // Everything is fine, either Intervention or Recovery template will be displayed
        this._getTemplateEmail(mailObject[woi.product.code]['type'])
          .then((res: ITemplateEmail) => {
            if (woi.product.code === 'P-NEYLU') {
              this._getShippingMailData(woi)
                .then(() => {
                  this._openEmailModal(mailObject[woi.product.code], woi, `${res.id}`, res.last_attachment_id, res.sender_address, res.cc_address, res.subject)
                    .then(context => deferred.resolve(context))
                    .catch(() => deferred.reject());
                })
                .catch(() => deferred.reject());
            } else {
              this._openEmailModal(mailObject[woi.product.code], woi, `${res.id}`, res.last_attachment_id, res.sender_address, res.cc_address, res.subject)
                .then(context => deferred.resolve(context))
                .catch(() => deferred.reject());
            }
          })
          .catch(() => deferred.reject());
      }
    } else {
      const modalResult = this.wcmModalsService.confirm(
        `Changement d'état`,
        `Vous êtes sur le point de passer la tâche au statut Démarrer.`
      );
      modalResult.then(
        () => deferred.resolve(),
        () => {}
      );
    }
    return deferred.promise;
  }

  /**
   * This function must return a promise to be used by the state field directive
   */
  public doneStateTransition(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    if (['P-LINKS', 'P-INTER'].includes(woi.product.code)) {
      this._checkGenericLineDoneTransition(woi)
        .then(() => deferred.resolve())
        .catch(() => deferred.reject());
    } else {
      // Other product are not impacted by this check
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * This function must return a promise to be used by the state field directive
   */
  public waitingCheckNetworkStateTransition(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    if (woi.product.code === 'P-PROVI') {
      this._setCommentAboutWaitingCheckNetworkState(woi)
        .then(() => deferred.resolve())
        .catch(() => deferred.reject());
    } else {
      // Other product are not impacted by this check
      deferred.resolve();
    }
    return deferred.promise;
  }

  public cancelStateTransition(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    const modal = this.ngbModal.open(WorkOrderItemsStateCancelComponent, {
      backdrop: 'static',
      size: 'xs'
    });
    modal.componentInstance.woi = woi;
    modal.result
      .then(() => deferred.resolve())
      .catch(() => deferred.reject());

    return deferred.promise;
  }

  public interventionTagSelector(woi: IWorkOrderItems, transitionName: string): Promise<IInterventionTag> {
    const deferred = this.promisesService.defer();
    const modal = this.ngbModal.open(WorkOrderItemsValidateTeamComponent, {
      backdrop: 'static',
      size: 'xs'
    });
    modal.componentInstance.woi = woi;
    modal.componentInstance.transitionName = transitionName;
    modal.componentInstance.isInterFormNeeded = woi.product?.code === 'P-QPEWV' && woi.state.name === 'in-progress-validate';

    modal.result
      .then((res: { tagName: string }) => {
        const context: IInterventionTag = { tag_name: res };
        deferred.resolve(context);
      })
      .catch(() => deferred.reject());
    return deferred.promise;
  }

  public handleEmailError(error: unknown): void {
    if (error instanceof WaycomHttpErrorResponse) {
      if (error.getFirstErrorMessage() === 'RECOVERY_MAIL_DID_NOT_RECEIVE_EXPECTED_CONTEXT_TO_BE_SEND') {
        // This is ugly, but the backend sends the list of missing keys in a format not easily convertible
        const keyMap = {
          '{\'message_html\'}': 'Contenu',
          '{\'address_to\'}': 'Destinataire',
          '{\'cc_addresses_list\'}': 'Destinataires en copie',
          '{\'message_attachment_ids\'}': 'Pièces jointes',
        };
        const missingKeys = error.detail['missing_keys'].map((key: string) => keyMap[key]).join(', ');
        this.toastr.error(`Informations manquantes pour l'envoi du mail : ${missingKeys}.`);
        return;
      }
    }
    Promise.reject(error);
  }

  public async orderedToWaitingClientTransition(woi: IWorkOrderItems): Promise<unknown> {
    const today: string = moment().format('YYYY-MM-DD');
    const waitings: IListResult<IWaiting> = await this._getWaitingCauses(woi, today);

    if (waitings.count) {
      // We have an open waiting cause, edit it
      return this._openWaitingCauseModal(waitings.results[0], false);
    }

    // We are moving to `waiting-client`, we need a waiting cause, create one
    const emptyItem: IWaiting = {
      editable: true,
      type: WaitingTypeEnum.WaitingOnCustomer,
      start_date: today,
      end_date: null,
      duration: null,
      reason: null,
      sub_reason: null,
      description: null,
      work_order_item: woi,
    };
    return this._openWaitingCauseModal(emptyItem, false);
  }

  public async waitingClientToOrderedTransition(woi: IWorkOrderItems): Promise<unknown> {
    const today: string = moment().format('YYYY-MM-DD');
    const waitings: IListResult<IWaiting> = await this._getWaitingCauses(woi, today);

    if (waitings.count) {
      // We have an open waiting cause, edit it
      return this._openWaitingCauseModal(waitings.results[0], true);
    }

    // We are moving away from `waiting-client` and there is no open waiting cause, we're all good
    return Promise.resolve();
  }

  /**
   * Format the contact as a human-readable name or return a placeholder if the contact is empty
   * @param contact The contact to format
   */
  public formatContactForEmail(contact?: IContact | undefined): string {
    let contactName: string = `${contact?.first_name || ''} ${contact?.last_name || ''}`;
    if (!contactName.trim()) {
      contactName = '[[contact]]';
    }
    return contactName;
  }

  // ----------------------------------------
  // Tools used by state confirm functions
  // ----------------------------------------
  private _getWaitingCauses(woi: IWorkOrderItems, today: string): Promise<IListResult<IWaiting>> {
    return this.apiShiva.waitings.list({
      work_order_item__code: woi.code,
      end_date__null_or_gt: today,
    });
  }

  private _openWaitingCauseModal(waiting: IWaiting, isClosingContext: boolean): Promise<unknown> {
    return this.wcmModalsService.openComponent(
      WaitingEditionModalComponent,
      {
        waiting: waiting,
        endDateRequired: isClosingContext,
      },
      {
        backdrop: 'static',
        size: 'lg'
      }
    );
  }

  private _getTemplateEmail(template_type: string): Promise<ITemplateEmail> {
    return this.apiShiva.template_emails.list({ type: template_type })
      .then((res: IListResult<ITemplateEmail>) => {
        // if only one template email we don't need to open the template modal chooser
        if (res.count === 1) {
          return res.results[0];
        } else {
          const modal = this.ngbModal.open(TemplateEmailsModalComponent, {
            backdrop: 'static',
            size: 'lg'
          });
          modal.componentInstance.disabledButtons = { create: true };
          modal.componentInstance.filters = { type: template_type };
          return modal.result;
        }
      })
      .catch(err => console.error(err));
  }

  private _getShippingMailData(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    this.apiShiva.work_order_items.get_shipping_mail_data(woi.id)
      .then(res => {
        woi.dataForDynamicVar = res;
        deferred.resolve();
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'EXPED_AND_EXPER_ARE_MISSING') {
            this.toastr.error(`Cette tâche est liée à une demande logistique qui ne possède ni tâche d'expédition ni tâche de retour.`);
            return;
          }
        }
        this.toastr.error('Erreur lors du chargement des variables dyamiques.');
        Promise.reject(err);
      });
    return deferred.promise;
  }


  private _openEmailModal(
    mailObject: Object,
    woi: IWorkOrderItems,
    templateEmailId: string,
    templateEmailLastAttachmentId: string,
    templateEmailSenderAddress: string,
    templateEmailCcAddress: string,
    templateEmailSubject: string,
  ): Promise<Record<string, unknown>> {
    const deferred = this.promisesService.defer();
    this.mappingEmailCC = {
      intervention: ['deploiement@waycom.net'],
      recovery: ['logistique@waycom.net'],
    };

    const modal = this.ngbModal.open(EmailModalComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    // passing the data
    modal.componentInstance.type = mailObject['type'];
    modal.componentInstance.modalTitle = mailObject['modalTitle'];
    modal.componentInstance.modalSendButton = mailObject['modalSendButton'];
    modal.componentInstance.fillFunction = (templateEmail: string): string => this._fillFunction(templateEmail, woi);
    modal.componentInstance.attachmentModelPk = woi.id;
    modal.componentInstance.attachmentModel = 'work-order-items';
    modal.componentInstance.attachmentsApi = this.apiShiva.attachments_ng;
    modal.componentInstance.templateEmailId = templateEmailId;
    modal.componentInstance.templateEmailLastAttachmentId = templateEmailLastAttachmentId;
    modal.componentInstance.templateEmailSenderAddress = templateEmailSenderAddress;
    modal.componentInstance.templateEmailCcAddress = templateEmailCcAddress ? templateEmailCcAddress.replace(' ', '').split(',') : this.mappingEmailCC[mailObject['type']];
    modal.componentInstance.emailTo = mailObject['emailTo'];
    modal.componentInstance.typeEmail = woi.product.code === 'P-QPEWV' ? 'intervention' : 'recovery';
    modal.componentInstance.defaultEmailSubject = templateEmailSubject
      ? this._fillFunction(templateEmailSubject, woi)
      : this._generateDefaultEmailSubject(woi);


    modal.result
      .then((res: IEmailModal) => {
        const context = {
          address_to: woi.product.code === 'P-NEYLU' ? res.emailTo : '',
          message_html: res.content,
          message_attachment_ids: res.attachments,
          cc_addresses_list: res.cc,
          sender_address: res.senderAddress,
          subject: res.subject
        };
        this.signalsService.broadcast('attachments-list-refresh');
        // Resolving the transition with email content in context
        deferred.resolve(context);
      })
      .catch(() => deferred.reject());
    return deferred.promise;
  }

  private _generateDefaultEmailSubject(woi: IWorkOrderItems): string {
    let defaultSubjet: string;
    switch (woi.product.code) {
      case 'P-QPEWV':
        defaultSubjet = `Intervention: ${woi.code}`;
        break;
      case 'P-NEYLU':
        defaultSubjet = `[Waycom] Retour d’équipement : ${woi.state.name === 'in-progress' ? 'Première relance' : 'Dernière relance'} ${woi.code}`;
        break;
      default:
        defaultSubjet = 'Objet par défaut';
    }
    return defaultSubjet;
  }

  /**
   * Setting a comment about waiting-check-network state is mandatory
   * If no comment sent, the state transition is cancelled
   */
  private _setCommentAboutWaitingCheckNetworkState(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    const modal = this.ngbModal.open(WorkOrderItemsAddCommentModalComponent, {
      backdrop: 'static',
      size: 'lg'
    });
    modal.componentInstance.woi = {
      id: woi.id,
      code: woi.code,
    };
    modal.result
      .then(() => deferred.resolve())
      .catch(() => {
          this.toastr.error('Changement de statut annulé.');
          deferred.reject();
      });
    return deferred.promise;
  }

  /**
   * Check if the order transition can be played in case of P-ORFOP product (automatic processing).
   * The appointment date must be today +7 day minimum in order to allow the transition
   */
  private _checkFOPAppointmentDate(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    // ensure that a metadata object is defined
    woi.metadata = woi.metadata || {};
    const todayPlus7d = moment().add(7, 'd');
    // moment of null value will give an invalid moment date objet that will not pass the check
    // moment of undefined value will give today's date, and it will not pass the check
    if (!moment(woi.metadata.appointment_date).isSameOrAfter(todayPlus7d)) {
      // invalid or missing date
      const modal = this.ngbModal.open(FopInvalidAppointmentDateModalComponent, { backdrop: 'static' });
      modal.componentInstance.userInvalidDate = woi.metadata.appointment_date;
      modal.result
        .then(
          (res) => {
            // updating the appointment date and saving before playing the transition
            woi.metadata.appointment_date = res.appointment_date;
            // Resolving the transition after the save is done
            this.apiShiva.work_order_items.update(woi.code, woi)
              .then(() => deferred.resolve())
              .catch(() => {
                this.toastr.error('Erreur lors de la mise à jour de la date de rendez-vous.');
                deferred.reject();
              });
          },
          () => deferred.reject());
    } else {
      // the date is OK
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * Check if the order transition can be played in case of P-LINKS/P-INTER products.
   */
  private _checkGenericLineOrderTransition(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    const olCode = woi.metadata?.operator_line_code;
    if (olCode) {
      this.apiProvitool.operator_lines.detail(olCode).then(
        res => {
          if (res.offer?.has_collection_nodes && !res.collection_node) {
            const modalBody = `
              Aucune information concernant la porte de collecte renseignée sur le lien opérateur.
              <br><br>
              <i>Il vous est possible d'éditer cette information directement dans cette tâche,
              dans l'onglet informations, en cliquant sur le lien opérateur.</i>`;
            this.wcmModalsService.alert('Porte de collecte non renseignée', modalBody)
              .then(() => deferred.reject())
              .catch(() => deferred.reject());
          } else {
            deferred.resolve();
          }
        },
        err => {
          console.error(err);
          this.toastr.error('Echec de récupération du lien opérateur.');
          deferred.reject();
        }
      );
    } else {
      // There's no OL so it's ok
      deferred.resolve();
    }
    return deferred.promise;
  }

  /**
   * Check if the done transition can be played in case of P-LINKS/P-INTER products.
   */
  private _checkGenericLineDoneTransition(woi: IWorkOrderItems): Promise<void> {
    const deferred = this.promisesService.defer();
    const olCode = woi.metadata?.operator_line_code;
    if (olCode) {
      this.apiProvitool.operator_lines.detail(olCode).then(
        res => {
          if (res.offer?.has_collection_nodes && !res.vlan) {
            const modalBody = `
              Aucune information VLAN renseignée sur le lien opérateur.
              <br><br>
              <i>Il vous est possible d'éditer cette information directement dans cette tâche,
              dans l'onglet informations, en cliquant sur le lien opérateur.</i>`;
            this.wcmModalsService.alert('VLAN non renseigné', modalBody)
              .then(() => deferred.reject())
              .catch(() => deferred.reject());
          } else {
            deferred.resolve();
          }
        },
        err => {
          console.error(err);
          this.toastr.error('Echec de récupération du lien opérateur.');
          deferred.reject();
        }
      );
    } else {
      // There's no OL so it's ok
      deferred.resolve();
    }
    return deferred.promise;
  }

  private _fillFunction(templateEmail: string, woi: IWorkOrderItems): string {
    if (woi.product.code === 'P-QPEWV') {
      return this._replaceVarForIntervention(templateEmail, woi);
    } else if (woi.product.code === 'P-NEYLU') {
      return this._replaceVarForRecovery(templateEmail, woi);
    }
  }

  /**
   * For the given emailContent, replace all dynamic variables [[var_example]], with values from woi
   */
  private _replaceVarForIntervention(emailContent: string, woi: IWorkOrderItems): string {
    const displayDate = woi.provisional_start_date ? moment(woi.provisional_start_date).format('DD/MM/YYYY') : '';
    const displayTime = woi.provisional_start_date ? moment(woi.provisional_start_date).format('LT') : '';

    // to avoid 'undefined' display on location
    const displayLocation =
      `${woi.location?.address || ''},
      ${woi.location?.zipcode || ''}
      ${woi.location?.city || ''}`;

    // convert markdown note to html
    const displayNote = woi.note ? this.markdownService.compile(woi.note) : '';

    // get winter href url
    const validationUrl = this.apiWinter.validateIntervention(woi.metadata?.receipt_code);

    // replace values in template with woi details
    return emailContent
      .replace('[[code_woi]]', woi.code)
      .replace('[[Nom entité]]', woi.work_order?.entity?.name || '')
      .replace('[[Ville entité]]', woi.work_order?.entity?.location?.city || '')
      .replace('[[Ref client entité]]', woi.work_order?.entity?.customer_ref || '')
      .replace('[[provisional_start_date]]', displayDate)
      .replace('[[provisional_start_time]]', displayTime)
      .replace('[[location]]', displayLocation)
      .replace('[[contact]]', this.formatContactForEmail(woi.contact))
      .replace('[[note]]', displayNote)
      .replace('[[validation_url]]', validationUrl)
      .replace('[[contractor__intervention_cost]]', woi.contractor?.intervention_cost || '')
      .replace('[[created_by]]', this.userService.getUserFullName());
  }

  /**
   * For the given emailContent, replace all dynamic variables [[var_example]]
   */
  private _replaceVarForRecovery(emailContent: string, woi: IWorkOrderItems): string {
    if (!woi.dataForDynamicVar) {
      return emailContent;
    }

    return emailContent
      .replace('[[code_woi]]', woi.code)
      .replace('[[Nom entité]]', woi.work_order?.entity?.name || '')
      .replace('[[Ville entité]]', woi.work_order?.entity?.location?.city || '')
      .replace('[[Ref client entité]]', woi.work_order?.entity?.customer_ref || '')
      .replace('[[date_envoi_pexped]]', woi.dataForDynamicVar['sending_woi_exped_date'] || '[[date_envoi_pexped]]')
      .replace('[[type_eqp_envoye]]', woi.dataForDynamicVar['eqp_type'] || '[[type_eqp_envoye]]')
      .replace('[[modele_eqp_envoye]]', woi.dataForDynamicVar['sending_eqp_model'] || '[[modele_eqp_envoye]]')
      .replace('[[SN_eqp_envoye]]', woi.dataForDynamicVar['sending_sn'] || '[[SN_eqp_envoye]]')
      .replace('[[type_eqp_remplacer]]', woi.dataForDynamicVar['eqp_type'] || '[[type_eqp_remplacer]]')
      .replace('[[modele_eqp_remplacer]]', woi.dataForDynamicVar['replace_eqp_modele'] || '[[modele_eqp_remplacer]]')
      .replace('[[SN_eqp_remplacer]]', woi.dataForDynamicVar['replace_sn'] || '[[sn_eqp_remplacer]]')
      .replace('[[nom_parent]]', woi.dataForDynamicVar['parent_name'] || '[[nom_parent]]')
      .replace('[[nom_site]]', woi.dataForDynamicVar['entity_name'] || '[[nom_site]]')
      .replace('[[ville]]', woi.dataForDynamicVar['city'] || '[[ville]]')
      .replace('[[shipment_ref_pexper]]', woi.dataForDynamicVar['shipment_ref_woi_exper'] || '[[shipment_ref_pexper]]')
      .replace('[[shipment_ref_pexped]]', woi.dataForDynamicVar['shipment_ref_woi_exped'] || '[[shipment_ref_pexped]]')
      .replace('[[libelle_statut_cible]]', woi.dataForDynamicVar['libelle_statut_cible'] || '[[libelle_statut_cible]]')
      .replace('[[due_date]]', woi.dataForDynamicVar['due_date'] || '[[due_date]]')
      .replace('[[due_time]]', woi.dataForDynamicVar['due_time'] || '[[due_time]]')
      .replace('[[list_equipments]]', woi.dataForDynamicVar['list_equipments'] || '[[list_equipments]]')
      .replace('[[contact]]', this.formatContactForEmail(woi.contact))
      .replace('[[created_by]]', this.userService.getUserFullName());
  }
}
