import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';

import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { isArray, omit } from '@core/helpers';
import { PromisesService } from '@core/services/promises.service';
import { ReplaySubject, Subject } from 'rxjs';

interface IUser {
  email: string;
  first_name: string;
  last_name: string;
  name: string;
  permissions: string[];
  poles: string[];
  preferences: Record<string, unknown>;
  related_entities: unknown[];
  username: string;
}
interface IPermissionDict {[key: string]: { [key: string]: boolean }}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private _tokenInfo: any;
  private _info: any;
  private _loaded: boolean;
  private _permissions: IPermissionDict;

  public readonly currentUser: Subject<IUser> = new ReplaySubject<IUser>();
  public readonly currentUserPermissions: Subject<IPermissionDict> = new ReplaySubject<IPermissionDict>(1);

  constructor(
    private promisesService: PromisesService,
    private apiSiAuth: ApiSiAuthService
  ) {
    this._tokenInfo = {};
    this._loaded = false;
    this._info = {};
  }

  // This function loads the user data into the app
  // It will fetch its information and permissions and store them
  public load(parsedToken, forceRefresh = false): Promise<any> {
    this._tokenInfo = parsedToken;
    const deferred = this.promisesService.defer();
    if (this._loaded && !forceRefresh) {
      deferred.resolve();
    } else {
      // Fetch the user info and its permissions
      const promises = {
        info: this.apiSiAuth.userInfo(),
        // other api call is needed
      };
      this.promisesService.all(promises).then((res) => {
        this._info = omit(res.info.data, 'permissions');
        const permissions = res?.info?.data?.permissions;
        this._permissions = this._buildPermissionsDict(permissions || []);
        this.currentUser.next(this._info);
        this.currentUserPermissions.next(this._permissions);
        this._loaded = true;

        // add user context to sentry errors
        const sentryUser = omit(this._info, 'preferences');
        // sentry wants an `id` property
        sentryUser.id = sentryUser.username;
        Sentry.setUser(sentryUser);

        deferred.resolve();
      }).catch((err) => {
        deferred.reject(err);
      });
    }
    return deferred.promise;
  }

  public getInfo(): IUser {
    return this._info;
  }

  public getUserFullName(user?: IUser | undefined): string {
    if (user) {
      return this.getFullNameFromUser(user);
    } else {
      const userInfo: IUser = this.getInfo();
      return this.getFullNameFromUser(userInfo);
    }
  }

  public getFullNameFromUser(userInfo: IUser): string {
    return userInfo.last_name ? `${userInfo.first_name} ${userInfo.last_name}` : userInfo.username;
  }

  public getThumbnailUrl(): Promise<any> {
    const deferred = this.promisesService.defer();

    const checkForUsername = () => {
      if (this._info.username) {
        deferred.resolve(this.apiSiAuth.getThumbnailUrl(this._info.username));
      } else {
        setTimeout(checkForUsername, 300);
      }
    };

    checkForUsername();

    return deferred.promise;
  }

  // Usage:
  // this.hasPermissions('Firewall:Admin', 'Dashboard:Admin')
  // Return true if the user has at least one of the given permissions
  // Logical OR between the given permissions
  public hasPermissions(...permissions: string[]): boolean {
    return permissions.some((perm) => {
      const [app, permissionsName] = perm.split(':');
      return this._hasPermission(app, permissionsName);
    });
  }

  // This function does a check for a single permission
  private _hasPermission(app: string, permissionName: string): boolean {
    return this._permissions[app] && this._permissions[app][permissionName];
  }

  // list the user permissions
  public getAuthorizations() {
    const userData = this._info;
    if (!userData.memberOf || !isArray(userData.memberOf)) {
      return [];
    }

    const authList = {};
    userData.memberOf.forEach(group => {
      const keyValList = group.split(',');
      let addGroup = false;
      let permissionName;
      let backendName;
      keyValList.forEach(keyVal => {
        keyVal = keyVal.trim();
        // Searching for OU=WCM_Permissions
        if (keyVal === 'OU=WCM_Permissions') {
          addGroup = true;
        } else if (keyVal.startsWith('OU=')) {
          const OUName = keyVal.split('=')[1];
          const ignoredOU = ['WAYCOM - Ressources', 'wcmnoc', 'local'];
          if (ignoredOU.indexOf(OUName) === -1) {
            backendName = OUName;
          }
        } else if (keyVal.startsWith('CN=')) {
          permissionName = keyVal.split('=')[1];
        }
      });
      if (addGroup && permissionName && backendName) {
        if (authList[backendName]) {
          authList[backendName].push(permissionName);
        } else {
          // init the permission list for this backend
          authList[backendName] = [permissionName];
        }
      }
    });

    return authList;
  }

  public updatePreferences(preferences): Promise<any> {
    return this.apiSiAuth.updatePreferences(this._info.username, preferences);
  }

  private _buildPermissionsDict(permissions: string[]): IPermissionDict {
    const permissionsDict = {};
    permissions.forEach(permission => {
      const [appName, permissionsName] = permission.split(':');

      permissionsDict[appName] = permissionsDict[appName] || {};
      permissionsDict[appName][permissionsName] = true;
    });

    return permissionsDict;
  }
}
