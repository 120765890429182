import { Component, Injector, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';

import * as moment from 'moment';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ApiDashboardService } from '@core/apis/api-dashboard.service';
import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { IGenericListOptions, IInterventionTag, IWorkOrderItems, ISelectedItems, ISelectedPk } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';

import { TagBulkUpdateService } from '@views/tags/tag-bulk-update.service';
import { UsersModalComponent } from '@views/users/users-modal.component';

import { WorkOrderItemsBulkPlayTransitionModalComponent } from './work-order-items-bulk-play-transition-modal.component';
import { WorkOrderItemsBulkCloseModalComponent } from './work-order-items-bulk-close-modal.component';
import { WorkOrderItemsImportModalComponent } from './work-order-items-import-modal.component';
import { WorkOrderItemsMEPImportModalComponent } from './work-order-items-mep-import-modal.component';
import { WorkOrderItemsListBulkUpdateActionsModalComponent } from './work-order-items-list-bulk-update-actions-modal.component';
import { WorkOrderItemsStateService } from './work-order-items-state.service';
import { ITransitionPayload } from './work-order-items-completion-date-modal.component';
import { BulkyExportWarningService } from '@app/core/components/bulky-export-warning/bulky-export-warning';

@Component({
  selector: 'app-work-order-items-list',
  templateUrl: './work-order-items-list.component.html',
  styleUrls: ['./work-order-items-list.component.less'],
  // The fact that there is no encapsulation make the css available to all the project
  // This is necessary otherwise the css doesn't apply to the child elements
  encapsulation: ViewEncapsulation.None,
})
export class WorkOrderItemsListComponent extends GenericListComponent implements OnInit {

  @ViewChild('wcmTable', { static: true }) public wcmTable: WcmTableComponent;
  @Input() public newBtnLabel: string;

  public readonly shipmentTypeOptions = {
    RETURN: 'Retour',
    SEND: 'Envoi'
  };
  public readonly serviceTypeOptions = {
    STANDARD: 'Standard',
    EXPRESS_SAVER: 'Express Saver',
    EXPRESS: 'Express',
    EXPRESS_PLUS: 'Express Plus'
  };
  public readonly contractorFailOptions = {
    customer: 'Cause Client',
    waycom: 'Cause Waycom',
    contractor: 'Cause Prestataire'
  };

  public readonly woiListOptions: IGenericListOptions = {
    filters: {
      limit: 25,
      ordering: '-date',
      serializer: 'woi_main_list'
    },
    disabledColumns: {
      completion_date: true,
      contact__last_name: true,
      created_by: true,
      last_comment_date: true,
      location__city: true,
      location__country: true,
      location__zipcode: true,
      logistics_site__name: true,
      logistics_request__title_or_code: true,
      metadata__cause_ko: true,
      metadata__escalade: true,
      metadata__process: true,
      metadata__provider: true,
      metadata__service_type: true,
      metadata__shipment_ref: true,
      metadata__shipment_type: true,
      metadata__ups_state: true,
      modified_at: true,
      metadata__operator_line_offer_technology: true,
      metadata__operator_line_offer_provider: true,
      processing: true,
      provider__name: true,
      provisional_end_date: true,
      quantity: true,
      reminder_date: true,
      traceability__code: true,
      traceability__name: true,
      work_order__entity__code: true,
      work_order__entity__customer_ref: true,
      work_order__entity__parent__code: true,
      work_order__entity__parent__name: true,
      work_order__order__assignee: true,
      work_order__order__project__name: true,
      work_order__order__project__type: true,
      work_order__order__state: true,
    }
  };

  // keep special filters 'user_watch' // 'user_not_watch'
  public readonly ignoredFiltersForStr = ['assigned-to-me', 'not-assigned', 'created-by-me'];
  public readonly liveUpdateChannel = 'WorkOrderItem';
  public loadingAction = false;
  // state field
  public stateConfirmFunctMap: Record<string, (transitionName?: string | undefined) => Promise<unknown>>;
  public stateErrorFuncMap: Record<string, (transitionName?: string | undefined) => Promise<unknown>>;
  private woiTransition: IWorkOrderItems; // woi selected for workflow transition
  private readonly currentDate = moment();
  private readonly username: string;

  constructor(
    public injector: Injector,
    private ngbModal: NgbModal,
    private signalsService: SignalsService,
    private apiDashboard: ApiDashboardService,
    private wcmModalsService: WcmModalsService,
    private workOrderItemsStateService: WorkOrderItemsStateService,
    private readonly tagBulkUpdateService: TagBulkUpdateService,
    private bulkyExportWarningService: BulkyExportWarningService,
  ) {
    super(injector);
    this.username = this.userService.getInfo().username;
    this.localDisabledColumns = this.woiListOptions.disabledColumns;
    this.localFilters = this.woiListOptions.filters;
  }

  public ngOnInit(): void {
    super.ngOnInit();

    //   /!\ If you add a confirm function here, you also have to add it
    //        to stateConfirmFunctMap in work-order-items-detail
    this.stateConfirmFunctMap = {
      'start': this._sendEmail.bind(this),
      'waiting-check-network': this._waitingCheckNetworkStateTransition.bind(this),
      'in-progress': this._sendEmail.bind(this),
      'first-recovery': this._sendEmail.bind(this),
      'last-recovery': this._sendEmail.bind(this),
      'finish': this._setCompletionDateOnFinish.bind(this),
      'order': this._orderStateTransition.bind(this),
      'in-progress-to-validate': this._interventionTagSelector.bind(this),
      'in-progress-validate-to-validate': this._interventionTagSelector.bind(this),
      'in-progress-validate-to-done-ko': this._setCauseKo.bind(this),
      'in-progress-validate-to-in-progress': this._setInProgress.bind(this),
      'done-ko': this._setCauseKo.bind(this),
      'done': this._doneStateTransition.bind(this),
      'in-progress-validate-to-done': this._setCompletionDateOnFinish.bind(this),
      'cancel': this._cancelStateTransition.bind(this)
    };

    this.stateErrorFuncMap = {
      'first-recovery': this._handleEmailError.bind(this),
      'last-recovery': this._handleEmailError.bind(this),
    };
  }

  public fetchCallback(event): void {
    // after the fetch we update the results to set the reminder css class based on today date
    if (event.isSuccess && event.items) {
      event.items.forEach((item) => {
        item.reminderClass = this._getReminderDateClass(item);
      });
    }
  }

  public export(filters): void {
    const promise = this.bulkyExportWarningService.warningExport(300, this.wcmTable.itemCount);
    promise
      .then(() => this._doExport(filters))
      .catch(() => {});
  }

  public _doExport(filters) {
    this.loadingAction = false;
    this.apiShiva.work_order_items.export(filters)
      .then((res) => {
        // sending a signal to start a watcher for this job
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch((err) => Promise.reject(err))
      .finally(() => this.loadingAction = false);
  }

  public showImportModal(): void {
    this.ngbModal.open(WorkOrderItemsImportModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
  }

  public showImportMEPModal(): void {
    this.ngbModal.open(WorkOrderItemsMEPImportModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
  }

  public showImportSdasModal(wcmTable: WcmTableComponent): void {
    const modal = this.ngbModal.open(FileUploadModalComponent, {
      size: 'lg',
      backdrop: 'static'
    });
    modal.componentInstance.uploadUrl = this.apiProvitool.sdas.import_sdas_for_porta();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import de Sdas';

    modal.result.then(
      () => wcmTable.refreshTable(),
      () => {}
    );
  }

  public downloadTemplate() {
    this.apiShiva.templates.detailByName('import_sdas_for_porta')
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      })
      .catch(() => this.toastr.error('Erreur lors du téléchargement du template'));
  }

  public quickFilter(filterName: string, filters): void {
    // The quick filters are stored in the table filters and the url
    // Getting the existing value for the quick filter if it does exist
    const existingQuickFilter = filters[filterName] !== undefined;

    // because they are exclusive, we remove all the quick filters
    delete filters['assigned-to-me'];
    delete filters['not-assigned'];
    delete filters['created-by-me'];
    delete filters['user_watch'];
    delete filters['user_not_watch'];

    // then, if the existingQuickFilter is not defined,
    // that means that we are activating the given filterName
    if (!existingQuickFilter) {
      filters[filterName] = true;
    }

    // Translating the quick filter in to regular filters
    // Because the quick filters are exclusives, we must clear beforehand all the quick filters related field
    delete filters['assignee'];
    delete filters['assignee__isnull'];
    delete filters['created_by'];
    // this one is removed because the user can't remove it, and it is set directly after a woi creation (redirection)
    delete filters['code__in'];

    // 2 Exclusive cases for the assignee
    if (filters['assigned-to-me']) {
      filters['assignee'] = this.username;
      delete filters['assignee__isnull'];
    } else if (filters['not-assigned']) {
      filters['assignee__isnull'] = true;
      delete filters['assignee'];
    }

    if (filters['created-by-me']) {
      filters.created_by = this.username;
    }

    // 2 Exclusive cases for user watch
    if (filters.user_watch) {
      filters.user_watch = this.username;
      delete filters.user_not_watch;
    }
    if (filters.user_not_watch) {
      filters.user_not_watch = this.username;
      delete filters.user_watch;
    }

    this._refreshTable();
  }

  // ---------------------------------------------------------
  // Items quick actions
  // ---------------------------------------------------------
  public assignToMyself(item: IWorkOrderItems): void {
    this._assignOneToUser(item, this.username);
  }

  public assignTo(item: IWorkOrderItems): void {
    const modal = this.ngbModal.open(UsersModalComponent);
    modal.result.then(
      result => this._assignOneToUser(item, result.username),
      () => {}
    );
  }

  public transitionLoading(item: IWorkOrderItems, loading: boolean): void {
    item.loadingTransitions = loading;
  }

  public fetchTransitions(modalIsOpening: boolean, item: IWorkOrderItems): void {
    // if the modal is being closed or the transitions have been already fetched for this item,
    // we don't do the fetch again
    if (!modalIsOpening || item.fullDetail) {
      // select the woi for workflow transition
      this.woiTransition = item.fullDetail;
      return;
    }

    item.loadingTransitions = true;
    // To play workflow transitions, we need same level of woi information as on detail view
    this.apiShiva.work_order_items.detail(item.code)
      .then((res: IWorkOrderItems) => {
        item.state = res['state'];
        item.fullDetail = res;
        // select the woi for workflow transition
        this.woiTransition = res;
      })
      .catch(err => {
        console.error(`Erreur lors de la récupération du détail de cette tâche`, err);
        this.toastr.error('Erreur lors de la récupération du détail de cette tâche. Veuillez essayer à nouveau.');
      })
      .finally(() => item.loadingTransitions = false);
  }

  // ---------------------------------------------------------
  // Bulk action (for selected items)
  // ---------------------------------------------------------

  public bulkUpdate(action: string, selectedIds: ISelectedPk): void {
    const ids = Object.keys(selectedIds);
    const modal = this.ngbModal.open(WorkOrderItemsListBulkUpdateActionsModalComponent, { size: 'md' });
    modal.componentInstance.action = action;
    modal.componentInstance.selectedLength = ids.length;

    modal.result.then(
      updateFields => {
        this.loadingAction = true;
        this.apiShiva.work_order_items.bulk_update(ids, updateFields)
          .then(() => {
            this.loadingAction = false;
            this.clearSelection();
            this._refreshTable();
          })
          .catch(err => Promise.reject(err));
      },
      () => {}
    );
  }

  public assignMultipleToMyself(selectedIds: ISelectedPk): void {
    const ids = Object.keys(selectedIds);
    if (ids.length > 0) {
      this._assignMultipleToUser(ids, this.username);
    }
  }

  public assignMultipleTo(selectedIds: ISelectedPk): void {
    const ids = Object.keys(selectedIds);
    if (ids.length === 0) {
      return;
    }
    const modal = this.ngbModal.open(UsersModalComponent);

    modal.result.then(
      result => this._assignMultipleToUser(ids, result.username),
      () => {}
    );
  }

  public bulkCloseItems(desiredState: string, selectedIds: ISelectedPk): void {
    const ids = Object.keys(selectedIds);
    // The button in the view calls this function with the desired state being either
    // 'done' or 'cancelled'.
    const modalResult = this._closeItemsModal(desiredState, ids.length);
    // The modal returns a dictionary with 'completionDate' and 'comment' keys.
    // Note that in the case of cancelled there is no 'completionDate'
    // (and it is ignored by the backend if provided here).
    modalResult.then(
      data => this._updateItemsWorkflow(desiredState, data.completionDate, data.comment, ids),
      () => {}
    );
  }

  /**
   * Update the tags for one or more work order items
   * @param selectedItems The dictionary of items selected in the table
   */
  public bulkPatchTags(selectedItems: ISelectedPk): void {
    this.loadingAction = true;
    this.tagBulkUpdateService.selectTagsAndUpdate(
      Object.keys(selectedItems),
      this.apiShiva.work_order_items.bulk_patch_tags,
      'tâche'
    )
      .then(() => this.clearSelection())
      .finally(() => this.loadingAction = false);
  }

  public bulkWatch(selectedIds: ISelectedPk, becomingWatcher: boolean): void {
    const ids = Object.keys(selectedIds);
    this.loadingAction = true;

    this.apiDashboard.bulk_subscribe('WorkOrderItem', ids, becomingWatcher)
      .then(() => this.toastr.success('Modification du suivi prise en compte.'))
      .catch(() => this.toastr.error(`Échec de la modification du suivi. Veuillez essayer à nouveau.`))
      .finally(() => this.loadingAction = false);
  }

  public exportSelection(selectedIds: ISelectedPk): void {
    const ids = Object.keys(selectedIds);
    this.loadingAction = true;

    this.apiShiva.work_order_items.exportSelected(ids)
      .then(res => {
        // sending a signal to start a watcher for this job
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loadingAction = false);
  }

  public advanceSelection(selectedItems: ISelectedItems): void {
    const workflow: string = this._certifyUnicityWorflowAndState(selectedItems);
    if (!workflow) {
      return;
    }
    const selectedIds =  Object.keys(selectedItems);
    const modal = this.ngbModal.open(WorkOrderItemsBulkPlayTransitionModalComponent, { backdrop: 'static' });
    modal.componentInstance.selectedCount = Object.keys(selectedItems).length;
    modal.componentInstance.selectedIds = selectedIds;
    modal.componentInstance.workflow = workflow;

    modal.result.then((res: any) => {
      // at this step we are sure that all the selected items have both the same workflow and state.
      const source: string = selectedItems[selectedIds[0]].state.name;
      const labelSource: string = selectedItems[selectedIds[0]].state.label;
      const labelDestination: string = res['labelDestination'];
      this.toastr.info(`Demande prise en compte. Veuillez patienter le temps que le système fasse évoluer l'état des tâches.`);
      this.apiShiva.work_order_items.bulk_play_transition(workflow, source, res['destination'], selectedIds)
      .then((result: any) => {
        this.signalsService.broadcastJobStart(result['job_name'], result['job_id']);
        })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'UNAUTHORIZED_TRANSITION') {
            this.wcmModalsService.alert(
              `Erreur lors de la progression en masse du statut des tâches sélectionnées.`,
              `La transition ${labelSource} -> ${labelDestination} pour le workflow ${err['context']['workflow']}
              n'est pas acceptée.`
            );
          } else if (err.getFirstErrorMessage() === 'UNAUTHORIZED_WORKFLOW') {
            this.wcmModalsService.alert(
              `Erreur lors de la progression en masse du statut des tâches sélectionnées.`,
              `Le workflow ${err['context']['workflow']} n'est actuellement pas disponible pour la progression en masse.`
            );
          }
        } else {
          Promise.reject(err);
        }
      })
      .finally(() => this.clearSelection());
    }, () => {}); // mandatory when the modal is dismised
  }

  public clearSelection(): void {
    this.wcmTable.unselectAll();
  }

  private _assignOneToUser(item: IWorkOrderItems, username: string): void {
    const payload = {
      assignee: username
    };
    this.apiShiva.work_order_items.update(item.id, payload)
      .then((res: IWorkOrderItems) => item = {...item, ...res})
      .catch(err => Promise.reject(err));
  }

  private _assignMultipleToUser(ids: string[], username: string): void {
    const payload = {
      assignee: username
    };
    this.apiShiva.work_order_items.bulk_update(ids, payload)
      .then(() => {
        this.clearSelection();
        this._refreshTable();
      })
      .catch(err => Promise.reject(err));
  }

  private _closeItemsModal(desiredState: string, itemsCount: number): Promise<Record<string, string>> {
    const modal = this.ngbModal.open(WorkOrderItemsBulkCloseModalComponent, { backdrop: 'static' });
    // passing the data
    modal.componentInstance.selectedCount = itemsCount;
    modal.componentInstance.desiredState = desiredState;

    return modal.result;
  }

  private _updateItemsWorkflow(desiredState: string, completionDate: string, comment: string, selectedIds: string[]): void {
    this.loadingAction = true;
    const taskName = 'Mise à jour de statuts en masse';
    this.apiShiva.work_order_items.bulk_close(selectedIds, desiredState, taskName, completionDate, comment)
      .then(res => {
        // sending a signal to start a watcher for this job
        this.signalsService.broadcastJobStart(taskName, res['job_id']);
        this.clearSelection();
        this.toastr.success('La mise à jour des tâches a bien été prise en compte.');
      })
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'UNHANDLED_STATE') {
            this.toastr.error(`Impossible de fermer une tâche qui n'est pas en état Terminé ou Annulé.`);
            return;
          }
        }
        return Promise.reject(err);
      })
      .finally(() => this.loadingAction = false);
  }

  // ---------------------------------------------------------

  private _refreshTable(): void {
    this.wcmTable.refreshTable();
  }

  private _getReminderDateClass(item): string {
    const reminderDate = item.reminder_date;
    if (!reminderDate) {
      item.reminderClass = 'hide';
      return 'hide';
    }

    const reminderDateObj = moment(reminderDate);
    let cssClass = 'wcm-text-danger';
    if (reminderDateObj.isAfter(this.currentDate, 'day')) {
      cssClass = 'wcm-text-success';
    }
    return cssClass;
  }


  // ----------------------------------------
  // State field confirm function
  // ----------------------------------------
  private _orderStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.orderStateTransition(this.woiTransition);
  }

  private _setCauseKo(): Promise<ITransitionPayload> {
    return this.workOrderItemsStateService.setCauseKo(this.woiTransition);
  }

  private _setInProgress(): Promise<ITransitionPayload> {
    return this.workOrderItemsStateService.setInProgress(this.woiTransition);
  }


  private _setCompletionDateOnFinish(): Promise<ITransitionPayload> {
    return this.workOrderItemsStateService.setCompletionDateOnFinish(this.woiTransition);
  }

  private _sendEmail(): Promise<Record<string, unknown>> {
    return this.workOrderItemsStateService.sendEmail(this.woiTransition);
  }

  private _doneStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.doneStateTransition(this.woiTransition);
  }

  private _waitingCheckNetworkStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.waitingCheckNetworkStateTransition(this.woiTransition);
  }

  private _cancelStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.cancelStateTransition(this.woiTransition);
  }

  private _interventionTagSelector(transitionName: string): Promise<IInterventionTag> {
    return this.workOrderItemsStateService.interventionTagSelector(this.woiTransition, transitionName);
  }

  private _handleEmailError(error: unknown): void {
    this.workOrderItemsStateService.handleEmailError(error);
  }

  private _certifyUnicityWorflowAndState(selectedItems: any): string {
    const keys = Object.keys(selectedItems);
    let workflows = [];
    let sourceState = [];

    keys.forEach((key, index) => {
      workflows.push(selectedItems[key]['workflow']);
      sourceState.push(selectedItems[key]['state']['name']);
    });

    // (pour la review) https://stackoverflow.com/questions/1960473/get-all-unique-values-in-a-javascript-array-remove-duplicates
    workflows = workflows.filter((v, i, a) => a.indexOf(v) === i);
    sourceState = sourceState.filter((v, i, a) => a.indexOf(v) === i);
    if (workflows.length > 1) {
      this.toastr.error('Les tâches sélectionnées ne partagent pas toutes le même workflow.');
      return null;
    } else if (sourceState.length > 1) {
      this.toastr.error('Les tâches sélectionnées ne partagent pas toutes le même état.');
      return null;
    }
    return workflows[0];
  }
}
