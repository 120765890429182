import { Component, Input, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { ApiHedwigService } from '@core/apis/api-hedwig.service';
import { IPrinterOption, PRINTER_OPTIONS } from '@core/constants';
import { LabelPreferencesService } from '@core/services/label-preferences.service';


@Component({
  selector: 'app-print-shipping-label-button',
  templateUrl: './print-shipping-label-button.component.html',
  styleUrls: ['./print-shipping-label-button.component.less']
})
export class PrintShippingLabelButtonComponent implements OnInit {
  @Input() public detail: any;
  @Input() public labelRefs: string[];
  @Input() public isSmallBtn = false;

  public readonly printerOptions: IPrinterOption[] = PRINTER_OPTIONS;
  public selectedPrinter: string;
  public printing = false;

  constructor(
    private readonly apiHedwigService: ApiHedwigService,
    private readonly toastr: ToastrService,
    public readonly labelPreferencesService: LabelPreferencesService,
  ) { }

  public ngOnInit(): void {
    this.selectedPrinter = this.detail?.logistics_site?.printer || this.labelPreferencesService.getInitialValue();
  }

  public print() {
    this.printing = true;
    for (const ref of this.labelRefs) {
      if (ref) {
        this.apiHedwigService.print_label(ref, this.selectedPrinter).then((res) => {
          this.toastr.success(`Étiquette ${ref} envoyée à l'imprimante.`);
        }, (err) => {
          this.toastr.error(`Erreur lors de la demande d'impression de l'étiquette ${ref}.`);
        }).finally(() => {
          this.printing = false;
        });
      } else {
         this.toastr.error(`Erreur lors de la demande d'impression de l'étiquette. Celle-ci n'a pas de référence d'envoi UPS`);
         this.printing = false;
      }
    }
  }
}
