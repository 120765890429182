<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">

    <div class="page-header header-xs flex">
      <h4 class="flex-left">Données à remplir par le réseau</h4>
      <span class="flex-right action-btn-bar" *ngIf="hasPermissions('Antoine:CanPrintLabel')">
        <app-print-label-button [disabled]="mode === 'edition'" [buildPrintData]="buildPrintData" [detail]="woi"></app-print-label-button>
      </span>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Équipement à provisionner</label>
      <div class="col-md-8 col-lg-4">
        <app-network-device-field [filters]="{entity__code: woi?.work_order?.entity?.code}"
                                  [disabled]="mode === 'normal' || !woi.work_order.entity"
                                  [(ngModel)]="networkDevice" name="networkDevice"
                                  (ngModelChange)="onChangeNetworkDevice($event)">
        </app-network-device-field>
      </div>
      <label class="col-md-4 col-lg-2 control-label">Équipement à prélever</label>
      <div class="col-md-8 col-lg-4" *ngIf="woi?.metadata?.requested_equipment?.code">
        <app-equipment-field [(ngModel)]="woi.metadata.requested_equipment" name="equipment" [disabled]="true">
        </app-equipment-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Modèle d'équipement<i class="required">*</i></label>
      <div class="col-md-8 col-lg-4">
        <app-equipment-model-field class="wcm-highlight-req" [disabled]="mode === 'normal'" [required]="true"
                                   [filters]="equipmentModelFieldFilters"
                                   [(ngModel)]="equipmentModel" name="equipmentModel"
                                   (ngModelChange)="onChangeEquipmentModel()">
        </app-equipment-model-field>
      </div>
      <label class="col-md-4 col-lg-2 control-label">Numéro de série à prélever</label>
      <div class="col-md-8 col-lg-4" *ngIf="woi?.metadata?.requested_serial_number">
        <div class="flex-left">
          <p class="form-control-static">{{woi?.metadata?.requested_serial_number}}</p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Nom de l'équipement</label>
      <div class="col-md-8 col-lg-4">
        <input class="form-control" [readonly]="mode === 'normal'"
               name="eqpName" [(ngModel)]="eqpName"
               (ngModelChange)="onChangeEqpName($event)"/>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label" for="dhcp">Branchement DHCP</label>
      <div class="col-sm-4">
        <div class="checkbox">
          <p-checkbox [disabled]="mode === 'normal'"
                      [binary]="true" inputId="dhcp"
                      [(ngModel)]="dhcp" name="dhcp"
                      (ngModelChange)="woi.metadata.dhcp = dhcp">
          </p-checkbox>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Branchement WAN</label>
      <div class="col-sm-4">
        <select class="form-control" [disabled]="mode === 'normal'"
                [(ngModel)]="wan" name="wan"
                (ngModelChange)="woi.metadata.wan = wan">
          <option value=""></option>
          <option value="adsl_vdsl">ADSL/VDSL</option>
          <option value="sdsl_efm">SDSL EFM</option>
          <option value="ftth_l2tp_c2eOptique">FTTH/L2TP/C2E Optique</option>
        </select>
      </div>
    </div>

    <div class="page-header header-xs" style="margin-top: 30px;">
      <h4>Données à remplir par la logistique</h4>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Console</label>
      <div class="col-md-8 col-lg-4">
        <select class="form-control" [disabled]="logisticsReadOnly()"
                [(ngModel)]="selectedConsole" name="selectedConsole" (ngModelChange)="onChangeConsole(selectedConsole)"
                [compareWith]="compareConsoleFn">
          <optgroup *ngFor="let bench of consoles" label="{{bench.name}}">
            <option *ngFor="let console of bench.items" [ngValue]="console">Console {{console.cname}}</option>
          </optgroup>
        </select>
      </div>

      <div *ngIf="selectedConsole">
        <label class="col-md-4 col-lg-2 control-label">@IP</label>
        <div class="col-md-8 col-lg-4 flex">
          <div class="flex-left">
            <p class="form-control-static">{{fullIpDisplayed}}</p>
          </div>
          <div class="flex-right flex-v-center">
            <app-copy-to-clipboard [data]="fullIpDisplayed"></app-copy-to-clipboard>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-4 col-lg-2 control-label">Équipement branché</label>
      <div class="col-md-8 col-lg-4">
        <app-equipment-field [filters]="{location: EquipmentLocationEnum.Stock, entity__isnull: true}"
                             [disabled]="logisticsReadOnly()"
                             [(ngModel)]="pluggedEquipment" name="pluggedEquipment"
                             (ngModelChange)="onChangePluggedEquipment()">
        </app-equipment-field>
      </div>

      <div *ngIf="pluggedEquipment">
        <label class="col-md-4 col-lg-2 control-label">SN</label>
        <div class="col-md-8 col-lg-4">
          <p class="form-control-static">
            {{pluggedEquipment.serial_number}}
          </p>
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="col-md-offset-4 col-lg-offset-2 col-md-8 col-lg-4">
        <button class="btn btn-primary" (click)="saveAndRunTransition()"
                [disabled]="saveAndRunTransitionIsDisabled()">
          Enregistrer et changer le statut vers "Equipement branché"
        </button>
      </div>
    </div>
  </form>
</div>
