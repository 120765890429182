<app-wcm-table #wcmTable [api]="apiShiva.equipments" [filters]="localFilters" [disabledColumns]="localDisabledColumns"
               refreshSignal="equipments-list-refresh" urlUpdateSignal="equipments-list-url-update"
               (rowClick)="rowClick?.emit($event)" [enableRowClick]="chooserRowClick"
               [disableUrlUpdate]="disableUrlUpdate" [ignoredFiltersForStr]="ignoredFiltersForStr"
               id="equipments-list" [allowPreferences]="allowPreferences"
               (fetchCallback)="fetchCallback()">
  <ng-template appWcmHeaderTitle text="Équipements" targetRouterLink="/equipments/list"></ng-template>
  <ng-template appWcmHeaderRight>
    <label type="label" class="label inverted-primary button-like-label"
           style="margin-right: 5px" *ngIf="showPrice">
      Prix d'achat total des équipements :
      <i class="fa fa-spinner fa-spin " *ngIf="loadingPrice"></i>
      <span *ngIf="!loadingPrice">{{ totalPrice | currency:'EUR':'symbol':'1.2-2' }}</span> (H.T.)
    </label>

    <button *ngIf="!localDisabledButtons.export && hasPermissions('Antoine:ExportAdmin')"
            class="btn btn-default"
            [disabled]="loadingExport"
            (click)="exportResults()">
      <i class="far fa-file-excel"></i> Exporter
    </button>

    <div class="btn-group">
      <button *ngIf="hasPermissions('Wira:EqpAdmin')"
              type="button"
              class="btn btn-default"
              (click)="showImportEquipmentsModal()">
        <i class="far fa-file-excel"></i> Importer des équipements
      </button>
      <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
        <span class="caret"></span>
      </button>
      <ul class="dropdown-menu" aria-labelledby="dropdown">
        <li><a role="button" (click)="downloadTemplate('equipment_import')">Télécharger le template</a></li>
      </ul>
    </div>

    <button *ngIf="!localDisabledButtons.exactSearch"
            class="btn btn-default"
            (click)="toggleExactSearch()">
      <i class="fa" [ngClass]="wcmTable.filters.exactSearch ? 'fa-toggle-on' : 'fa-toggle-off'"></i> Recherche exacte
    </button>

    <div class="btn-group" *ngIf="!localDisabledButtons.create && hasPermissions('Wira:EqpAdmin')">
      <ng-container *hasPermissions="'Antoine:ExternalEquipmentOrderCanDisplay'; else newLoganEquipmentButtonOnly">
        <button type="button"
                class="btn btn-default"
                (click)="create()">
          <i class="fas fa-plus"></i> Nouvel équipement
        </button>

        <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown">
          <span class="caret"></span>
        </button>
        <ul class="dropdown-menu" aria-labelledby="dropdown">
          <li><a role="button" (click)="create()">Nouvel équipement Logan</a></li>
          <li><a role="button" (click)="createFromExternalEquipmentOrder()">Nouvel équipement Cassiopée</a></li>
        </ul>
      </ng-container>

      <ng-template #newLoganEquipmentButtonOnly>
        <button class="btn btn-default" (click)="create()">
          <i class="fas fa-plus"></i> Nouvel équipement
        </button>
      </ng-template>
    </div>

    <button *ngIf="!localDisabledButtons.delete && hasPermissions('ShivaBackend:EqpCanDelete')"
            class="btn btn-danger"
            (click)="confirmBulkDelete(wcmTable.selectedPk)">
      <i class="fa fa-trash-alt"></i> Supprimer
    </button>
  </ng-template>

  <app-wcm-col *ngIf="!localDisabledColumns.selection"
               field="id"
               header="Sélection"
               class="text-center"
               type="checkbox"
               [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.code}}" link="/equipments/detail/{{item.code}}" [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="model__brand__name" header="Constructeur" class="text-center"></app-wcm-col>

  <app-wcm-col field="model__name"
               header="Modèle {{wcmTable.filters.exactSearch ? '(exact)' : ''}}"
               class="text-center">
    <ng-template appWcmColFilter let-dt="dt" let-col="col" let-filters="filters">
      <div *ngIf="wcmTable.filters.exactSearch; then exactSearchBlock else searchBlock"></div>

      <ng-template #exactSearchBlock>
        <input type="text"
               class="input-sm form-control"
               [(ngModel)]="filters['model__exact_name']"
               (ngModelChange)="dt.filter(filters['model__exact_name'], 'model__exact_name', null)">
      </ng-template>

      <ng-template #searchBlock>
        <input type="text"
               class="input-sm form-control"
               [(ngModel)]="filters['model__name']"
               (ngModelChange)="dt.filter(filters['model__name'], 'model__name', null)">
      </ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="model__branded_name" header="Réf. Constructeur" class="text-center"></app-wcm-col>

  <app-wcm-col field="serial_number" header="N° de série" class="text-center" [preventSort]="true" [focus]="true">
  </app-wcm-col>

  <app-wcm-col field="internal_serial_number" header="N° de série interne" class="text-center" [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col field="entity__parent__name" header="Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.name}}"
                link="/entities/detail/{{item.entity?.parent?.code}}"
                [disabled]="disableRouterLink">
      </app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__parent__code" header="Code Parent" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.parent?.code}}"
                link="/entities/detail/{{item.entity?.parent?.code}}"
                [disabled]="disableRouterLink">
      </app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__name" header="Client" class="text-center" type="nullableText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.name}}"
                link="/entities/detail/{{item.entity?.code}}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__code" header="Code client" class="text-center">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.entity?.code}}"
                link="/entities/detail/{{item.entity?.code}}"
                [disabled]="disableRouterLink"></app-link>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="entity__customer_ref" header="Réf. Client" class="text-center"></app-wcm-col>

  <app-wcm-col field="entity__location__city" header="Ville" class="text-center"></app-wcm-col>

  <app-wcm-col field="reserved_for__name" header="Réservé pour (Client)" class="text-center" type="nullableText">
  </app-wcm-col>

  <app-wcm-col field="reserved_for__code" header="Réservé pour (Code)" class="text-center" type="nullableText">
  </app-wcm-col>

  <app-wcm-col field="provider_order_number__or__provider_order__order_number"
               header="Commande fournisseur"
               class="text-center"
               type="nullableText"
               [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <div *ngIf="item.provider_order?.order_number; then linkBlock else textBlock"></div>

      <ng-template #linkBlock>
        <a routerLink="/provider-orders/detail/{{item.provider_order?.id}}">{{ item.provider_order?.order_number }}</a>
      </ng-template>

      <ng-template #textBlock>
        <span>{{ item.provider_order_number }}</span>
      </ng-template>

    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="created_at" header="Créé le" type="dateRangeWithTime" class="text-center" width="120">
    <ng-template appWcmColBody let-item="item">
      {{ item.created_at | amDateFormat:'L' }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="last_stock_activity"
               header="Dernier mouvement de stock"
               type="dateRangeWithTime"
               class="text-center"
               width="120">
  </app-wcm-col>

  <app-wcm-col field="mac_address" header="Adresse MAC" class="text-center" [preventSort]="true"></app-wcm-col>

  <app-wcm-col field="immobilization_number" header="N° immobilisation" class="text-center"></app-wcm-col>

  <!-- Equipment's site's owner-->
  <app-wcm-col field="owner" type="text" header="Propriétaire" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="location"
               header="Affectation"
               type="select"
               class="text-center"
               [additionalArgs]="{ options: EQP_LOCATIONS }">
  </app-wcm-col>

  <!--  Equipment' logistic site-->
  <app-wcm-col field="equipment_location__logistics_site__name"
               header="Site logistique"
               class="text-center"
               type="nullableText">
    <ng-template appWcmColBody let-item="item">
      <app-link name="{{item.equipment_location?.logistics_site?.name}}"
                link="/logistics-sites/detail/{{item.equipment_location?.logistics_site?.code}}"
                [disabled]="disableRouterLink">
      </app-link>
    </ng-template>
  </app-wcm-col>

  <!--  Equipment's site-->
  <app-wcm-col field="equipment_location__number" type="text" header="Emplacement" class="text-center">
  </app-wcm-col>

  <app-wcm-col field="model__type_label"
               header="Type"
               type="select"
               width="150"
               class="text-center"
               [preventSort]="true"
               [additionalArgs]="{options: typeOptions}">
  </app-wcm-col>

  <app-wcm-col field="has_accounting_equipment"
               type="yesNo"
               header="A un équipement comptable"
               class="text-center"
               [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <div *ngIf="item.has_accounting_equipment; else hasNotEquipment">
        <span class="label label-success">&nbsp;</span>
      </div>

      <ng-template #hasNotEquipment>
        <span class="label label-danger">&nbsp;</span>
      </ng-template>
    </ng-template>
  </app-wcm-col>

  <app-wcm-col
    *ngIf="hasPermissions('ShivaBackend:AccountingEquipmentAdmin', 'ShivaBackend:AccountingEquipmentLogistic')"
    field="price_untaxed"
    header="Prix d'achat unitaire (H.T.)"
    type="none"
    class="text-center">
    <ng-template appWcmColBody let-item="item">
      {{ item.price_untaxed | currency:'EUR':'symbol':'1.2-4' }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col
    *ngIf="hasPermissions('ShivaBackend:AccountingEquipmentAdmin', 'ShivaBackend:AccountingEquipmentLogistic')"
    field="license_price"
    header="Prix d'achat licence (H.T.)"
    type="none"
    class="text-center">
    <ng-template appWcmColBody let-item="item">
      {{ item.license_price | currency:'EUR':'symbol':'1.2-4' }}
    </ng-template>
  </app-wcm-col>

  <app-wcm-col field="tags" header="Tags" type="tag" class="text-center" [preventSort]="true" width="100">
  </app-wcm-col>
  <app-wcm-col field="model__weight"
               header="Poids (kg)"
               type="none"
               width="100"
               class="text-center"
               [preventSort]="true">
  </app-wcm-col>

  <app-wcm-col *ngIf="hasPermissions('Wira:EqpCanAssign', 'Wira:EqpAdmin')"
               id="action"
               label="Action"
               class="text-center"
               type="none"
               width="1"
               [preventSort]="true">
    <ng-template appWcmColBody let-item="item">
      <div class="btn-group table-dropdown" ngbDropdown container="body" placement="bottom-right">
        <button class="btn btn-default btn-xs dropdown-toggle btn-transparent" ngbDropdownToggle>
          <i class="fa fa-cog text-muted"></i>
        </button>

        <div ngbDropdownMenu>
          <button ngbDropdownItem (click)="assignToShop(item)" *ngIf="item.location !== EquipmentLocationEnum.Shop">
            Affecter à un magasin
          </button>

          <button ngbDropdownItem (click)="assignToStock(item)" *ngIf="item.location !== EquipmentLocationEnum.Stock">
            Renvoyer au stock
          </button>

          <button ngbDropdownItem (click)="assignToInvoicing(item)"
                  *ngIf="item.location !== EquipmentLocationEnum.Invoicing">Facturer l'équipement
          </button>
        </div>
      </div>
    </ng-template>
  </app-wcm-col>

</app-wcm-table>
<div class="wcm-selection-bar flex center" *ngIf="wcmTable.selectedCount > 0">
  <div class="bar-wrapper">
    <div class="flex center">
      <div class="flex-left flex-v-center selection-label">
        {{ wcmTable.selectedCount || 0 }} {{ wcmTable.selectedCount > 1 ? 'équipements sélectionnés' : 'équipement sélectionné' }}
      </div>
      <div class="flex-right flex-wrap action-btn-bar">
        <button class="btn btn-default" [disabled]="loadingAction" (click)="clearSelection()">
          Tout désélectionner
        </button>

        <div class="btn-group dropup" *hasPermissions="'Antoine:EquipmentCanBulkPatch'">
          <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" [disabled]="loadingAction">
            Plus d'actions <span class="caret"></span>
          </button>
          <ul class="dropdown-menu">
            <li>
              <a role="button" (click)="bulkUpdate(wcmTable.selectedItems, 'owner')">
                <i class="fa fa-user"></i> Modifier le propriétaire
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdate(wcmTable.selectedItems, 'equipmentModel')">
                <i class="fa fa-wrench"></i> Modifier le modèle d'équipement
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdateTags(wcmTable.selectedPk)">
                <i class="fa fa-tags"></i> Mise à jour des tags...
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdate(wcmTable.selectedItems, 'reservedFor')">
                <i class="fa fa-user-lock"></i> Modifier le réservé pour
              </a>
            </li>
            <li>
              <a role="button" (click)="bulkUpdate(wcmTable.selectedItems, 'equipmentLocation')">
                <i class="fa fa-map-marker"></i> Modifier l'emplacement
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
