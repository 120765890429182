import { Injectable } from '@angular/core';

import { ApiService } from './api.service';
import { AuthService } from '@core/services/auth.service';
import { ConfigService } from '@core/config/config.service';
import { IEquipment, IEquipmentSearchPayload, IWoogleSearchResponse } from '@core/interfaces';

@Injectable({
  providedIn: 'root'
})
export class ApiWoogleService {

  constructor(
    private api: ApiService,
    private authService: AuthService,
    private config: ConfigService
  ) { }

  private _call(method, path, data?, disableError?): Promise<any> {
    return this.api.call(method, this.config.apis.woogle + path, data, disableError);
  }


  public isUp() {
    return this._call('GET', '/es/is_up');
  }

  public get search() {
    return {
      all: (payload) => {
        return this._call('POST', '/es/search/', payload);
      }
    };
  }

  public searchEquipment(payload: IEquipmentSearchPayload): Promise<IWoogleSearchResponse<IEquipment>> {
    return this._call('POST', '/es/search-equipment/', payload);
  }

  public searchProductConf(payload) {
    return this._call('POST', '/es/search-product-configuration/', payload);
  }

  public suggest(payload) {
    return this._call('POST', '/es/suggest/', payload);
  }

  public suggestContact(payload) {
    return this._call('POST', '/es/suggest-contacts/', payload);
  }
}
