import { inject, Injectable } from '@angular/core';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import {
  IEquipment,
  IEquipmentModel,
  IEquipmentSlotData,
  IListResult,
  IMetadataScannedEquipment,
  IRequiredEquipment,
  IScannedMerakNetworkDevice,
  ITinyEquipment,
  ITinyEquipmentModel,
  IWorkOrderItems,
  ScanStatusEnum,
} from '@core/interfaces';
import { EquipmentLocationEnum } from '@core/constants';
import { deepCopy } from '@core/helpers';

@Injectable({
  providedIn: 'root',
})
export class ScannedEquipmentManager {

  private readonly apiShivaService = inject(ApiShivaService);

  public formatNetworkDeviceForMetadata(networkDevice: { id: string, code: string, name: string, spare_name?: string } | undefined): IScannedMerakNetworkDevice {
    if (!networkDevice) {
      return undefined;
    }
    return {
      id: networkDevice.id,
      code: networkDevice.code,
      name: networkDevice.name,
      spare_name: networkDevice.spare_name,
    };
  }

  /**
   * Formats the scanned equipment in the component to the values that can be saved to the work order item metadata
   * @param scannedEquipment The list of scanned equipment metadata to convert
   * @return The array of equipment metadata entries
   */
  public formatSlotsForMetadata(scannedEquipment: IMetadataScannedEquipment[]): IMetadataScannedEquipment[] {
    return scannedEquipment
      // Only convert the slots that have scanned equipment
      .filter((slot: IMetadataScannedEquipment) => slot.code)
      .map((slot: IMetadataScannedEquipment): IMetadataScannedEquipment => {
        return {
          id: slot.id,
          is_scanned: slot.is_scanned,
          scan_status: slot.scan_status,
          code: slot.code,
          serial_number: slot.serial_number,
          internal_serial_number: slot.internal_serial_number,
          mac_address: slot.mac_address,
          network_device: this.formatNetworkDeviceForMetadata(slot.network_device),
          is_secondary_eqp: slot.is_secondary_eqp || false,
        };
      });
  }

  /**
   * Adds scanned equipment to the work order item scanned equipment metadata.
   * If the scanned equipment code already has an entry, that entry will be updated, otherwise a new entry will be created
   *
   * @param equipment The equipment to add to the metadata
   * @param scannedEquipment The scanned equipment metadata
   * @param requestedEquipment The requested equipment data
   * @param workOrderItem The work order item the scanned equipment is for
   * @param equipmentModel The expected equipment model for the scanned equipment
   * @param networkDevice The network device linked to the equipment for P-MERAK WOIs
   * @return The updated metadata scanned equipment list
   */
  public addScannedEquipment(
    equipment: ITinyEquipment,
    scannedEquipment: IMetadataScannedEquipment[],
    requestedEquipment: IRequiredEquipment[],
    workOrderItem: IWorkOrderItems,
    equipmentModel: IEquipmentModel,
    networkDevice?: IScannedMerakNetworkDevice | undefined,
  ): IMetadataScannedEquipment[] {
    // Convert the scanned equipment to the metadata object
    const newScanned: IMetadataScannedEquipment = this._convertEquipmentToMetadata(
      equipment,
      requestedEquipment || [],
      workOrderItem,
      equipmentModel,
      networkDevice,
    );

    let existingIndex: number;
    if (!scannedEquipment) {
      scannedEquipment = [];
      existingIndex = -1;
    } else {
      existingIndex = scannedEquipment.findIndex((scanned: IMetadataScannedEquipment) => scanned.code === newScanned.code);
    }

    // if it's our second given eqp on a MX (firewall with cluster) we agree it's a secondary eqp
    if (equipment.model?.name?.startsWith('MX') && scannedEquipment.length >= 1) {
      newScanned.is_secondary_eqp = true;
    }
    if (existingIndex >= 0) {
      scannedEquipment[existingIndex] = newScanned;
    } else {
      scannedEquipment.push(newScanned);
    }

    return scannedEquipment;
  }

  /**
   * Checks the scan status of the equipments vs the requested quantity
   * @param requestedQuantity The requested quantity
   * @param scannedEquipments The current state of the scanned equipments
   * @returns An object containing properties stating if everything was scanned and if there are errors
   */
  public checkScanStatus(requestedQuantity: number, scannedEquipments: IMetadataScannedEquipment[]): { allScanned: boolean, hasErrors: boolean } {
    const scannedEquipment: IMetadataScannedEquipment[] = scannedEquipments || [];

    const allScanned: boolean = scannedEquipment
      .filter((scanned: IMetadataScannedEquipment) => [ScanStatusEnum.Success, ScanStatusEnum.Warning].includes(scanned.scan_status))
      .length >= requestedQuantity;
    const hasErrors: boolean = scannedEquipment
      .some((scanned: IMetadataScannedEquipment) => scanned.scan_status === ScanStatusEnum.Error);

    return {
      allScanned,
      hasErrors,
    };
  }

  /**
   * Check if a provided equipment is valid
   * The equipment is considered unavailable if
   * - it is reserved for another entity or group
   * - it is not in the stock
   * - the equipment model is different to the expected model
   * @param equipment The equipment to check
   * @param workOrderItem The work order item the equipment is for
   * @param equipmentModel The expected equipment model
   * @return The list of errors if any, or an empty array if the equipment is valid
   */
  public checkForErrors(
    equipment: ITinyEquipment | undefined,
    workOrderItem: IWorkOrderItems | undefined,
    equipmentModel: IEquipmentModel | undefined
  ): string[] {
    const errors: string[] = [];
    if (this._isInvalidEntity(equipment, workOrderItem)) {
      errors.push(`Cet équipement est réservé pour une autre entité.`);
    }
    // Un eqp est scanné mais déjà affecté à un magasin différent du site de la tâche
    if (this._isInvalidLocation(equipment)) {
      errors.push(`L'équipement concerné est déjà affecté en magasin sur une autre entité.`);
    }
    if (this._isInvalidModel(equipment, equipmentModel)) {
      errors.push(`Le modèle d’équipement est différent de celui attendu.`);
    }
    return errors;
  }

  /**
   * Builds the list of scanned equipment metadata slots from the requirements and the already scanned equipment
   * @param quantity The required quantity
   * @param requirements The list of required equipment
   * @param scannedEquipment The list of scanned equipment already saved to the metadata
   * @param workOrderItem The work order item the scanned equipment are for
   * @param equipmentModel The expected equipment model
   * @param disabled Whether the component is disabled or not, if yes we don't recalculate the statuses
   * @return The list of scanned equipment slots
   */
  public async constructEquipmentSlots(
    quantity: number,
    requirements: IRequiredEquipment[],
    scannedEquipment: IMetadataScannedEquipment[],
    workOrderItem?: IWorkOrderItems,
    equipmentModel?: IEquipmentModel,
    disabled?: boolean,
  ): Promise<IMetadataScannedEquipment[]> {

    // Create equipment slots that will contain the scanned equipment, we prefill the slots with the equipment information
    let equipmentSlots: IMetadataScannedEquipment[];
    if (requirements.length > 0) {
      // Fill from required equipment internal serial numbers
      const equipments: IEquipment[] = await this._getEquipmentFromRequirements(requirements);
      equipmentSlots = this._prepareSlotsFromRequirements(requirements, equipments, equipmentModel);

    } else {
      // Create a list with minimal slots for the scanned equipment
      equipmentSlots = this._prepareSlotsFromQuantity(quantity, equipmentModel);
    }

    await this._updateSlotsWithScannedEquipment(equipmentSlots, requirements.length > 0, scannedEquipment);

    if (!disabled) {
      equipmentSlots = this._checkAllSlotsValidity(equipmentSlots, requirements, workOrderItem, equipmentModel);
    }
    equipmentSlots = this._sortAccordingToScanned(equipmentSlots, scannedEquipment);

    return equipmentSlots;
  }

  /**
   * Checks if the slots are valid, returning whether an update was made to the slots statuses
   * @param equipmentSlots The list of equipment slots
   * @param requirements The list of required equipment
   * @param workOrderItem The work order item the scanned equipment are for
   * @param equipmentModel The expected equipment model for the scanned equipment
   * @private
   * @return The equipment slots with changes if any were made
   */
  private _checkAllSlotsValidity(
    equipmentSlots: IMetadataScannedEquipment[],
    requirements: IRequiredEquipment[],
    workOrderItem: IWorkOrderItems | undefined,
    equipmentModel: IEquipmentModel | undefined
  ): IMetadataScannedEquipment[] {
    return equipmentSlots.map((slot: IMetadataScannedEquipment) => this._updateSlotValidity(
      slot,
      requirements,
      workOrderItem,
      equipmentModel,
    ));
  }

  /**
   * Updates the scanned equipment status and errors depending on the validity
   * @param slot The scanned equipment
   * @param requirements The list of required equipment
   * @param workOrderItem The work order item the scanned equipment is for
   * @param equipmentModel The expected equipment model
   * @returns The updated slot
   */
  private _updateSlotValidity(
    slot: IMetadataScannedEquipment,
    requirements: IRequiredEquipment[],
    workOrderItem: IWorkOrderItems | undefined,
    equipmentModel: IEquipmentModel | undefined,
  ): IMetadataScannedEquipment {
    // If not scanned then do nothing, there's no validation to be done
    if (!slot.is_scanned) {
      return slot;
    }

    // If scanned then check if there are any warnings or errors
    let newStatus: ScanStatusEnum | undefined;
    let errorMessages: string[] = [];

    // If there is no status, start off by considering it is valid
    if (!slot.scan_status) {
      newStatus = ScanStatusEnum.Success;
      errorMessages = [];
    }

    // Then, check if the equipment was part of the required list
    const equipmentInRequirements = requirements
      .some((requirement: IRequiredEquipment) => this._checkEquipmentMatchesRequirement(slot.equipment, requirement));

    if (requirements.length && !equipmentInRequirements) {
      newStatus = ScanStatusEnum.Warning;
      errorMessages = [`L'équipement n'est pas dans les équipements demandés par le FIFO.`];
    }

    // And finally, check if there are any blocking errors
    const errors: string[] = this.checkForErrors(slot.equipment, workOrderItem, equipmentModel);
    if (errors.length) {
      newStatus = ScanStatusEnum.Error;
      errorMessages = [...errorMessages, ...errors];
    }

    if (newStatus) {
      return {
        ...slot,
        scan_status: newStatus,
        error_messages: errorMessages,
      };
    }
    return slot;
  }

  /**
   * Pre-fill the scanned equipment metadata with the scanned equipment data
   * @param equipmentSlots The equipment slots to pre-fill
   * @param requiresSpecificEquipment Whether we want specific equipment or if any matching equipment will do
   * @param scannedEquipment The scanned equipment metadata
   * @private
   */
  private async _updateSlotsWithScannedEquipment(
    equipmentSlots: IMetadataScannedEquipment[],
    requiresSpecificEquipment: boolean,
    scannedEquipment: IMetadataScannedEquipment[]
  ): Promise<void> {

    // Get the list of equipments matching the metadata scanned codes
    const equipmentDataToPlace: IEquipmentSlotData[] = await this._getEquipmentDataForMetadataEquipment(scannedEquipment);

    equipmentSlots.forEach((equipmentSlot: IMetadataScannedEquipment) => {
      let equipmentDataIndex: number = -1;

      if (requiresSpecificEquipment) {
        // We want specific equipment in this slot, find one from the equipmentsToPlace
        equipmentDataIndex = equipmentDataToPlace.findIndex((data: IEquipmentSlotData) => {
          return this._checkEquipmentMatchesRequirement(data.equipment, equipmentSlot);
        });

      } else if (equipmentDataToPlace.length > 0) {
        // We don't know what equipment we want in this slot, get the first available one from the equipmentsToPlace
        equipmentDataIndex = 0;
      }

      if (equipmentDataIndex >= 0) {
        // Found a match, update the equipment slot with the metadata information
        const equipmentData: IEquipmentSlotData = equipmentDataToPlace[equipmentDataIndex];

        // Save the equipment object to the slot so that we can use it later to check if the slot is valid
        equipmentSlot.equipment = equipmentData.equipment;

        // Update the slot information
        equipmentSlot.is_scanned = equipmentData.scannedEquipment.is_scanned;
        equipmentSlot.scan_status = equipmentData.scannedEquipment.scan_status;
        equipmentSlot.code = equipmentData.scannedEquipment.code;
        equipmentSlot.network_device = equipmentData.scannedEquipment?.network_device;
        equipmentSlot.internal_serial_number = equipmentData.equipment?.internal_serial_number;

        // Update the slot equipment details
        if (!equipmentSlot.serial_number) {
          equipmentSlot.serial_number = equipmentData.equipment?.serial_number;
        }
        if (!equipmentSlot.reserved_for) {
          equipmentSlot.reserved_for = equipmentData.equipment?.reserved_for;
        }
        if (!equipmentSlot.mac_address) {
          equipmentSlot.mac_address = equipmentData.equipment?.mac_address || equipmentData?.scannedEquipment?.mac_address;
        }
        if (!equipmentSlot.location) {
          equipmentSlot.location = equipmentData.equipment?.location;
        }

        // Remove the match from the equipment to place
        equipmentDataToPlace.splice(equipmentDataIndex, 1);
      }
    });

    // Add the remaining equipment to the list as new slots, this is equipment that was not expected but was scanned
    equipmentDataToPlace.forEach((equipmentData: IEquipmentSlotData) => {
      const additionalSlot: IMetadataScannedEquipment = {
        equipment: equipmentData.equipment,
        is_scanned: true,
        scan_status: equipmentData.scannedEquipment.scan_status,
        code: equipmentData.scannedEquipment.code,
        model: equipmentData.equipment?.model,
        location: equipmentData.equipment?.location,
        serial_number: equipmentData.equipment?.serial_number,
        internal_serial_number: equipmentData.equipment?.internal_serial_number,
        reserved_for: equipmentData.equipment?.reserved_for,
        mac_address: equipmentData.equipment?.mac_address || equipmentData?.scannedEquipment?.mac_address,
        network_device: undefined,
      };
      equipmentSlots.push(additionalSlot);
    });
  }

  /**
   * Find the equipments matching the scanned equipment metadata
   * @param scannedEquipmentList The scanned equipment metadata
   * @private
   * @return A list of tuples containing the metadata and the matching equipment
   */
  private async _getEquipmentDataForMetadataEquipment(scannedEquipmentList: IMetadataScannedEquipment[]): Promise<IEquipmentSlotData[]> {
    const scannedCodes: string[] = scannedEquipmentList.map((equipment: IMetadataScannedEquipment) => equipment.code);
    let equipmentList: IEquipment[] = [];
    // Only actually try to call the API if there's something to get
    if (scannedCodes.length > 0) {
      const response: IListResult<IEquipment> = await this.apiShivaService.equipments.list({
        code__in: scannedCodes,
      });
      equipmentList = response.results;
    }

    // Join each metadata scanned code with the matching equipment object
    return scannedEquipmentList.map((scannedEquipment: IMetadataScannedEquipment) => {
      return {
        scannedEquipment: scannedEquipment,
        equipment: equipmentList.find((equipment: IEquipment) => equipment.code === scannedEquipment.code),
      };
    });
  }

  /**
   * Checks if a provided equipment matches a specific requirement
   * @param equipment The equipment to check
   * @param requirement The requirement containing a code, serial number, or internal serial number
   * @private
   * @return True if one of the properties is a match, false otherwise
   */
  private _checkEquipmentMatchesRequirement(equipment: IEquipment | undefined, requirement: IRequiredEquipment): boolean {
    return (
      (requirement.code && requirement.code === equipment?.code)
      || (requirement.serial_number && requirement.serial_number === equipment?.serial_number)
      || (requirement.internal_serial_number && requirement.internal_serial_number === equipment?.internal_serial_number)
    );
  }

  /**
   * Creates the metadata slots from the provided required serial numbers
   * @param requirements The list of required equipment
   * @param foundEquipment The equipment matching the search for the serial numbers
   * @param equipmentModel The requested model, used if the requested equipment was not found
   * @private
   * @return The list of metadata entries pre-filled with the equipment values
   */
  private _prepareSlotsFromRequirements(
    requirements: IRequiredEquipment[],
    foundEquipment: IEquipment[],
    equipmentModel: IEquipmentModel | undefined
  ): IMetadataScannedEquipment[] {
    return requirements.map((requirement: IRequiredEquipment): IMetadataScannedEquipment => {
      const equipment: IEquipment = foundEquipment.find((eq: IEquipment) => this._checkEquipmentMatchesRequirement(eq, requirement));

      return {
        is_scanned: false,
        scan_status: undefined,
        error_messages: [],
        code: equipment?.code || requirement.code,
        serial_number: equipment?.serial_number || requirement.serial_number,
        internal_serial_number: equipment?.internal_serial_number || requirement.internal_serial_number,
        model: equipment?.model || equipmentModel,
        reserved_for: equipment?.reserved_for,
        location: equipment?.location,
        mac_address: equipment?.mac_address,
        network_device: undefined,
        equipment: equipment,
      };
    });
  }

  /**
   * Creates the metadata slots from the provided quantity
   * @param quantity The required quantity
   * @param equipmentModel The requested model
   * @private
   * @return The list of metadata entries pre-filled with the model name
   */
  private _prepareSlotsFromQuantity(quantity: number, equipmentModel: IEquipmentModel | undefined): IMetadataScannedEquipment[] {
    return Array.from(Array(quantity)).map(() => {
      return {
        is_scanned: false,
        scan_status: undefined,
        error_messages: [],
        code: undefined,
        model: equipmentModel,
        serial_number: undefined,
        internal_serial_number: undefined,
        reserved_for: undefined,
        location: undefined,
        mac_address: undefined,
        network_device: undefined,
      };
    });
  }

  /**
   * Convert a tiny equipment to object that will be saved in the metadata
   * @param equipment The equipment to convert
   * @param requirements The list of required equipment
   * @param workOrderItem The work order item the scanned equipment is for
   * @param equipmentModel The expected equipment model for the scanned equipment
   * @param networkDevice The network device linked to the equipment for P-MERAK WOIs
   * @return The scanned equipment object that can be saved to the metadata
   */
  private _convertEquipmentToMetadata(
    equipment: ITinyEquipment,
    requirements: IRequiredEquipment[],
    workOrderItem: IWorkOrderItems | undefined,
    equipmentModel: IEquipmentModel | undefined,
    networkDevice: IScannedMerakNetworkDevice | undefined,
  ): IMetadataScannedEquipment {
    const metadataEntry: IMetadataScannedEquipment = {
      code: equipment.code,
      id: equipment.id,
      mac_address: equipment.mac_address,
      serial_number: equipment.serial_number,
      model_need_mac_address: equipment.model.need_mac_address,
      // Mark this entry in the metadata as having been scanned so that the component will recalculate the status
      is_scanned: true,
      // Include the equipment details to check if it is valid
      equipment: equipment,
      // Include the network device, used for P-MERAK WOIs
      network_device: networkDevice,
    };

    const metadataEntryWithStatus = this._updateSlotValidity(
      metadataEntry,
      requirements,
      workOrderItem,
      equipmentModel
    );

    // Remove the equipment data from the metadata results, so we don't save it
    delete metadataEntryWithStatus.equipment;

    return metadataEntryWithStatus;
  }

  /**
   * Checks if the entity is invalid for the provided equipment
   * @param equipment The equipment to check
   * @param workOrderItem The work order item for which the equipment is scanned
   * @private
   * @return False if the equipment is valid, else true
   */
  private _isInvalidEntity(equipment: ITinyEquipment | undefined, workOrderItem: IWorkOrderItems | undefined): boolean {
    const eqpReservedForEntityCode = equipment?.reserved_for?.code;
    const eqpReservedForParentEntityCode = equipment?.reserved_for?.parent?.code;
    const entityCode = workOrderItem?.work_order?.entity?.code;
    const woiEntityParentCode = workOrderItem?.work_order?.entity?.parent?.code;

    let eqpIsUnavailable: boolean;
    if (!eqpReservedForEntityCode) {
      eqpIsUnavailable = false;
    } else {
      eqpIsUnavailable = !(
        eqpReservedForEntityCode === entityCode ||
        (eqpReservedForEntityCode === woiEntityParentCode && woiEntityParentCode) ||
        (eqpReservedForParentEntityCode === entityCode && eqpReservedForParentEntityCode) ||
        (eqpReservedForParentEntityCode === woiEntityParentCode && eqpReservedForParentEntityCode && woiEntityParentCode)
      );
    }
    return eqpIsUnavailable;
  }

  /**
   * Checks if the equipment location is valid
   * @param equipment The equipment to check
   * @private
   * @return False if the equipment is valid, else true
   */
  private _isInvalidLocation(equipment: ITinyEquipment): boolean {
    if (!equipment) { return false; }

    const allowedLocations: EquipmentLocationEnum[] = [EquipmentLocationEnum.Collect, EquipmentLocationEnum.Stock];
    return !allowedLocations.includes(equipment.location);
  }

  /**
   * Checks if the equipment model is valid
   * @param equipment The equipment to check
   * @param equipmentModel The expected equipment model
   * @private
   * @return False if the equipment is valid, else true
   */
  private _isInvalidModel(equipment: ITinyEquipment | undefined, equipmentModel: ITinyEquipmentModel | undefined): boolean {
    if (!equipmentModel || !equipment) {
      return false;
    }
    return equipment.model.name !== equipmentModel.name;
  }

  /**
   * Fetches the equipment matching the requirements
   * @param requirements The list of required equipment
   * @private
   * @return The equipment matching the requirements
   */
  private async _getEquipmentFromRequirements(requirements: IRequiredEquipment[]): Promise<IEquipment[]> {
    const byInternalSerialNumbers: IEquipment[] = await this._getEquipmentsMatching('internal_serial_number', requirements);
    const bySerialNumbers: IEquipment[] = await this._getEquipmentsMatching('serial_number', requirements);
    const byCodes: IEquipment[] = await this._getEquipmentsMatching('code', requirements);

    return [
      ...byInternalSerialNumbers,
      ...bySerialNumbers,
      ...byCodes,
    ];
  }

  private async _getEquipmentsMatching(property: keyof IRequiredEquipment, requirements: IRequiredEquipment[]): Promise<IEquipment[]> {
    const values: string[] = requirements
      .map((requirement: IRequiredEquipment) => requirement[property])
      .filter(Boolean);

    const response = await this.apiShivaService.equipments.list({
      [`${property}__in`]: values,
    });
    return response.results || [];
  }

  private _sortAccordingToScanned(equipmentSlots: IMetadataScannedEquipment[], scannedEquipments: IMetadataScannedEquipment[]): IMetadataScannedEquipment[] {
    return equipmentSlots.sort((a: IMetadataScannedEquipment, b: IMetadataScannedEquipment): number => {
      const indexA = scannedEquipments.findIndex((scanned: IMetadataScannedEquipment) => (scanned.code && scanned.code === a.code)
                     || (scanned.serial_number && scanned.serial_number === a.serial_number)
                     || (scanned.internal_serial_number && scanned.internal_serial_number === a.internal_serial_number));
      const indexB = scannedEquipments.findIndex((scanned: IMetadataScannedEquipment) => (scanned.code && scanned.code === b.code)
                     || (scanned.serial_number && scanned.serial_number === b.serial_number)
                     || (scanned.internal_serial_number && scanned.internal_serial_number === b.internal_serial_number));
      return indexA - indexB;
    });
  }
}
