import { Component, Input } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { ChangeCallback } from '@core/components/abstract-field.component';

import { EquipmentModelsModalComponent } from './equipment-models-modal.component';


@Component({
  selector: 'app-equipment-model-field',
  templateUrl: './equipment-model-field.component.html',
  styles: [],

  // This part is reponsible for the integration of the input inside the angular forms
  // It allows angular formControl element to communicate with our custom input
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: EquipmentModelFieldComponent,
    multi: true
  }]
})
export class EquipmentModelFieldComponent implements ControlValueAccessor {
  @Input() public disabled: boolean;
  @Input() public filters: any;
  @Input() public disabledColumns: any;
  @Input() public disabledButtons: any;

  public detail: any;
  public onChangeCb: ChangeCallback;

  constructor(private modalService: NgbModal) { }

  public choose(): void {
    if (this.disabled) {
      return;
    }

    const localDisabledColumns = {...this.disabledColumns};
    const localDisabledButtons = {create: true, ...this.disabledButtons};
    const localFilters = {limit: 10, ...this.filters};

    const modal = this.modalService.open(EquipmentModelsModalComponent, {size: 'lg'});
    modal.componentInstance.disabledColumns = localDisabledColumns;
    modal.componentInstance.disabledButtons = localDisabledButtons;
    modal.componentInstance.filters = localFilters;

    modal.result.then((res) => {
      this.detail = res;
      this.onChangeCb(this.detail);
    }, () => {});
  }

  public reset(): void {
    this.detail = null;
    this.onChangeCb(this.detail);
  }


  // These 3 functions are part of the NG_VALUE_ACCESSOR
  // they must be implemented for Angular to access our input
  public writeValue(value): void {
    // This function is called by Angular when the formControl element has its value updated
    this.detail = value;
  }

  public registerOnChange(fn: ChangeCallback): void {
    // This function is used by Angular to listen to the update of our custom control
    this.onChangeCb = fn;
  }

  public registerOnTouched(): void {
    // This function is used by Angular to know if our element has been touched by the user
  }
  /**
   * Function that is called by the forms API when the control status changes to or from 'DISABLED'.
   * Depending on the status, it enables or disables the appropriate DOM element.
   * @see https://angular.dev/api/forms/ControlValueAccessor
   * @param disabled The disabled status to set on the element
   */
  public setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }
}
