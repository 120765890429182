import { Injectable } from '@angular/core';

import { ApiSiAuthService } from '@core/apis/api-si-auth.service';
import { PromisesService } from './promises.service';

@Injectable({
  providedIn: 'root'
})
export class WcmUsersService {
  public users = {};

  constructor(
    private promisesService: PromisesService,
    private apiSiAuth: ApiSiAuthService
  ) { }


  // This function loads the wcm users into the app
  public load(): Promise<any> {
    const deferred = this.promisesService.defer();

    this.apiSiAuth.listUsers().then(res => {
      const result = {};
      res.results.forEach(item => {
        result[item.username] = item;
      });

      this.users = result;
      deferred.resolve();
    }).catch((err) => {
      deferred.reject(err);
    });
    return deferred.promise;
  }

  public get(username: string) {
    return this.users[username];
  }

}
