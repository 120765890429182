import { IEntity } from '@core/interfaces/entity-items';
import { IEquipmentLocation, ILogisticsSite } from '@core/interfaces/logistics-sites';
import { IEquipmentModel } from '@core/interfaces/equipment-models-items';
import { ITags } from '@core/interfaces/tags-items';
import {IMetadataScannedEquipment, IRequiredEquipment} from "@core/interfaces/equipments-items";

export interface IExternalEquipmentOrder {
  id: number;
  log_id: string;
  provider_order_reference: string;
  owner: string;
  provider: string;
  buyer: string;
  date_order: string;
  order_project: string;
  reserved_for: IEntity;
  equipment_processed: boolean;
  logistics_site: ILogisticsSite;
  tags?: ITags[];
  external_equipment_order_items_id_list?: number[];
  order_items?: IExternalEquipmentOrderItem[];
  events?: IExternalEquipmentOrderTransmissionEvent[];
  latest_event?: IExternalEquipmentOrderTransmissionEvent;
}

interface IExternalEquipmentOrderItemMetadata {
  requested_equipment_quantity: number;
  remaining_quantity: number;
  scanned_quantity: number;
  requested_equipments: IRequiredEquipment[];
  equipments: IMetadataScannedEquipment[];
}

export interface IExternalEquipmentOrderItem {
  id: number;
  external_equipment_order: IExternalEquipmentOrder;
  equipment_model: IEquipmentModel;
  equipment_location: IEquipmentLocation;
  metadata: IExternalEquipmentOrderItemMetadata;
  cassiopee_ref: string;
}

export interface IRandomSlipNumberResponse {
  random_slip_number: string;
}

export interface IReceiveEquipmentResponse {
  codes: string[];
}

export enum ExternalEquipmentOrderTransmissionEventStatusEnum {
  Running = 'running',
  Success = 'success',
  Error = 'error',
}

export const ExternalEquipmentOrderTransmissionEventStatusLabel = {
  [ExternalEquipmentOrderTransmissionEventStatusEnum.Running]: 'En cours',
  [ExternalEquipmentOrderTransmissionEventStatusEnum.Success]: 'Validé',
  [ExternalEquipmentOrderTransmissionEventStatusEnum.Error]: 'Erreur',
};

export interface IExternalEquipmentOrderTransmissionEvent {
  id: number;
  created_by: string;
  start_date: string;
  end_date?: string;
  status: ExternalEquipmentOrderTransmissionEventStatusEnum;
  message: string;
}
