<div class="modal-header">
  <h4 class="modal-title">Mise à jour des tags</h4>
</div>
<div class="modal-body" id="modal-body">
  Vous êtes sur le point de modifier les tags de {{ modelCountLabel }}.<br>
  Veuillez indiquer ceux à ajouter et ceux à retirer.

  <div class="form-horizontal" style="margin-top: 10px;">

    <div class="form-group">
      <label class="col-md-3 control-label">Tags à ajouter :</label>
      <div class="col-md-9">
        <app-tags-field [(ngModel)]="addedTags" name="addedTags" ngDefaultControl></app-tags-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-md-3 control-label">Tags à retirer :</label>
      <div class="col-md-9">
        <app-tags-field [(ngModel)]="removedTags" name="removedTags" ngDefaultControl></app-tags-field>
      </div>
    </div>

  </div>
</div> <!-- /modal-body -->
<div class="modal-footer">
  <button class="btn btn-primary" (click)="close()" [disabled]="addedTags.length === 0 && removedTags.length === 0">
    <span *ngIf="selectedCount > 1">
      Modifier les tags de {{ selectedCount }} {{ labelPlural }}
    </span>
    <span *ngIf="selectedCount === 1">
      Modifier les tags de 1 {{ labelSingular }}
    </span>
  </button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div> <!-- /modal-footer -->
