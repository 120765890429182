<div class="wcm-erp modal-view">
  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'" >
        <button *ngIf="hasPermissions('ShivaBackend:LogisticsRequestCanEdit') && !afterPackingState"
                type="button" class="btn btn-default"
                [disabled]="entityIsBlocked"
                (click)="edit()">
          Modifier
        </button>

        <app-notification-dropdown *ngIf="detail.id" [hidden]="mode !== 'normal'"
          model="LogisticsRequest" [pk]="detail.id">
        </app-notification-dropdown>
      </div>

      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading || entityIsBlocked" *ngIf="!detail.code">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading || entityIsBlocked || invalidWeight" *ngIf="detail.code">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code || inModal">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="alert alert-danger" *ngIf="hasActiveLogisticsRequests">
      Une demande logistique est actuellement en cours sur ce site.
    </div>

    <app-entity-alerts-alert [entityAlerts]="entityAlerts"> </app-entity-alerts-alert>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Titre <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <input class="form-control wcm-highlight-req"
               name="name" [required]="true"
               [readonly]="mode === 'normal' || !inState('new')"
               [(ngModel)]="detail.title" />
      </div>

      <label class="col-sm-2 control-label" *ngIf="detail.code">
        Statut
      </label>
      <div class="col-sm-4" *ngIf="detail.code">
        <app-state-field model="logistics-request"
                         [pk]="detail.id"
                         [state]="detail.state"
                         [disabled]="mode !== 'normal' || entityIsBlocked"
                         [confirmFuncMap]="stateConfirmFunctMap"
                         [errorFuncMap]="stateErrorFuncMap"
                         (transitionPlayed)="onStateUpdate()">
        </app-state-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Site <i class="required">*</i>
      </label>
      <div class="col-sm-4">
          <app-entity-field name="site"
                            class="wcm-highlight-req"
                            [(ngModel)]="detail.site"
                            [disabled]="mode === 'normal' || !inState('new')"
                            [required]="true"
                            (ngModelChange)="onEntityUpdate($event)">
          </app-entity-field>
      </div>
      <label class="col-sm-2 control-label">
        Site logistique <i *ngIf="!inState('new')" class="required">*</i>
      </label>
      <div class="col-sm-4">
          <app-logistics-site-field name="logistics_site"
                                    class="wcm-highlight-req"
                                    [(ngModel)]="detail.logistics_site"
                                    [disabled]="mode === 'normal' || !inState('new', 'pending_dispatch', 'provisioning')"
                                    [required]="!inState('new')">
          </app-logistics-site-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Référence site
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.site?.customer_ref}}
        </p>
      </div>
      <label class="col-sm-2 control-label">
        Projet
      </label>
      <div class="col-sm-4">
        <app-project-field name="project"
                           [(ngModel)]="detail.project"
                           [disabled]="mode === 'normal' || !inState('new')">
        </app-project-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Tags
      </label>
      <div class="col-sm-4">
        <app-tags-field name="tags"
                        [disabled]="mode === 'normal'"
                        [(ngModel)]="detail.tags" ngDefaultControl>
        </app-tags-field>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Créée par
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          <app-wcm-user [username]="detail.created_by"></app-wcm-user>
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Date de création
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.created_at | amDateFormat:'L'}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Date souhaitée
      </label>
      <div class="col-sm-4">
        <app-date-field name="due_date"
                        serializationFormat="YYYY-MM-DDTHH:mm:ssZ"
                        [(ngModel)]="detail.due_date"
                        [mediumSize]="true"
                        [minDate]="today"
                        [disabled]="mode === 'normal' || !prePackingState">
        </app-date-field>
      </div>

      <label class="col-sm-2 control-label">
        Date de fin
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.done_date | amDateFormat:'L'}}
        </p>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <div class="col-sm-6">
        <label class="col-sm-4 control-label">
          Expédition
         </label>
         <div class="col-sm-8">
           <div class="form-group">
             <div class="wcm-p-radio-wrapper">
               <p-radioButton name="shipping_type"
                              id="standardShipping"
                              inputId="standardShipping"
                              value="standard"
                              [(ngModel)]="detail.shipping"
                              [disabled]="mode === 'normal' || !prePackingState"></p-radioButton>
               <label for="standardShipping">Expédition standard</label>
             </div>
             <div class="wcm-p-radio-wrapper">
               <p-radioButton name="shipping_type"
                              id="urgentShipping"
                              inputId="urgentShipping"
                              value="urgent"
                              [(ngModel)]="detail.shipping"
                              [disabled]="mode === 'normal' || !prePackingState"></p-radioButton>
               <label for="urgentShipping">Expédition urgente</label>
             </div>
             <div class="wcm-p-radio-wrapper">
               <p-radioButton name="shipping_type"
                              id="withoutShipping"
                              inputId="withoutShipping"
                              value="without"
                              [(ngModel)]="detail.shipping"
                              [disabled]="mode === 'normal' || !prePackingState"></p-radioButton>
               <label for="withoutShipping">Sans expédition</label>
             </div>
           </div>
         </div>
      </div>

      <div class="col-sm-6" style="padding: 0;">
        <div style="margin-bottom: 15px; height:34px;">
          <label class="col-sm-2 col-md-4 control-label">
            Contact
          </label>
          <div class="col-sm-10 col-md-8">
            <app-contact-field name="contact"
                               [disabled]="mode === 'normal' || afterPackingState"
                               [disabledButtons]="{export: true}"
                               [(ngModel)]="detail.contact"
                               [entity]="detail.site">
            </app-contact-field>
          </div>
        </div>
        <div style="margin-bottom: 15px; height:34px;">
          <label class="col-sm-2 col-md-4 control-label">
            Adresse d'expédition
          </label>
          <div class="col-sm-10 col-md-8">
            <app-location-field name="shipping_location"
                                [disabled]="mode === 'normal' || afterPackingState"
                                [filters]="{type: 'shipping', limit: 10}"
                                [(ngModel)]="detail.shipping_location"
                                [disabledColumns]="{type: true}"
                                [hideFooter]="false"
                                [defaults]="{type: 'shipping'}"
                                showLocationNotFoundBtn=true>
            </app-location-field>
          </div>
        </div>

        <div style="height:34px">
          <label class="col-sm-2 col-md-4 control-label">
            Libellé Adresse
          </label>
          <div class="col-sm-10 col-md-8">
            <p class="form-control-static">
              {{detail.shipping_location?.address}}
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-xs-12 col-sm-offset-2 col-sm-8">
        <hr>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.code">
      <div *ngIf="inState('provisioning', 'packing') && detail.proviWoi?.total > 0">
        <label class="col-sm-2 control-label">
          Tâches Provi.
        </label>
        <div class="col-sm-4">
          <div class="progress-bar-text-center">
            <div class="progress">
              <ngb-progressbar [type]="detail.proviWoi?.done === detail.proviWoi?.total ? 'success' : 'default'"
                               [striped]="detail.proviWoi?.done !== detail.proviWoi?.total"
                               [value]="detail.proviWoi?.done"
                               [max]="detail.proviWoi?.total">
                <div class="progress-bar-text">{{detail.proviWoi?.done}} / {{detail.proviWoi?.total}}</div>
              </ngb-progressbar>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="inState('shipping')">
        <label class="col-sm-2 control-label">
          Tâches Expé.
        </label>
        <div class="col-sm-4">
          <div class="progress-bar-text-center">
            <div class="progress">
              <ngb-progressbar [type]="detail.expedSendWoi?.done === detail.expedSendWoi?.total ? 'success' : 'default'"
                               [striped]="detail.expedSendWoi?.done !== detail.expedSendWoi?.total "
                               [value]="detail.expedSendWoi?.done"
                               [max]="detail.expedSendWoi?.total">
                <div class="progress-bar-text">{{detail.expedSendWoi?.done}} / {{detail.expedSendWoi?.total}}</div>
              </ngb-progressbar>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group" *ngIf="detail.code">
      <label class="col-sm-2 control-label">
        Description
      </label>
      <div class="col-sm-10">
        <textarea name="description"
                  class="form-control"
                  placeholder="Description"
                  style="resize: none;" ngbAutofocus
                  rows="5"
                  [(ngModel)]="detail.description"
                  [disabled]="mode === 'normal' || !prePackingState">
        </textarea>
      </div>
    </div>

    <ng-container *ngIf="detail.code">
      <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
        <li [ngbNavItem]="0">
          <a ngbNavLink>Lignes de demande</a>
          <ng-template ngbNavContent>
            <app-logistics-request-items-list *ngIf="tabsStatus[0]"
                                              [logisticsRequest]="detail"
                                              [editionInProgressLR]="mode === 'edition'"
                                              [filters]="logisticsRequestItems.filters"
                                              [disableNoResultDisplay]="true"
                                              [disableUrlUpdate]="true"
                                              [disableFiltersDisplay]="true"
                                              (createOrDelete)="refreshDetail()">
            </app-logistics-request-items-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="'comments'">
          <a ngbNavLink>Discussion <span class="badge primary" *ngIf="commentsCount">{{commentsCount}}</span></a>
          <ng-template ngbNavContent>
            <app-comments model="logistics-request" [pk]="detail.id"></app-comments>
          </ng-template>
        </li>

        <li [ngbNavItem]="1" *ngIf="detail.code">
          <a ngbNavLink>
            Tâches Provi.
            <span class="badge" *ngIf="!inState('new') && detail.proviWoi?.total > 0"
                  [ngClass]="detail.proviWoi?.done === 0 ? 'light' : detail.proviWoi?.done === detail.proviWoi?.total ? 'success' : 'primary'">
              {{detail.proviWoi?.done}} / {{detail.proviWoi?.total}}
            </span>
          </a>
          <ng-template ngbNavContent *ngIf="tabsStatus[1]">
            <app-work-order-items-list *ngIf="!inState('new') && detail.proviWoi?.total > 0"
                                       [filters]="workOrderItems.proviFilters"
                                       [disableUrlUpdate]="true"
                                       [disabledColumns]="workOrderItems.proviDisabledColumns"
                                       [disabledButtons]="workOrderItems.disabledButtons">
            </app-work-order-items-list>

            <div class="alert alert-info" *ngIf="inState('new') || detail.proviWoi?.total === 0">
              Aucune tâche.
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="2" *ngIf="detail.code">
          <a ngbNavLink>
            Tâches Expé.
            <span class="badge" [ngClass]="detail.expedSendWoi?.done === 0 ? 'light' : detail.expedSendWoi?.done === detail.expedSendWoi?.total ? 'success' : 'primary'"
              *ngIf="!inState('new', 'pending_dispatch', 'provisioning', 'packing') && detail.expedSendWoi?.total > 0">
              {{detail.expedSendWoi?.done}} / {{detail.expedSendWoi?.total}}
            </span>
          </a>
          <ng-template ngbNavContent *ngIf="tabsStatus[2]">
            <app-work-order-items-list *ngIf="afterPackingState"
                                       [filters]="workOrderItems.expedFilters"
                                       [disableUrlUpdate]="true"
                                       [disabledColumns]="workOrderItems.expedDisabledColumns"
                                       [disabledButtons]="workOrderItems.disabledButtons">
            </app-work-order-items-list>

            <div class="alert alert-info" *ngIf="!afterPackingState">
              Aucune tâche.
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="3" *ngIf="detail.code">
          <a ngbNavLink>
            Equipements
          </a>
          <ng-template  ngbNavContent *ngIf="tabsStatus[3]">
            <div class="form-group">
              <label class="col-sm-2 control-label">
                Poids total des équipments (kg)
              </label>
              <div class="col-sm-4">
                <input class="form-control "
                        type="number"
                        name="weight"
                        value="{{ detail.weight }}"
                        [readonly]="true"
                />
              </div>
            </div>
            <app-equipments-list-logistics *ngIf="tabsStatus[3]"
                                            [hideHeader]="true"
                                            [disableFiltersDisplay]="true"
                                            [disableUrlUpdate]="true"
                                            [disabledColumns]="equipment.disabledColumns"
                                            [disabledButtons]="equipment.disabledButtons"
                                            [showPrice]="false" [filters]="equipment.filters"
                                            [hideColumnsWheel]="true"
                                            [entity]="detail.site"
                                            [logisticsId]="detail.id"
                                            [lrCode]="detail.code"
                                            (lrWeight)=onEquipmentChange($event)
            >
            </app-equipments-list-logistics>
          </ng-template>
        </li>

        <li [ngbNavItem]="4" *ngIf="detail.code">
          <a ngbNavLink>Expédition</a>
          <ng-template ngbNavContent *ngIf="tabsStatus[4]">
            <div ngModelGroup="shippingTab" style="padding-bottom: 24px;">
              <div class="wcm-tab-header text-right" *ngIf="inState('shipping') && shippingLabelRefs.length >= 1">
                <app-logistics-requests-custom-invoice-generate-button
                  style="margin-right: 5px;"
                  [woiList]="detail.work_order_items"
                  [logisticsRequestCode]="detail.code"
                  *ngIf="!detail.shipping_location?.is_located_in_schengen_area">
                </app-logistics-requests-custom-invoice-generate-button>

                <app-print-shipping-label-button [labelRefs]="shippingLabelRefs" [detail]="detail"></app-print-shipping-label-button>

                <button type="button" class="btn btn-default" (click)="downloadLabels()" style="margin-left: 5px;">
                  Télécharger les étiquettes <i class="fas fa-download"></i>
                </button>
              </div>
              <div class="wcm-tab-header text-right" *ngIf="inState('recuperation')">
                  <app-print-label-button [detail]="detail" origin="lr_recuperation"></app-print-label-button>
                  <button class="btn btn-default" type="button" (click)="downloadLabelRecuperation()" style="margin-left: 5px; margin-right: 5px;">
                    Télécharger l'étiquette de récupération <i class="fa fa-download"></i>
                  </button>
                  <app-print-shipping-label-button [labelRefs]="shippingLabelRefs" *ngIf="shippingLabelRefs.length >= 1" [detail]="detail"></app-print-shipping-label-button>

                  <button type="button" class="btn btn-default" (click)="downloadLabels()" style="margin-left: 5px;" *ngIf="shippingLabelRefs.length >= 1">
                    Télécharger  {{shippingLabelRefs.length > 1 ? 'les étiquettes' : 'l\'étiquette'}}  d'expédition <i class="fas fa-download"></i>
                  </button>
              </div>

              <app-logistics-requests-detail-shipping *ngIf="!inState('new')"
                                                      [detail]="detail"
                                                      [shippingErrorContext]="shippingErrorContext"
                                                      [f]="detailForm"
                                                      [disabled]="mode === 'normal' || afterPackingState"
                                                      (weightValidation)="weightValidation($event)"
                                                      [lrWeight]="detail.weight"
                                                      (locationUpdated)="onLocationUpdate()">   <!-- Adding weight validation to check the format and get the value from child component-->
              </app-logistics-requests-detail-shipping>

              <div class="alert alert-info" *ngIf="inState('new')">
                Les informations d'expédition seront disponibles à l'arrivée en colisage.
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="5">
          <a ngbNavLink>Historique des workflow</a>
          <ng-template ngbNavContent>
            <app-workflow-histories-list [id]="detail.id" model="logistics-request" *ngIf="tabsStatus[5]">
            </app-workflow-histories-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="6">
          <a ngbNavLink>Historique des tags</a>
          <ng-template ngbNavContent>
            <app-tags-histories-list [id]="detail.id" model="logistics-request"
                                     *ngIf="tabsStatus['6'] && detail.id">
            </app-tags-histories-list>
          </ng-template>
        </li>

        <li [ngbNavItem]="7">
          <a ngbNavLink>Historique</a>
          <ng-template ngbNavContent>
            <app-model-histories-list model="logisticsrequest" [id]="detail.id" *ngIf="tabsStatus[7]">
            </app-model-histories-list>
          </ng-template>
        </li>

      </ul>
      <!-- The tabs content is dislayed inside the outlet -->
      <div [ngbNavOutlet]="nav"></div>
    </ng-container> <!-- /tabs container -->
  </form>
</div>
