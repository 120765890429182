<div class="container-fluid">
  <form class="form-horizontal" #f="ngForm">
    <div class="field-container">
      <div class="form-group">
        <label class="col-sm-2 control-label">
          Réf. ICMA
        </label>
        <div class="col-sm-4">
          <input class="form-control"
            [readonly]="mode === 'normal'" name="reference_icma" [(ngModel)]="woi.metadata.reference_icma" />
        </div>

        <label class="col-sm-2 control-label">
          Réf. DT
        </label>
        <div class="col-sm-4">
          <input class="form-control"
            [readonly]="mode === 'normal'" name="reference_dt" [(ngModel)]="woi.metadata.reference_dt" />
        </div>
      </div>
    </div>

    <app-wcm-table #wcmTable id="pneylu-eqp-list" [staticData]="items"
                   [allowPreferences]="false" [hideColumnsWheel]="true"
                   [filters]="{limit: 25}" [enableRowClick]="false"
                   refreshSignal="pneylu-eqp-list-refresh">
      <ng-template appWcmHeaderTitle text="Équipements à retourner ({{items.length}})"></ng-template>
      <ng-template appWcmHeaderRight>
        <app-reminder-email-button *hasPermissions="'Antoine:WorkOrderItemCanSendMail'"
                                    type="recovery"
                                    [woi]="woi"
                                    [disabled]="mode !== 'normal'"
                                    label="Envoyer une relance">
        </app-reminder-email-button>
        <button class="btn btn-default" (click)="addItems()" [disabled]="mode !== 'normal'">
          <i class="fas fa-plus"></i> Ajouter des équipements
        </button>
      </ng-template>

      <app-wcm-col field="code" header="Code" class="text-center" [preventSort]="true" width="120">
        <ng-template appWcmColBody let-item="item">
          <app-link name="{{item.code}}" link="/equipments/detail/{{item.code}}" [disabled]></app-link>
        </ng-template>
      </app-wcm-col>

      <app-wcm-col field="model__brand__name" header="Constructeur" class="text-center"></app-wcm-col>

      <app-wcm-col field="model__name" header="Modèle" class="text-center"></app-wcm-col>

      <app-wcm-col field="serial_number" header="N° de série" class="text-center" [preventSort]="true" [focus]="true"></app-wcm-col>

      <app-wcm-col field="entity__name" header="Client" class="text-center">
        <ng-template appWcmColBody let-item="item">
          <app-link name="{{item.entity?.name}}" link="/entities/detail/{{item.entity?.code}}" [disabled]></app-link>
        </ng-template>
      </app-wcm-col>

      <app-wcm-col field="location" header="Affectation" class="text-center"
                   type="select" [additionalArgs]="{options: locationOptions}">
      </app-wcm-col>

      <app-wcm-col field="model__type_label" header="Type" class="text-center"
                   type="select" [additionalArgs]="{options: eqpModelTypeOptions}"
                   width="150" [preventSort]="true">
      </app-wcm-col>

      <app-wcm-col id="action" label="Action" type="none" class="text-center" [preventSort]="true" width="80">
        <ng-template appWcmColBody let-item="item" let-rowIndex="rowIndex">
          <div class="action-btn-bar">
            <button class="btn btn-default btn-xs" type="button" title="Supprimer"
                [disabled]="mode !== 'normal'" (click)="delete(rowIndex)">
              <i class="fa fa-trash-alt"></i>
            </button>
          </div>
        </ng-template>
      </app-wcm-col>
    </app-wcm-table>
  </form>
</div>
