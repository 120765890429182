import { Component, inject, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { EQUIPMENT_LOCATION_OWNERS } from '@core/constants';
import { IGenericListOptions, ILogisticsSite } from '@core/interfaces';

export type TEquipmentBulkUpdateActions = 'owner' | 'equipmentModel' | 'reservedFor' | 'equipmentLocation';


@Component({
  selector: 'app-equipments-list-bulk-update-modal',
  templateUrl: './equipments-list-bulk-update-modal.component.html',
})
export class EquipmentsListBulkUpdateModalComponent implements OnInit {
  public readonly modal = inject(NgbActiveModal);

  @Input() public selectedCount: number;
  @Input() public action: TEquipmentBulkUpdateActions;
  @Input() public owner: string;

  public readonly ownerOptions = EQUIPMENT_LOCATION_OWNERS;
  public readonly reservedForList: IGenericListOptions = {
    filters: {
      is_customer: true,
      parent__isnull: true
    },
    disabledButtons: {
      type: true
    }
  };
  public equipmentLocationOptions: IGenericListOptions = {};
  public formGroup: FormGroup;

  public ngOnInit(): void {
    switch (this.action) {
      case 'owner':
        this.formGroup = new FormGroup({
          owner: new FormControl(null, Validators.required),
        });
        break;

      case 'equipmentModel':
        this.formGroup = new FormGroup({
          model: new FormControl(null, Validators.required),
        });
        break;

      case 'reservedFor':
        this.formGroup = new FormGroup({
          reservedFor: new FormControl(null, Validators.required),
        });
        break;

      case 'equipmentLocation':
        this.formGroup = new FormGroup({
          logisticsSite: new FormControl(),
          equipmentLocation: new FormControl({ value: null, disabled: true }, Validators.required),
        });

        this.formGroup.get('logisticsSite').valueChanges.subscribe((value: ILogisticsSite | null) => {
          this._refreshEquipmentLocationOptions(value);
          this._resetEquipmentLocation();
          this._toggleEquipmentLocationEnabled(!!value);
        });
        break;

      default:
        this.formGroup = new FormGroup({});
    }
  }

  public confirm(): void {
    this.modal.close({
      owner: this.formGroup.value.owner,
      model: this.formGroup.value.model?.id,
      reserved_for: this.formGroup.value.reservedFor?.id,
      equipment_location: this.formGroup.value.equipmentLocation?.id,
    });
  }

  private _toggleEquipmentLocationEnabled(enable: boolean): void {
    if (enable) {
      this.formGroup.get('equipmentLocation').enable();
    } else {
      this.formGroup.get('equipmentLocation').disable();
    }
  }

  private _resetEquipmentLocation(): void {
    this.formGroup.get('equipmentLocation').setValue(null);
  }

  private _refreshEquipmentLocationOptions(logisticsSite: ILogisticsSite | null): void {
    console.log(this.formGroup.value);
    this.equipmentLocationOptions = {
      filters: {
        logistics_site__code: logisticsSite?.code,
        owner: this.owner,
        ordering: 'id',
      },
      disabledColumns: {
        actions: true,
      },
    };
  }
}
