import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';

import { isString } from '@core/helpers';
import { UserService } from '@core/services/user.service';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[hasPermissions]'
})
export class HasPermissionsDirective implements OnInit {
  @Input('hasPermissions') public permissions: string | string[];
  @Input('hasPermissionsElse') public permissionsElse?: TemplateRef<any>;

  constructor(
    private readonly viewContainerRef: ViewContainerRef,
    private readonly templateRef: TemplateRef<unknown>,
    private readonly userService: UserService,
  ) {
  }

  public ngOnInit(): void {
    this.userService.currentUserPermissions.subscribe(() => this._refreshVisibility());
  }

  private _refreshVisibility(): void {
    const permissionList = isString(this.permissions) ? [this.permissions] : this.permissions;
    const hasPermission = !permissionList.length || this.userService.hasPermissions(...permissionList);

    // Clear always to prevent duplicating embedded views
    this.viewContainerRef.clear();

    if (hasPermission) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
    } else if (this.permissionsElse) {
      this.viewContainerRef.createEmbeddedView(this.permissionsElse);
    }
  }
}
