import { Component, Input, Output, OnInit, Injector, EventEmitter } from '@angular/core';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiWoogleService } from '@core/apis/api-woogle.service';
import { EQP_LOCATIONS, EquipmentLocationEnum } from '@core/constants';

import { EquipmentsMacAddrModalComponent } from './equipments-mac-addr-modal.component';
import { EquipmentsSelectModalComponent } from './equipments-select-modal.component';


@Component({
  selector: 'app-equipments-bulk-assign-modal',
  templateUrl: './equipments-bulk-assign-modal.component.html',
  styles: []
})
export class EquipmentsBulkAssignModalComponent implements OnInit {

  @Input() public entity: any;
  @Input() public lr_emiting: any;

  public loading = false;
  private signalsService: SignalsService;
  public serialNumber: string;
  public items = [];
  private serialNumbersList = [];
  public isEqpListValid = true;
  public locationOptions = EQP_LOCATIONS;
  private alertModalTitle = 'Recherche Equipement';

  constructor(
    public injector: Injector,
    public modal: NgbActiveModal,
    private wcmModalsService: WcmModalsService,
    private ngbModal: NgbModal,
    private apiShiva: ApiShivaService,
    private toastr: ToastrService,
    private apiWoogle: ApiWoogleService
  ) {
    this.signalsService = injector.get(SignalsService);
  }

  public ngOnInit(): void {}

  public searchForSerial() {
    if (!this.serialNumber) { return; }
    this.loading = true;
    const payload = {
      raw_query: this.serialNumber
    };
    this.apiWoogle.searchEquipment(payload)
      .then((res) => {
        this.handleSuggest(res.hits.hits);
      })
      .catch((err) => {
        this.toastr.error('Erreur lors de la recherche. Veuillez essayer à nouveau.');
      }).finally(() => {
        this.loading = false;
      });
  }

  public handleException() {
    this.wcmModalsService.alert(
      `Conflit dans la liste des équipements à affecter`,
      `L'équipement concerné est déjà affecté en magasin sur une autre entité.`
    );
  }

  public deleteItem(index: number) {
    this.items.splice(index, 1);
    this.serialNumbersList.splice(index, 1);
    this.checkEqpListValidity();
  }

  public assignAndClose() {
    this.loading = true;
    const payload = {
      entity_code: this.entity.code,
      equipment_codes: [],
      lr_emiting: this.lr_emiting,
    };

    this.items.forEach((item) => {
      payload.equipment_codes.push(item.code);
    });
    this.apiShiva.equipments.bulk_assign(payload)
      .then(() => {
        // Here we send the result sent by the meth bulk_assign to the parent component
        this.loading = false;
        this.signalsService.broadcast('equipments-list-refresh');
        this.modal.close();
      }).catch((err) => {
        this.toastr.error(`Erreur lors de l'enregistrement. L'opération n'a pas été prise en compte.`);
      });
  }

  private handleSuggest(suggestion) {
    if (suggestion.length === 1) {
      if (this.items.some((item) => item.serial_number === suggestion[0]._source.serial_number)) {
        this.wcmModalsService.alert(this.alertModalTitle, `Un équipement possédant le numéro de série <b>${this.serialNumber}</b> a déjà été ajouté.`);
      } else {
        this.addEqpToList(suggestion[0]._source);
      }
    } else if (suggestion.length === 0) {
      this.wcmModalsService.alert(this.alertModalTitle, `Aucun équipement correspondant avec le numéro de série <b>${this.serialNumber}</b>.`);
    } else if (suggestion.length > 1) {
      const snToSelect = [];
      suggestion.filter((el) => {
        if (this.serialNumbersList.indexOf(el._source.serial_number) < 0) { snToSelect.push(el); }
      });
      if (snToSelect.length === 0) {
        this.wcmModalsService.alert(this.alertModalTitle, `Les équipements correspondant au numéro de série <b>${this.serialNumber}</b> ont déjà tous été ajoutés.`);
      } else {
        const modal = this.ngbModal.open(EquipmentsSelectModalComponent, {backdrop: 'static', size: 'lg'});
        modal.componentInstance.eqpList = snToSelect;
        modal.result
          .then((res) => {
            this.addEqpToList(res);
          }, err => {
            console.error(`Erreur lors de l'ajout de l'équipement`, err);
            this.toastr.error(`Erreur lors de l'ajout de l'équipement`);
          });
      }
    } else {
      console.error(`Un problème lié à la recherche est intervenu. Veuillez ré-essayer.`);
    }
    this.serialNumber = null;
  }

  private addEqpToList(eqp) {
    // checking if a mac addr is required by the model and if the eqp has it before assigning it to an entity
    if (eqp.model.need_mac_address && !eqp.mac_address) {
      this.addMacAddressToEqp(eqp);
    } else {
      eqp.isInvalid = this.isEqpInvalidToAssign(eqp);
      this.items.push(eqp);
      this.checkEqpListValidity();
      this.serialNumbersList.push(eqp.serial_number);
    }
  }

  private addMacAddressToEqp(eqp) {
    const modal = this.ngbModal.open(EquipmentsMacAddrModalComponent, {backdrop: 'static', size: 'md'});
    modal.result.then((res) => {
      eqp.mac_address = res;
      this.updateEquipmentWithMacAddr(eqp);
      eqp.isInvalid = this.isEqpInvalidToAssign(eqp);
      this.items.push(eqp);
      this.checkEqpListValidity();
      this.serialNumbersList.push(eqp.serial_number);
    }).catch(() => {
      this.toastr.warning(`L'adresse MAC n'ayant pas été renseignée, l'équipement n'a pas été ajouté.`);
    });
  }

  private updateEquipmentWithMacAddr(eqp) {
    this.loading = true;
    const payload = {
      mac_address: eqp.mac_address
    };
    this.apiShiva.equipments.update(eqp.code, payload)
      .then((res) => {
        this.loading = false;
      })
      .catch((err) => {});
  }

  private isEqpInvalidToAssign(eqp): boolean {
    return (eqp.entity.code !== this.entity.code && eqp.location !== EquipmentLocationEnum.Stock);
  }

  private checkEqpListValidity(): void {
    this.isEqpListValid = true;
    this.items.forEach((item) => {
      if (item.isInvalid) { this.isEqpListValid = false; }
    });
  }
}
