<ng-container *ngIf="mode === 'edition'; else displayOnly">
  <div class="form-group">
    <label class="col-sm-2 control-label">
      <b>{{ nameFieldLabel }}</b>
    </label>
    <div class="col-sm-4">
      <app-contact-field name="contact"
                         [filters]="{entity__name: entityName, source__nin: 'orch'}"
                         [ngModel]="contact"
                         [disabledButtons]="{ entityNameFilter: false }"
                         (ngModelChange)="onContactChange($event)">
      </app-contact-field>
    </div>
  </div>
</ng-container>

<ng-template #displayOnly>
  <ng-container *ngIf="detailMode === 'simplified'; else fullDetail">
    <div class="form-group">
      <ng-container *ngTemplateOutlet="contactName"></ng-container>
      <!-- mobile number takes priority over phone -->
      <ng-container *ngIf="contact?.mobile; else contactPhone">
        <ng-container *ngTemplateOutlet="contactMobile"></ng-container>
      </ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #fullDetail>
  <div class="form-group">
    <ng-container *ngTemplateOutlet="contactName"></ng-container>
    <ng-container *ngTemplateOutlet="contactEmail"></ng-container>
  </div>

  <div class="form-group">
    <ng-container *ngTemplateOutlet="contactPhone"></ng-container>
    <ng-container *ngTemplateOutlet="contactMobile"></ng-container>
  </div>
</ng-template>

<ng-template #contactName>
  <label class="col-sm-2 control-label">
    <b>{{ nameFieldLabel }}</b>
  </label>
  <div class="col-sm-4">
    <p class='form-control-static'>
      {{ contact?.first_name }} {{ contact?.last_name }}
    </p>
  </div>
</ng-template>

<ng-template #contactEmail>
  <label class="col-sm-2 control-label">
    <b>Email</b>
  </label>
  <div class="col-sm-4">
    <p class='form-control-static'>
      {{ contact?.email }}
    </p>
  </div>
</ng-template>

<ng-template #contactPhone>
  <label class="col-sm-2 control-label">
    <b>Téléphone</b>
  </label>
  <div class="col-sm-4">
    <p class='form-control-static'>
      <a href="tel:{{ contact?.phone }}">{{ contact?.phone }}</a>
    </p>
  </div>
</ng-template>

<ng-template #contactMobile>
  <label class="col-sm-2 control-label">
    <b>Mobile</b>
  </label>
  <div class="col-sm-4">
    <p class='form-control-static'>
      <a href="tel:{{ contact?.mobile }}">{{ contact?.mobile }}</a>
    </p>
  </div>
</ng-template>
