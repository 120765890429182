import { Component, Injector, ViewChild, Input, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as format from 'string-format';

import { FileUploadModalComponent } from '@core/components/file-upload-modal/file-upload-modal.component';
import { DEPRECATED_EQUIPMENT_MODEL_TYPES, EQP_LOCATIONS, EQUIPMENT_MODEL_TYPES, EquipmentLocationEnum } from '@core/constants';
import { GenericListComponent } from '@core/globals/generic-list/generic-list.component';
import { WcmTableComponent } from '@core/globals/wcm-table/wcm-table.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { omit } from '@core/helpers';
import { IEntity, IEquipment, IJobResponse, ISelectedItems, ISelectedPk } from '@core/interfaces';
import { SignalsService } from '@core/services/signals.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';
import { EntitiesModalComponent } from '@views/entities/entities-modal.component';
import { TagBulkUpdateService } from '@views/tags/tag-bulk-update.service';

import { EquipmentsBulkDeleteErrorModalComponent } from './equipments-bulk-delete-error-modal.component';
import { EquipmentsMacAddrModalComponent } from './equipments-mac-addr-modal.component';
import {
  EquipmentsListBulkUpdateModalComponent,
  TEquipmentBulkUpdateActions
} from '@views/equipments/equipments-list-bulk-update-modal.component';


@Component({
  selector: 'app-equipments-list',
  templateUrl: './equipments-list.component.html',
  styles: []
})
export class EquipmentsListComponent extends GenericListComponent implements OnInit {
  @ViewChild('wcmTable', {static: true}) public wcmTable: WcmTableComponent;

  @Input() public entity: IEntity;
  @Input() public chooserRowClick: boolean;
  @Input() public showPrice = true;

  public readonly EquipmentLocationEnum = EquipmentLocationEnum;
  public readonly EQP_LOCATIONS = EQP_LOCATIONS;

  public loadingExport = false;
  public loadingPrice = false;
  public totalPrice = 0;
  public ignoredFiltersForStr = ['exactSearch'];
  public typeOptions: {[key: string]: string};
  public loadingAction: boolean = false;

  constructor(
    public injector: Injector,
    private readonly signalsService: SignalsService,
    private readonly wcmModalsService: WcmModalsService,
    private readonly ngbModal: NgbModal,
    private readonly tagBulkUpdateService: TagBulkUpdateService,
  ) {
    super(injector);

    this.localDisabledColumns = {
      created_at: true,
      entity__code: true,
      entity__customer_ref: true,
      entity__location__city: true,
      entity__parent__code: true,
      entity__parent__name: true,
      immobilization_number: true,
      mac_address: true,
      model__branded_name: true,
      model__type_label: true,
      model__weight: true,
      license_price: true,
      price_untaxed: true,
      provider_order__order_number: true,
      provider_order_number: true,
      reserved_for__code: true,
      selection: true,
    };

    this.localFilters = {ordering: '-last_stock_activity'};

    this.localDisabledButtons = {
      assign: true,
      history: true,
      assignTags: true
    };

    // Build a dict of value label with our list of types
    this.typeOptions = {};
    const typeList = [...EQUIPMENT_MODEL_TYPES, ...DEPRECATED_EQUIPMENT_MODEL_TYPES].sort();
    typeList.forEach((typeStr: string) => this.typeOptions[typeStr] = typeStr);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    const isEqpList = (this.location.path(true).startsWith('/equipments/list'));
    if (isEqpList && this.hasPermissions('Wira:EqpAdmin')) {
      this.localDisabledColumns.selection = false;
      this.localDisabledButtons.assignTags = false;
    }
  }

  public createFromExternalEquipmentOrder(): Promise<boolean> {
    return this.router.navigateByUrl('/external-equipment-orders');
  }

  public showImportEquipmentsModal(): void {
    const modal: NgbModalRef = this.ngbModal.open(FileUploadModalComponent, {size: 'lg', backdrop: 'static'});
    modal.componentInstance.uploadUrl = this.apiShiva.equipments.bulk_update();
    modal.componentInstance.acceptedFileTypes = ['.xlsx'];
    modal.componentInstance.jobName = 'Import des équipements';
  }

  public downloadTemplate(name: string): void {
    this.apiShiva.templates.detailByName(name)
      .then((res) => {
        const url = this.apiShiva.attachments.downloadUrl(res['last_attachment_id']);
        window.open(url, 'Téléchargement du template');
      }, err => {
        console.error('Erreur lors du téléchargement du template', err);
        this.toastr.error('Erreur lors du téléchargement du template');
      });
  }

  /**
   * Clear the currente table selection
   */
  public clearSelection(): void {
    this.wcmTable.unselectAll();
  }

  public assignToShop(item: IEquipment): void {
    // checking if a mac addr is required by the model and if the eqp has it before assigning it to an entity
    if (item.model.need_mac_address && !item.mac_address) {
      const modal: NgbModalRef = this.ngbModal.open(EquipmentsMacAddrModalComponent, {size: 'md'});
      modal.result.then(
        res => {
          const itemWithMacAddr = {
            ...item,
            mac_address: this._cleanMacAddress(res),
          };
          this._selectEntityAndAssign(itemWithMacAddr);
        },
        () => {}
      );
    } else {
      this._selectEntityAndAssign(item);
    }
  }

  public assignToStock(item: IEquipment): void {
    this.wcmModalsService.confirm('Retour au stock', `Renvoyer l'équipement au stock ?`, 'Confirmer', 'Annuler').then(
      () => {
        const payload = {
          ...item,
          location: EquipmentLocationEnum.Stock,
          entity: null,
        };
        this._updateEquipment(payload);
      },
      () => {}
    );
  }

  public assignToInvoicing(item: IEquipment): void {
    this.wcmModalsService.confirm(`Facturer l'équipement`, `Souhaitez-vous facturer l'équipement ?`, 'Confirmer', 'Annuler').then(
      () => {
        const payload = {
          ...item,
          location: EquipmentLocationEnum.Invoicing,
          entity: null,
        };
        this._updateEquipment(payload);
      },
      () => {}
    );
  }

  public toggleExactSearch(): void {
    this.wcmTable.filters.exactSearch = !this.wcmTable.filters.exactSearch;
    if (this.wcmTable.filters.exactSearch) {
      this.wcmTable.filters.model__exact_name = this.wcmTable.filters.model__name;
      this.wcmTable.filters.model__name = null;
    } else {
      this.wcmTable.filters.model__name = this.wcmTable.filters.model__exact_name;
      this.wcmTable.filters.model__exact_name = null;
    }
    this.wcmTable.refreshTable();
  }

  public confirmBulkDelete(selectedIds: ISelectedPk): void {
    // If wcmTable contains selected items, then the total should reflect the select item count
    const itemCount = Object.keys(selectedIds).length > 0 ? Object.keys(selectedIds).length : this.wcmTable.itemCount;
    const msg = `Confirmez vous la suppression des ${itemCount} équipements filtrés ?`;
    this.wcmModalsService.confirm('Suppression des équipements filtrés', msg)
      .then(() => {
        this._bulkDelete(Object.keys(selectedIds));
      }, () => {});
  }

  public exportResults(): void {
    this.loadingExport = true;
    const cleanedFilters = omit(this.wcmTable.filters, 'limit', 'offset', 'ordering', 'serializer');
    this.apiShiva.equipments.export(cleanedFilters)
      .then((res) => {
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier d'export soit généré.`);
      })
      .catch(() => {
        const errMsg = `Echec de la demande d'export. Veuillez réessayer.`;
        this.toastr.error(errMsg);
      })
      .finally(() => this.loadingExport = false);
  }

  public fetchCallback(): void {
    this.loadingPrice = true;
    this.apiShiva.equipments.compute_total_price(this.wcmTable.filters)
      .then((result: number) => {
        this.totalPrice = (result || 0);
      })
      .catch(() => {
        this.toastr.error(`Impossible de calculer le prix total des équipements filtrés. Veuillez rafraîchir la page.`);
        this.totalPrice = 0;
      })
      .finally(() => this.loadingPrice = false);
  }

  private _bulkDelete(selectedIds: string[]): void {
    let filters = omit(this.wcmTable.filters, 'limit', 'offset', 'ordering', 'serializer');
    if (selectedIds.length) {
      filters = { id__in: selectedIds };
    }

    this.apiShiva.equipments.bulk_delete(filters)
      .then(() => {
        this.toastr.success('Suppression des équipements terminée.');
        this.wcmTable.refreshTable();
      })
      .catch((err) => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'NO_FILTER') {
            this.toastr.error(`Impossible de lancer la suppression, il faut préalablement appliquer un filtre sur la liste.`);
            return;
          } else if (err.getFirstErrorMessage() === 'TOO_MUCH_EQUIPMENTS') {
            this.toastr.error(`Impossible de lancer la suppression vous avez sélectionné trop d'équipements (${err.context['total']} > 100).`);
            return;
          } else if (err.getFirstErrorMessage() === 'WRONG_EQUIPMENT_HISTORY') {
            this.toastr.error(`Impossible de lancer la suppression suite à un problème d'historique.`);
            const modal = this.ngbModal.open(EquipmentsBulkDeleteErrorModalComponent, {size: 'md'});  // no need to open modal for the previous errors bcs no code detail
            modal.componentInstance.formattedError = 'WRONG_EQUIPMENT_HISTORY';
            modal.componentInstance.errorDetail = err.context['error_detail']; // contains equipment__code including an issue
            return;
          }
        }
        return Promise.reject(err);
      });
  }

  public async bulkUpdate(selectedItems: ISelectedItems, action: TEquipmentBulkUpdateActions): Promise<void> {
    const selectedEquipments: IEquipment[] = Object.values(selectedItems);

    if (!selectedEquipments.length) {
      this.toastr.error('Aucun équipement sélectionné, il n\'y a rien à faire.');
      return;
    }

    if (action === 'equipmentModel') {
      const modelCount: number = this._countDistinctValues(selectedEquipments.map((equipment: IEquipment) => equipment.model.id));
      if (modelCount !== 1) {
        this.toastr.error('Pour changer le modèle il faut que tous les équipements sélectionnés soient du même modèle.');
        return;
      }
    }

    if (action === 'equipmentLocation') {
      const ownerCount: number = this._countDistinctValues(selectedEquipments.map((equipment: IEquipment) => equipment.owner));
      if (ownerCount !== 1) {
        this.toastr.error('Pour changer l\'emplacement il faut que tous les équipements sélectionnés aient le même propriétaire.');
        return;
      }
    }

    const modal = this.ngbModal.open(EquipmentsListBulkUpdateModalComponent, { size: 'md' });
    modal.componentInstance.action = action;
    modal.componentInstance.selectedCount = selectedEquipments.length;
    modal.componentInstance.owner = selectedEquipments[0].owner;

    modal.result.then(
      (updateFields: Partial<IEquipment>) => {
        this.loadingAction = true;
        this.apiShiva.equipments.bulkPatch(Object.keys(selectedItems), updateFields)
          .then((res: IJobResponse) => {
            this.signalsService.broadcastJobStart(res.job_name, res.job_id);
            this.clearSelection();
            this.wcmTable.refreshTable();
          })
          .catch((error: unknown) => this._handleBulkError(error))
          .finally(() => this.loadingAction = false);
      },
      () => {},
    );
  }

  /**
   * Update the tags for one or more equipments
   * @param selectedPk The dictionary of item ids selected in the table
   */
  public bulkUpdateTags(selectedPk: ISelectedPk): void {
    this.loadingAction = true;
    this.tagBulkUpdateService.selectTagsAndUpdate(
      Object.keys(selectedPk),
      this.apiShiva.equipments.bulkPatchTags,
      'équipement'
    )
      .then(() => {
        this.clearSelection();
        this.signalsService.broadcast('equipments-list-refresh');
      })
      .finally(() => this.loadingAction = false);
  }

  private _cleanMacAddress(macAddress: string): string {
    macAddress = macAddress.replace(/[-\s:]/g, '');
    macAddress = format('{}{}:{}{}:{}{}:{}{}:{}{}:{}{}', ...macAddress);
    return macAddress;
  }

  private _selectEntityAndAssign(item: IEquipment): void {
    const modal = this.ngbModal.open(EntitiesModalComponent, {size: 'lg'});

    modal.result.then((selectedEntity) => {
      const payload = {
        ...item,
        location: EquipmentLocationEnum.Shop,
        entity: selectedEntity,
      };
      this._updateEquipment(payload);
    }, () => {});
  }

  private _updateEquipment(eqp: IEquipment): void {
    this.apiShiva.equipments.update(eqp.code, eqp)
      .then(() => this.wcmTable.refreshTable());
  }

  private _handleBulkError(err: unknown): void {
    if (err instanceof WaycomHttpErrorResponse) {
      const errorMap: Record<string, string> = {
        MISSING_PAYLOAD_OR_EQUIPMENTS_CODES: `Aucun équipement n'a été fourni lors de la mise à jour.`,
        INCORRECT_EQUIPMENTS: `Certains équipements n'ont pas pu être récupérés.`,
        COULD_NOT_PATCH_EQUIPMENTS_WITH_DIFFERENT_MODEL: `Les équipements sélectionnés doivent être du même modèle.`,
        COULD_NOT_PATCH_EQUIPMENTS_WITH_DIFFERENT_OWNER: `Les équipements sélectionnés doivent être du même propriétaire.`,
        MODEL_DOES_NOT_EXIST: `Erreur de récupération de l'élément sélectionné.`,
      };
      const errorMessage = errorMap[err.getFirstErrorMessage()];
      if (errorMessage) {
        this.toastr.error(errorMessage);
        return;
      }
    }
    Promise.reject(err);
  }

  private _countDistinctValues<T = unknown>(values: T[]): number {
    const distinctValues: Set<T> = new Set<T>(values);
    return distinctValues.size;
  }
}
