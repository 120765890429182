import { Component, OnInit, Injector, ViewChild, Input, OnDestroy } from '@angular/core';
import { NgForm } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { debounce } from 'underscore';
import { Subscription } from 'rxjs';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { PROVI_PRODUCTS_LIST } from '@core/constants';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { omit } from '@core/helpers';
import { IGenericApi } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';

import { LogisticsRequestsDetailStateShippingModalComponent } from './logistics-requests-detail-state-shipping-modal.component';
import { LogisticsRequestsService } from './logistics-requests.service';
import { LogisticsRequestDifferentAddressModalComponent } from './logistics-requests-different-address-modal.component';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

@Component({
  selector: 'app-logistics-requests-detail',
  templateUrl: './logistics-requests-detail.component.html',
  styles: []
})
export class LogisticsRequestsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @Input() public prefillSite: any;
  @Input() public inModal: boolean;
  private readonly listUrl = '#/logistics-requests/list?state__nin=cancelled&state__nin=done';
  private defaultBreadcrumbsData = [{label: 'Demandes logistiques', url: this.listUrl}];
  // The viewName is used to build a key for the user preferences
  // Uncomment it if you want the last tab position to be saved in the user preferences
  public viewName = 'logistics-requests';
  public workOrderItems: any;
  public logisticsRequestItems: any;
  public today: Date;
  public stateConfirmFunctMap: Record<string, () => Promise<void>>;
  public stateErrorFuncMap: any;
  public shippingLabelRefs: string[];
  public shippingErrorContext: {shippingErrors?: string[], returnErrors?: string[], transition: string};
  public equipment: {filters: {}, disabledColumns: {}, disabledButtons: {}};
  public hasActiveLogisticsRequests: boolean;
  public commentsCount: number;
  public entityAlerts = [];
  public entityIsBlocked = false;
  public invalidWeight: boolean = false;
  private proviProductsList = PROVI_PRODUCTS_LIST;
  private signalSubscriptions: Subscription[] = [];
  private api: IGenericApi;

  // Live update channel to listen to woi updates
  private liveUpdateWoiChannel: string;
  private debouncedFetch: any;
  private boundWoiWsCallback: any;

  public get afterPackingState(): boolean {
    return !this.inState('new', 'pending_dispatch', 'ready', 'provisioning', 'packing');
  }

  public get prePackingState(): boolean {
    return this.inState('new', 'pending_dispatch', 'ready', 'provisioning');
  }

  constructor(
    private apiShiva: ApiShivaService,
    private logisticsRequestsService: LogisticsRequestsService,
    private promisesService: PromisesService,
    private modalService: NgbModal,
    private wcmModalsService: WcmModalsService,
    public injector: Injector
  ) {
    super(injector);
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {
      state: {
        name: 'new',
        style: 'primary',
        label: 'Nouveau'
      },
      shipping: 'standard',
      proviWoi: {done: 0, total: 0},
      expedSendWoi: {done: 0, total: 0},
      expedWoi: {total: 0}
    };
    // Api used for fetch, update and create
    this.api = this.apiShiva.logistics_requests as IGenericApi;

    // This enables the live update (websocket)
    this.liveUpdateChannel = 'logisticsRequest';

    this.stateErrorFuncMap = {
      start: this._startErrorModal.bind(this),
      pending_dispatch_to_ready: this._startErrorModal.bind(this),
      send: this._shippingModal.bind(this),
      collect: this._shippingModal.bind(this)
    };
    this.stateConfirmFunctMap = {
      start: this._startConfirmModals.bind(this),
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.today = moment().toDate();
    if (!this.pk && this.prefillSite) {
      // prefill the site when creating LR in modal _ from entity page quick action
      // input prefill site is not available in constructor
      this.detail.site = this.prefillSite;
      // check if entity has active Logistics Requests
      this._findEntityActiveLogisticsRequests(this.prefillSite.code);
    }

    this._initSubscriptions();
    this._initLiveWoiUpdate();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
    this._stopLiveWoiUpdate();
  }

  public save(): void {
    if (!(this.detailForm && this.detailForm.valid)) {
      return;
    }
    this.loading = true;

    switch (this.detail.shipping) {
      case 'standard':
        this.detail.urgent_shipping = false;
        this.detail.without_shipping = false;
        break;
      case 'urgent':
        this.detail.urgent_shipping = true;
        this.detail.without_shipping = false;
        break;
      case 'without':
        this.detail.urgent_shipping = false;
        this.detail.without_shipping = true;
        break;
    }

    this.detail = omit(this.detail, 'shipping');

    let promise;
    if (this.detail.code) {
      promise = this.api.update(this.detail.code, this.detail);
    } else {
      promise = this.api.create(this.detail);
    }

    promise.then((res) => {
      if (!this.detail.code) {
        // it was a creation
        this.pk = res.code;
        this.signalsService.broadcast('logistics-requests:create', res.code);
        this._initTabs(res);
      }
      this.detail = this._handleShipping(res);
      this._countWoi();
      this._updateBreadcrumbs();
      this.mode = 'normal';
      this.modeChanged.emit(this.mode);
      this.signalsService.broadcast('model-history-list-refresh');
      this.signalsService.broadcast('woi-list-refresh');
      this.signalsService.broadcast('locations:update');
      this.detailSaved.emit(this.detail);
      this._initFIlters();
    }).catch((err) => {
      Promise.reject(err);
    }).finally(() => {
      this.loading = false;
    });
  }

  public onStateUpdate(): void {
    this._fetch();
    this.shippingErrorContext = null;
    this.signalsService.broadcast('workflow-histories-list-refresh');
    this.signalsService.broadcast('woi-list-refresh');
    this.signalsService.broadcast('locations:update');
  }

  public onLocationUpdate(): void {
    this._fetch();
  }

  public weightValidation(event): void {
    this.invalidWeight = event.invalid;
    this.detail.weight = event.weightValue;
  }

  public onEntityUpdate(entity): void {
    // check if entity has active Logistics Requests to display warning
    if (entity && entity.code) {
      this._findEntityActiveLogisticsRequests(entity.code);
      this._getEntityAlerts();
    }
  }

  public downloadLabels(): void {
    this.api.download_pdf_labels(this.detail.code)
      .then((res) => {
        this.signalsService.broadcastJobStart(res['job_name'], res['job_id']);
        this.toastr.success(`Demande prise en compte. Veuillez patienter le temps que le fichier soit généré.`);
      })
      .catch((err) => {
        const errMsg = `Échec de la demande d'export. Veuillez réessayer.`;
        this.toastr.error(errMsg);
      });
  }

  public downloadLabelRecuperation(): void {
    window.open(
      this.api.download_recuperation_pdf_label(this.detail.code),
      `Téléchargement de l'étiquette`
    );
  }

  public refreshDetail(): void {
    this._fetch();
  }

  public inState(...states: string[]): boolean {
    return states.includes(this.detail?.state?.name);
  }

  public onEquipmentChange(eventData: { weight:  number }){
    this.detail.weight = eventData.weight;
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then((res) => {
        this.detail = this._handleShipping(res);
        this._countWoi();
        this._updateBreadcrumbs();
        this._initTabs(res);
        this._buildshippingLabelRefs(res);
        this._getEntityAlerts();

      }, () => {}).finally(() => {
        this.loading = false;
      });
  }

  /**
   * initialise the various signal subscriptions and add them to the list to be unsubscribed on ngOnDestroy()
   */
  private _initSubscriptions(): void {
    const commentsCountSubscription = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
    this.signalSubscriptions.push(commentsCountSubscription);
  }

  /**
   * fetch entity alerts for this entity, which may block the creation / advancement of LRs if the
   * alert is of type 'accounting' (blocage compta)
   */
  private _getEntityAlerts(): void {
    this.entityIsBlocked = false;
    this.entityAlerts = [];
    if (!this.detail.site) { return; }

    const filters = {
      is_active: true,
      date_active_now: true,
      entity_code_compat: this.detail.site.code
    };
    this.apiShiva.entity_alerts.list(filters).then((res) => {
      this.entityAlerts = res['results'];
      this.entityIsBlocked = false;
      this.entityAlerts.forEach((entityAlert) => {
        if (entityAlert.type === 'accounting') {
          this.entityIsBlocked = true;
        }
      });
    });
  }

  private _buildshippingLabelRefs(res): void {
    this.shippingLabelRefs = [];
    if (['shipping', 'recuperation'].includes(this.detail?.state?.name )) {
      for (const woi of this.detail.work_order_items) {
        if (woi?.product?.code === 'P-EXPED' && woi?.metadata?.shipment_ref) {
          this.shippingLabelRefs.push(woi?.metadata?.shipment_ref);
        }
      }
    }
  }

  private _countWoi(): void {
    this.detail.expedSendWoi = {
      done: this.logisticsRequestsService.countWoiDone(this.detail.work_order_items, ['P-EXPED'], 'SEND'),
      total: this.logisticsRequestsService.countWoi(this.detail.work_order_items, ['P-EXPED'], 'SEND')
    };

    this.detail.expedWoi = {
      total: this.logisticsRequestsService.countWoi(this.detail.work_order_items, ['P-EXPED'])
    };

    this.detail.proviWoi = {
      done: this.logisticsRequestsService.countWoiDone(this.detail.work_order_items, this.proviProductsList),
      total: this.logisticsRequestsService.countWoi(this.detail.work_order_items, this.proviProductsList)
    };
  }

  private _startErrorModal(error: WaycomHttpErrorResponse): void {
    const errorType = (error.context && error.context.error_detail);

    const errorMap: Record<string, string> = {
      LOGISTICS_SITE_MISSING: `Le site logistique est obligatoire pour démarrer la LR.`,
      SHIPPING_SITE_WITHOUT_LOCATION: `L'entité renseignée ne possède pas d'adresse.`,
      LRI_DOES_NOT_EXIST: `Veuillez insérer au moins une ligne de demande logistique avant de démarrer.`,
      NEED_DEFAULT_CONTACT_IF_NONE_PROVIDED: `Il est nécessaire que le site posséde un contact si aucun n'est indiqué sur la LR.`,
      NEED_DEFAULT_CONTACT: `Il est nécessaire que le site posséde un contact.`,
      INVALID_DUE_DATE: `Impossible de démarrer la LR veuillez mettre à jour la date souhaitée.`,
    };

    const errorMessage = errorMap[errorType] || `Une erreur inattendue bloque le démarrage de la LR`;

    this.wcmModalsService.alertWithCancelOnly(
      `Démarrage de la demande logistique bloqué`,
      errorMessage,
    );
  }

  private _startConfirmModals(): Promise<any> {
    return this._checkAddress()
      .then(() => this._checkDueDate());
  }

  /**
   * Check if the shipping location is correct, asking confirmation if it isn't
   *
   * @private
   * @return A promise with the result of the modal if confirmation is required
   */
  private _checkAddress(): Promise<any> {
    // we can't begin a logistics request without at least an address be it the shipping or the principal address
    if (!this.detail.shipping_location && !this.detail.site.ship_location && !this.detail.site.location) {
      this.toastr.error(`Aucune adresse de livraison présente sur la LR ou sur le site.`);
      return Promise.reject();
    }

    // If we don't have a shipping location on this LR AND the site have a diffrent ship location than its principal location, we show a warning
    const differentAddress = !this.detail.shipping_location && this.detail.site.ship_location !== null &&  this.detail.site.ship_location?.address !== this.detail.site.location?.address;
    if (differentAddress) {
      const modal = this.modalService.open(LogisticsRequestDifferentAddressModalComponent, {
        backdrop: 'static',
        size: 'md'
      });
      // passing the data
      modal.componentInstance.sendLocation = this.detail.site.ship_location.address;
      modal.componentInstance.sitePrincipalLocation = this.detail.site.location.address;

      return modal.result;
    }

    return Promise.resolve();
  }

  /**
   * Check if the due date is correct, asking confirmation if it isn't
   * @private
   * @return A promise with the result of the modal if confirmation is required
   */
  private _checkDueDate(): Promise<any> {
    const today = moment(moment().toDate().setHours(0, 0, 0));
    const expiredDueDate = !this.detail.due_date || moment(this.detail.due_date).isBefore(today);

    if (expiredDueDate) {
      return this.wcmModalsService
        .confirm(
          'Date souhaitée inexistante ou échue',
          'La date souhaitée est inférieure à la date du jour, celle-ci va automatiquement être mise à jour à J+2 de la date du jour.<br > Souhaitez-vous continuer?',
          'Oui', 'Non',
        )
        .then(() => ({}));
    }

    return Promise.resolve({});
  }

  private _handleShipping(detail) {
    const handler = detail;
    if (handler.urgent_shipping) {
      handler.shipping = 'urgent';
    } else if (handler.without_shipping) {
      handler.shipping = 'without';
    } else {
      handler.shipping = 'standard';
    }
    return handler;
  }

  private _shippingModal(error): Promise<any> {
    const deferred = this.promisesService.defer();
    // This function must return a promise to be used by the state field directive
    if (error) {
      const modal = this.modalService.open(LogisticsRequestsDetailStateShippingModalComponent, {backdrop: 'static', size: 'md'});
      // passing the data
      modal.componentInstance.error = error;
      modal.componentInstance.logisticsRequest = this.detail;
      modal.result.then((res: {shippingErrors?: string[], returnErrors?: string[], transition: string}) => {
        this.shippingErrorContext = res;
        deferred.resolve();
      }).catch(() => {
        deferred.reject();
      });
    } else {
      this.shippingErrorContext = null;
    }
    return deferred.promise;
  }

  private _initTabs(detail): void {
    // If any tab filter must be initialized, it's done here
    this.logisticsRequestItems = {
      filters : {
        logistics_request__code: detail.code
      }
    };
    this.workOrderItems = {
      proviFilters : {
        logistics_request__code: detail.code,
        product__code__in: this.proviProductsList
      },
      expedFilters : {
        logistics_request__code: detail.code,
        metadata__shipment_type: 'SEND',
        product__code: ['P-EXPED']
      },
      proviDisabledColumns: {
        actions: true,
        work_order__order__code: true,
        contact: true,
        quantity: false
      },
      expedDisabledColumns: {
        actions: true,
        modified_at: false,
        due_date: true,
        assignee: true,
        metadata__ups_state: false,
        metadata__shipment_ref: false,
        metadata__service_type: false,
        tags: true,
        work_order__order__code: true,
        contact: true,
      },
      disabledButtons: {
        create: true
      }
    };
    this.equipment = {
      filters: {
        logistics_requests__in: this.detail.code,
      },
      disabledColumns: {
        entity__name: true,
        provider_order_number__or__provider_order__order_number: true,
        last_stock_activity: true,
        owner: true,
        has_accounting_equipment: true,
        tags: true,
        mac_address: false
      },
      disabledButtons: {
        exactSearch: true,
        create: true,
        export: true,
        assign: false,
        delete : true,
        printDeliveryNote: false
      }
    };
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/logistics-requests/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  private _findEntityActiveLogisticsRequests(entityCode): void {
    const filters = {
      site__code: entityCode,
      state__nin: ['cancelled', 'done']
    };
    this.apiShiva.logistics_requests.list(filters)
      .then(res => {
        this.hasActiveLogisticsRequests = res['count'] > 0;
      }, err => {
        console.error(err);
      });
  }


  // ------------------------------------------------------------------------
  // Websockets related functions for logistics-request refresh if woi updates
  // ------------------------------------------------------------------------

  private _initLiveWoiUpdate(): void {
    // defining the throttled refresh function
    this.debouncedFetch = debounce(() => {
      this._fetch();
    }, 2000);

    // we must store the generated bound function otherwise the unsubscribe will not recognize it
    // because the .bind generate a new anonymous function that is not equal (== or ===) to the binded function
    this.boundWoiWsCallback = this._handleWoiWsMsg.bind(this);

    // formatting properly the channel name
    this.liveUpdateWoiChannel = this.websocketService.cleanChanName('work-order-item');
    this.websocketService.subscribe([this.liveUpdateWoiChannel], this.boundWoiWsCallback);
  }

  private _handleWoiWsMsg(obj): void {
    // checking if the message is for our channel
    if (obj.channel !== this.liveUpdateWoiChannel) {
      return;
    }
    // checking if the woi updated is part of our logistics-request
    if (this.detail.work_order_items && obj.data) {
      const woiFiltered = this.detail.work_order_items.filter((woi: any) => woi.id === obj.data.id);
      if (woiFiltered.length > 0) {
        this.debouncedFetch();
      }
    } else {
      return;
    }
  }

  private _stopLiveWoiUpdate(): void {
    // unsubscribing from this specific channel
    this.websocketService.unsubscribe([this.liveUpdateWoiChannel]);
    // and removing our callback from the webcksocket onMessage callback list
    this.websocketService.removeCallback(this.boundWoiWsCallback);
  }

  private _initFIlters(): void {
    this.equipment = {
      filters: {
        logistics_requests__in: this.detail.code,
      },
      disabledColumns: {
        entity__name: true,
        provider_order_number__or__provider_order__order_number: true,
        last_stock_activity: true,
        owner: true,
        has_accounting_equipment: true,
        tags: true,
        mac_address: false
      },
      disabledButtons: {
        exactSearch: true,
        create: true,
        export: true,
        assign: false,
        delete : true,
        printDeliveryNote: false
      }
    };
  }
}
