<div class="modal-header">
  <h4 class="modal-title">Modifier avant impression</h4>
</div>
<div class="modal-body" id="modal-body">
  <div class="form-horizontal" style="margin-top: 10px;">
    <div class="form-group">
      <label class="col-sm-2 control-label">Entité</label>
      <div class="col-sm-4">
        <input class="form-control" maxlength="24"
               name="entityCode" [(ngModel)]="labelObject.labels_data.entity_code" />
      </div>
      <label class="col-sm-2 control-label">Parent</label>
      <div class="col-sm-4">
        <input class="form-control" maxlength="24"
               name="parentName" [(ngModel)]="labelObject.labels_data.parent_name" />
      </div>
    </div>
    <div class="form-group">
      <label class="col-sm-2 control-label">Ref. Client</label>
      <div class="col-sm-4">
        <input class="form-control" maxlength="24"
               name="customerRef" [(ngModel)]="labelObject.labels_data.customer_ref" />
      </div>
      <label class="col-sm-2 control-label">Code LR</label>
      <div class="col-sm-4">
        <input class="form-control" maxlength="24" [disabled]="true"
               name="lrCode" [(ngModel)]="labelObject.labels_data.lr_code" />
      </div>
    </div>
    <br>

    <table class="table">
      <thead>
        <tr>
          <th width="200px">Nom Équipement</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of labelObject.labels_data.labels; let index = index;">
          <td>
            <input class="form-control" maxlength="24"
                   name="{{'eqpName_' + index}}" [(ngModel)]="item.equipment_name" />
          </td>
        </tr>
      </tbody>
    </table>
    <div class="form-group">
      <div class="col-sm-4">
        <input class="form-control" maxlength="3"
               name="number_print" [(ngModel)]="labelObject.number_print"/>
      </div>
      <label class="col-sm-2 control-label">exemplaire(s)</label>
    </div>
  </div>
</div>
<div class="modal-footer">
  <button class="btn btn-primary" (click)="modal.close(labelObject)">Valider</button>
  <button class="btn btn-default" (click)="modal.dismiss()">Annuler</button>
</div>