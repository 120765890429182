<div class="wcm-erp modal-view">


  <app-detail-header [breadcrumbsData]="breadcrumbsData">
    <ng-template appDetailHeaderRight>
      <div *ngIf="mode === 'normal'">

        <a routerLink="/work-order-items/list" [queryParams]="{work_order__order__code: detail.code}"
            class="btn btn-link" *ngIf="!financeMode && detail.code">
          Tâches associées
        </a>

        <a href="http://wgp.waycom.net/quote/show/id/{{detail.wgp_id}}#2:item-predefined-lines"
           class="btn btn-transparent" style="margin-right: 5px;" target="_blank" role="button"
           *ngIf="detail.wgp_id">
          <i class="fa fa-external-link-alt"></i> WGP
        </a>

        <button type="button" class="btn btn-default" [disabled]="loadingDirectInvoice" (click)="directInvoicing()"
                *ngIf="financeMode && detail.code && detail.state?.name !== 'cancelled' && hasPermissions('Alexia:FinanceCreateInvoice')">
          Facturer
        </button>

        <button type="button" class="btn btn-default" *ngIf="(mode === 'normal') && !financeMode"
                (click)="associateOrder()">
          Ajouter une tâche
        </button>

        <app-notification-dropdown *ngIf="detail.id" [pk]="detail.id"
                                    model="Order" style="margin-right: 5px;">
        </app-notification-dropdown>

        <button type="button" class="btn btn-default" (click)="edit()">
          Modifier
        </button>
      </div>
      <div *ngIf="mode === 'edition'">
        <button type="button" class="btn btn-primary" (click)="save()"
                [disabled]="f.invalid || loading" *ngIf="!detail.code">
          Créer
        </button>

        <ng-container *ngIf="itemNotInSync; else itemInSyncTemplate">
          <button type="button" class="btn btn-primary" (click)="cancelEditionAndFetch()"
                  *ngIf="detail.code">
            Annuler et mettre à jour
          </button>
        </ng-container>

        <ng-template #itemInSyncTemplate>
          <button type="button" class="btn btn-primary" (click)="save()"
                  [disabled]="f.invalid || loading" *ngIf="detail.code">
            Enregistrer
          </button>

          <button type="button" class="btn btn-default" (click)="cancel()"
                  *ngIf="detail.code">
            Annuler
          </button>
        </ng-template>
      </div> <!-- /edition mode -->
    </ng-template>
  </app-detail-header>

  <div class="flex">
    <div class="flex-left">
    </div>
    <div class="flex-right">
      <div class="mode-switch-wrapper">
        <span [ngClass]="{'mode-switch-on': !financeMode, 'mode-switch-off': financeMode}" (click)="onModeSwitch('orders-mrp')">Vue production</span>
        <p-inputSwitch [(ngModel)]="modeSwitch" (ngModelChange)="onModeSwitch()" [disabled]="!hasPermissions('Alexia:FinanceOrder')" styleClass="mode-switch"></p-inputSwitch>
        <span [ngClass]="{'mode-switch-on': financeMode, 'mode-switch-off': !financeMode, 'mode-disabled': !hasPermissions('Alexia:FinanceOrder')}" (click)="onModeSwitch('orders')">Vue finance</span>
      </div>
    </div>
  </div>

  <form class="form-horizontal" #f="ngForm" (ngSubmit)="save()">
    <!-- this submit button is here to trigger the submit when the user presses the enter key -->
    <!-- the form (ngSubmit) function will be in charge of handling the call -->
    <button type="submit" [hidden]="true"></button>

    <div class="alert alert-info" role="alert" *ngIf="directInvoiceCode">
      Un brouillon de facture directe (<a routerLink="/invoices/detail/{{directInvoiceCode}}">{{directInvoiceCode}}</a>) existe pour cette commande.
    </div>

    <div class="alert alert-danger alert-sm" *ngIf="detail.id && incompleteData">
      <span>Données de la commande incomplètes :<br></span>
      <ul>
        <li *ngIf="!detail.company?.id">Société manquante</li>
        <li *ngIf="!detail.payment_method?.id">Méthode de paiement manquante</li>
        <li *ngIf="!detail.payment_term?.id">Modalités de paiement manquantes</li>
      </ul>
    </div>

    <div class="alert alert-danger alert-sm" *ngIf="detail.procurement_error">
      Erreur pendant le procurement de cette commande, veuillez corriger le probleme et re-jouer le procurement: <br><br>
      <span style="white-space: pre-line;">{{detail.procurement_error}}</span>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        N° de commande
      </label>
      <div class="col-sm-4">
        <input class="form-control" disabled
                name="number" [(ngModel)]="detail.number" />
      </div>

      <label class="col-sm-2 control-label">
        Créée le
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.created_at | amDateFormat:'L'}}
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        N° devis
      </label>
      <div class="col-sm-4">
        <p class="form-control-static">
          {{detail.quote?.number}}
          <a href="{{salesforceBaseUrl}}/{{detail.salesforce_id}}" *ngIf="detail.salesforce_id" target="_blank">
            <i class="fa fa-external-link-alt"></i> {{detail.internal_ref}}
          </a>
        </p>
      </div>

      <label class="col-sm-2 control-label">
        Fin prévue le
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.provisional_end_date" name="provisionalEndDate"
                        serializationFormat="YYYY-MM-DDTHH:mm:ssZ"
                        [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Réf client (Commande)
      </label>
      <div class="col-sm-4">
        <app-input-list-field [(ngModel)]="detail.customer_ref" name="customer_ref"
                              *ngIf="existingRefs" [disabled]="mode === 'normal' || !financeMode"
                              [list]="existingRefs">
        </app-input-list-field>
      </div>

      <label class="col-sm-2 control-label">
        Rappel le
      </label>
      <div class="col-sm-4">
        <app-date-field [(ngModel)]="detail.reminder_date" name="reminderDate"
                        serializationFormat="YYYY-MM-DDTHH:mm:ssZ"
                        [mediumSize]="true" [disabled]="mode === 'normal'">
        </app-date-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Tags
      </label>
      <div class="col-sm-4">
        <app-tags-field [disabled]="true" name="tags" [(ngModel)]="detail.tags">
        </app-tags-field>
      </div>

      <label class="col-sm-2 control-label" *ngIf="!financeMode">
        Progression
      </label>
      <div class="col-sm-4" *ngIf="!financeMode">
        <div class="progress-bar-text-center">
          <div class="progress">
            <ngb-progressbar [type]="detail.woi_done_count === detail.woi_count ? 'success' : 'default'"
                    [striped]="detail.woi_done_count !== detail.woi_count"

                    [value]="detail.woi_done_count" [max]="detail.woi_count">
              <div class="progress-bar-text">{{detail.woi_done_count}} / {{detail.woi_count}}</div>
            </ngb-progressbar>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-offset-1 col-sm-9">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Projet
      </label>
      <div class="col-sm-4">
        <app-project-field [(ngModel)]="detail.project" name="project"
                           [filters]='{"entities_or_children__code": detail.entity?.code}'
                           [disabled]="mode === 'normal'" (ngModelChange)="onProjectUpdate()">
        </app-project-field>
      </div>
      <label class="col-sm-2 control-label">
        Pilote de commande
      </label>
      <div class="col-sm-4">
        <app-user-field [(ngModel)]="detail.assignee" name="assignee"
                        [disabled]="mode === 'normal'">
        </app-user-field>
      </div>
    </div>

    <div class="row">
      <div class="col-sm-offset-1 col-sm-9">
        <hr>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Entité de facturation <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-entity-field class="wcm-highlight-req" [(ngModel)]="detail.entity" name="entity"
                          [filters]="entityFieldInherit.filters" [disabledColumns]="entityFieldInherit.disabledColumns"
                          [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:OrderCanEditClient') || !financeMode"
                          [required]="true" (ngModelChange)="onEntityUpdate()">
        </app-entity-field>
      </div>

      <label class="col-sm-2 control-label">
        Commercial
      </label>
      <div class="col-sm-4">
        <p class="form-control-static align-form-control">
          <app-wcm-user [username]="detail.salesperson"></app-wcm-user>
        </p>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Adresse de facturation
      </label>
      <div class="col-sm-4">
        <app-location-field class="wcm-highlight-req" [disabled]="true" [required]="true"
                            name="location" [ngModel]="detail.entity?.location"
                            [filters]="{source__nin: 'orch'}">
        </app-location-field>
      </div>

      <label class="col-sm-2 control-label">
        Statut
      </label>
      <div class="col-sm-4">
        <app-state-field model="order" [pk]="detail.id" [state]="detail.state">
        </app-state-field>
      </div>
    </div>

    <div class="form-group">
      <label class="col-sm-2 control-label">
        Langue de facturation <i class="required">*</i>
      </label>
      <div class="col-sm-4">
        <app-language-field [required]="true" [disableEmptyValue]="true" [disabled]="mode === 'normal' || !financeMode"
                            [(ngModel)]="detail.language_affinity" name="languageAffinity">
        </app-language-field>
      </div>
    </div>

    <ng-container *ngIf="detail.code">
      <ul ngbNav #nav="ngbNav" class="nav-tabs nav-pills" [destroyOnHide]="false" (activeIdChange)="onTabChange($event)" [activeId]="activeTab">
        <li [ngbNavItem]="'comments'">
          <a ngbNavLink>Discussion <span class="badge primary" *ngIf="commentsCount">{{commentsCount}}</span></a>
          <ng-template ngbNavContent>
            <app-comments model="order" [autoRefresh]="true" [pk]="detail.id"></app-comments>
          </ng-template>
        </li>
        <li [ngbNavItem]="0">
          <a ngbNavLink>Détail</a>
          <ng-template ngbNavContent>
            <div ngModelGroup="detailTab">
              <div class="form-group">
                <label class="col-sm-3 control-label">
                  Durée d'engagement
                </label>
                <div class="col-sm-3">
                  <p class="form-control-static">
                    {{detail.commitment}} mois
                  </p>
                </div>

                <label class="col-sm-3 control-label">
                  Préavis de résiliation
                </label>
                <div class="col-sm-3">
                  <p class="form-control-static">
                    {{detail.termination}} mois
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  Délai de livraison
                </label>
                <div class="col-sm-3">
                  <p class="form-control-static">
                    {{detail.lead_time}}
                  </p>
                </div>

                <label class="col-sm-3 control-label">
                  Échéance
                </label>
                <div class="col-sm-3">
                  <p class="form-control-static">
                    {{detail.due_date | amDateFormat:'L'}}
                  </p>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label">
                  Commentaire
                </label>
                <div class="col-sm-6">
                  <p class="form-control-static">
                  {{ detail.comment }}
                  </p>
                </div>
              </div>
              <app-orders-detail-groups-list *ngIf="tabsStatus[0] && groupList"
                                             [mode]="mode"
                                             [state]="detail.state.name"
                                             [orderCode]="detail.code"
                                             [filters]="groupList.filters"
                                             [disabledColumns]="groupList.disabledColumns"
                                             [defaults]="groupList.defaults"
                                             [entity]="groupList.entity"
                                             [type]="groupList.type"
                                             (entityOrLocationUpdated)="onGroupEntityOrLocationUpdated()"
                                             (groupRemoved)="onGroupRemoved()">
              </app-orders-detail-groups-list>
              <div *ngIf="financeMode">
                <div class="form-group">
                  <div class="col-sm-3 col-sm-offset-6">
                    <p class="form-control-static text-center">
                      <strong>FA</strong>
                    </p>
                  </div>

                  <div class="col-sm-3">
                    <p class="form-control-static text-center">
                      <strong>FR</strong>
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-3 col-sm-offset-3 control-label">
                    Total HT
                  </label>

                  <div class="col-sm-3">
                    <p class="form-control-static text-right">
                      {{detail.setup_total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
                    </p>
                  </div>

                  <div class="col-sm-3">
                    <p class="form-control-static text-right">
                      {{detail.recurring_total_untaxed | currency:'EUR':'symbol':'1.2-4'}}
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-3 col-sm-offset-3 control-label">
                    Taxes
                  </label>

                  <div class="col-sm-3">
                    <p class="form-control-static text-right">
                      {{detail.setup_total_taxes | currency:'EUR':'symbol':'1.2-4'}}
                    </p>
                  </div>

                  <div class="col-sm-3">
                    <p class="form-control-static text-right">
                      {{detail.recurring_total_taxes | currency:'EUR':'symbol':'1.2-4'}}
                    </p>
                  </div>
                </div>

                <div class="form-group">
                  <label class="col-sm-3 col-sm-offset-3 control-label">
                    Total TTC
                  </label>

                  <div class="col-sm-3">
                    <p class="form-control-static text-right">
                      {{detail.setup_total | currency:'EUR':'symbol':'1.2-4'}}
                    </p>
                  </div>

                  <div class="col-sm-3">
                    <p class="form-control-static text-right">
                      {{detail.recurring_total | currency:'EUR':'symbol':'1.2-4'}}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="1" *ngIf="financeMode">
          <a ngbNavLink>Finance</a>
          <ng-template ngbNavContent>
            <div class="alert alert-danger" *ngIf="companyMismatch">
              La société de la commande ne correspond pas à celle du régime fiscal.
            </div>
            <div ngModelGroup="financeTab">
              <div class="form-group">
                <label class="col-sm-3 control-label" data-toggle="tooltip" data-placement="left"
                       title="Écraser la valeur par défaut: {{detail.entity?.company_affinity?.name || 'aucune'}}">
                  Société <i class="fa fa-info-circle"></i>
                </label>
                <div class="col-sm-3">
                  <app-company-field class="wcm-highlight-req" [required]="true"
                                     [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:OrderCanEditCompany')"
                                     [(ngModel)]="detail.company" name="company"
                                     (ngModelChange)="checkCompanyMismatch(); checkIncompleteData()">
                  </app-company-field>
                </div>

                <label class="col-sm-3 control-label" data-toggle="tooltip" data-placement="left"
                       title="Écraser la valeur par défaut: {{detail.entity?.fiscal_position_affinity?.name || 'aucune'}}">
                  Régime fiscal spécifique
                </label>
                <div class="col-sm-3">
                  <app-fiscal-position-field class="wcm-highlight-req" [ngClass]="{'wcm-highlight-error': companyMismatch}"
                                             [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:OrderCanEditCompany')"
                                             [(ngModel)]="detail.fiscal_position" name="fiscal_position"
                                             (ngModelChange)="checkCompanyMismatch()"
                                             [filters]="{company__code: detail.company?.code}">
                  </app-fiscal-position-field>
                </div>
              </div>

              <div class="row" *ngIf="mode === 'edition'">
                <div class="col-sm-offset-1 col-sm-9">
                  <hr>
                </div>
              </div>

              <div class="form-group" *ngIf="mode === 'edition' && (detail.payment_term?.id !== detail.entity?.payment_term_affinity?.id || detail.payment_method?.id !== detail.entity?.payment_method_affinity?.id)">
                <div class="col-sm-12 text-right">
                  <button class="btn btn-danger" (click)="resetPaymentOptions()">
                    Restaurer la configuration par défaut
                  </button>
                </div>
              </div>

              <div class="form-group">
                <label class="col-sm-3 control-label" data-toggle="tooltip" data-placement="left"
                       title="Écraser la valeur par défaut: {{detail.entity?.payment_term_affinity?.name || 'aucune'}}">
                  Modalités de paiement <i class="required">*</i>&nbsp;<i class="fa fa-info-circle"></i>
                </label>
                <div class="col-sm-3">
                  <app-payment-term-field class="wcm-highlight-req" [required]="true" [(ngModel)]="detail.payment_term" name="payment_term"
                                          [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:OrderCanEditPaymentConfiguration')"
                                          (ngModelChange)="checkIncompleteData()">
                  </app-payment-term-field>
                </div>

                <label class="col-sm-3 control-label" data-toggle="tooltip" data-placement="left"
                       title="Écraser la valeur par défaut: {{detail.entity?.payment_method_affinity?.name || 'aucune'}}">
                  Méthode de paiement <i class="required">*</i>&nbsp;<i class="fa fa-info-circle"></i>
                </label>
                <div class="col-sm-3">
                  <app-payment-method-field class="wcm-highlight-req" [required]="true" [(ngModel)]="detail.payment_method" name="payment_method"
                                            [disabled]="mode === 'normal' || !hasPermissions('ShivaBackend:OrderCanEditPaymentConfiguration')"
                                            (ngModelChange)="checkIncompleteData()">
                  </app-payment-method-field>
                </div>
              </div>

              <hr *ngIf="mode === 'edition'" />

              <div class="form-group">
                <label class="col-sm-3 control-label" data-toggle="tooltip" data-placement="left"
                       title="Écraser la valeur par défaut: {{detail.entity?.currency_affinity?.name || 'aucune'}}">
                  Devise <i class="fa fa-info-circle"></i>
                </label>
                <div class="col-sm-3">
                  <app-currency-field [disabled]="mode === 'normal' || detail.state?.name !== 'new'"
                                      [(ngModel)]="detail.currency" name="currency">
                  </app-currency-field>
                </div>

                <div *ngIf="detail.currency && detail.currency?.code !== 'EUR'">
                  <label class="col-sm-3 control-label">
                    Taux de change <i class="required">*</i>
                  </label>
                  <div class="col-sm-3">
                    <app-decimal-field class="wcm-highlight-req" [required]="true"
                                       [disabled]="mode === 'normal' || !hasPermissions('EvaBackend:OrderCanEdit')"
                                      pattern="^[0-9]*[.,]?[0-9]*$" [(ngModel)]="detail.exchange_rate" name="exchange_rate">
                    </app-decimal-field>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]="2" *ngIf="financeMode && detail.code && hasPermissions('Alexia:FinanceInvoice')">
          <a ngbNavLink>Factures</a>
          <ng-template ngbNavContent>
            <app-orders-detail-invoices-list [filters]="{order__code: detail.code}" *ngIf="tabsStatus[2]">
            </app-orders-detail-invoices-list>
          </ng-template>
        </li>
        <li [ngbNavItem]="6" *ngIf="!financeMode && detail.code">
          <a ngbNavLink>Ordres de production</a>
          <ng-template ngbNavContent>
            <app-work-orders-list [filters]="workOrders.filters" [disabledColumns]="workOrders.disabledColumns"
                              [disableUrlUpdate]="true" *ngIf="tabsStatus[6]">
            </app-work-orders-list>
          </ng-template>
        </li>
        <li [ngbNavItem]="3" *ngIf="detail.code">
          <a ngbNavLink>Historique des workflow</a>
          <ng-template ngbNavContent>
            <app-workflow-histories-list [id]="detail.id" model="order" *ngIf="tabsStatus[3]">
            </app-workflow-histories-list>
          </ng-template>
        </li>
        <li [ngbNavItem]="4" *ngIf="detail.code">
          <a ngbNavLink>Fichiers <span class="badge primary" *ngIf="attachmentsCount">{{attachmentsCount}}</span></a>
          <ng-template ngbNavContent>
            <app-attachments-list [pk]="detail.id" model="mrp-orders"
                                  (counter)="setAttachmentsCount($event)">
            </app-attachments-list>
          </ng-template>
        </li>
        <li [ngbNavItem]="5" *ngIf="detail.code">
          <a ngbNavLink>Historique</a>
          <ng-template ngbNavContent>
            <app-model-histories-list model="order" [id]="detail.id" *ngIf="tabsStatus[5]">
            </app-model-histories-list>
          </ng-template>
        </li>

      </ul>
      <!-- The tabs content is dislayed inside the outlet -->
      <div [ngbNavOutlet]="nav"></div>
    </ng-container> <!-- /tabs container -->
  </form>
</div>
