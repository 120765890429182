<div [ngTemplateOutlet]="listWithHeaderTmpl"
     [ngTemplateOutletContext]="{ $implicit: taskEquipmentSlots, listContext: 'main' }"
     data-testid="main-scanned-equipment">
</div>

<ng-container *ngIf="ignoredEquipmentSlots.length">
  <div [ngTemplateOutlet]="listWithHeaderTmpl"
       [ngTemplateOutletContext]="{ $implicit: ignoredEquipmentSlots, listContext: 'ignored' }"
       data-testid="ignored-scanned-equipment">
  </div>
</ng-container>

<ng-template #listWithHeaderTmpl let-items let-listContext="listContext">

  <div class="wcm-table-header" data-testid="scanned-equipment-header">
    <nav class="navbar navbar-default">
      <div class="container-fluid">
        <div class="navbar-header">
          <a class="navbar-brand">
            <ng-container *ngIf="listContext === 'main'">
              Equipements de la tâche : {{ scannedEquipmentCount }} / {{ requestedEquipmentCount }}
            </ng-container>
            <ng-container *ngIf="listContext === 'ignored'">
              Equipements ignorés : {{ ignoredEquipmentSlots.length }}
            </ng-container>
          </a>
        </div>
      </div>
    </nav>
  </div>

  <p-table #table
           class="wcm-table scanned-equipment"
           [rows]="items.length"
           [value]="items"
           [reorderableColumns]="true"
           [scrollable]="true"
           (onRowReorder)="rowsReordered($event)"
           data-testid="scanned-equipment-table">

    <ng-template pTemplate="header">
      <tr data-testid="scanned-equipment-columns">
        <th *ngIf="!localDisabledColumns.network_device">ND</th>
        <th>Code</th>
        <th>Modèle</th>
        <th class="no-text-wrap">N° de série</th>
        <th class="no-text-wrap">N° de série interne</th>
        <th>Réservé</th>
        <th *ngIf="!localDisabledColumns.mac_address" class="no-text-wrap">Adresse MAC</th>
        <th>Affectation</th>
        <th style="width: 80px">Statut</th>
        <th style="min-width: 80px"></th>
        <th *ngIf="listContext === 'main' && !localDisabledColumns.print"></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-item let-index="rowIndex">
      <tr [pReorderableRow]="index" class="border {{ item.scan_status }}" data-testid="scanned-equipment-row">
        <td *ngIf="!localDisabledColumns.network_device" class="text-center no-text-wrap">
          <ng-container *ngIf="item.network_device">
            <a routerLink="/network-devices/detail/{{ item.network_device.code }}" target="_blank">
              <i class="fas fa-external-link-alt" aria-hidden="true"></i> {{ item.network_device.code }}
            </a>
            / {{ item.network_device.name }}
          </ng-container>
        </td>

        <td class="no-text-wrap">
          <a *ngIf="item.code" routerLink="/equipments/detail/{{ item.code }}" target="_blank">
            <i class="fas fa-external-link-alt"></i> {{ item.code }}
          </a>
        </td>

        <td class="text-center no-text-wrap">{{ item.model?.name }}</td>
        <td class="text-center no-text-wrap">{{ item.serial_number }}</td>

        <td class="text-center no-text-wrap">
          <div class="text-nowrap">
            {{ item.internal_serial_number | slice: 0: -3 }}
            <span class="font-weight-bold">{{ item.internal_serial_number | slice : -3 }}</span>
          </div>
        </td>

        <td class="text-center no-text-wrap">{{ item.reserved_for?.name }}</td>
        <td *ngIf="!localDisabledColumns.mac_address" class="text-center no-text-wrap">{{ item.mac_address }}</td>
        <td class="text-center no-text-wrap">{{ EQP_LOCATIONS[item.location] }}</td>

        <td class="text-center">
          <ng-container [ngSwitch]="item.scan_status">
            <img *ngSwitchCase="ScanStatusEnum.Success" alt="success" ngSrc="assets/img/icon_check.svg" width="20" height="20" />
            <img *ngSwitchCase="ScanStatusEnum.Warning" alt="warning" ngSrc="assets/img/icon_warning.svg" width="20" height="20" />
            <img *ngSwitchCase="ScanStatusEnum.Error" alt="error" ngSrc="assets/img/icon_error.svg" width="20" height="20" />
            <img *ngSwitchDefault alt="unknown" ngSrc="assets/img/icon_unknown.svg" width="20" height="20" />
          </ng-container>
        </td>

        <td>
          <div class="action-btn-bar">
            <ng-container *ngIf="listContext === 'ignored'; else mainButtons">
              <button type="button" class="btn btn-default btn-xs" (click)="unignoreItem(item)">
                <i class="fa fa-plus" aria-hidden="true"></i>
              </button>
            </ng-container>

            <ng-template #mainButtons>
              <ng-container *ngIf="requestedEquipments.length && !item.scan_status; else deleteAndAlertActionsTmpl">
                <button type="button" class="btn btn-default btn-xs" (click)="ignoreItem(item)">
                  <i class="fa fa-ban" aria-hidden="true"></i>
                </button>
              </ng-container>

              <ng-template #deleteAndAlertActionsTmpl>
                <button *ngIf="item.scan_status && item.error_messages?.length" type="button" class="btn btn-default btn-xs" (click)="showInvalidEquipmentAlert(item)">
                  <i class="fa fa-exclamation-triangle"></i>
                </button>
                <button *ngIf="item.scan_status && !disabled" type="button" class="btn btn-default btn-xs" (click)="deleteItem(item)">
                  <i class="fa fa-trash-alt"></i>
                </button>
              </ng-template>
            </ng-template>
          </div>
        </td>

        <td *ngIf="listContext === 'main' && !localDisabledColumns.print">
          <ng-container *ngIf="item.scan_status">
            <app-print-label-button [buildPrintData]="buildPrintDataCallback"
                                    [isSmallBtn]="true"
                                    [detail]="workOrderItem"
                                    [currentEqp]="item">
            </app-print-label-button>
          </ng-container>
        </td>

        <td *ngIf="reorderableRows">
          <span class="pi pi-bars" pReorderableRowHandle></span>
        </td>
      </tr>
    </ng-template>
  </p-table>
</ng-template>
