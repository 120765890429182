import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';

import { SignalsService } from '@core/services/signals.service';
import { BulkPatchTaskCallbackType, ITagBulkPatchResponse, ITagBulkUpdate } from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';

import { TagUpdateSelectionModalComponent } from './tag-update-selection-modal.component';


@Injectable({
  providedIn: 'root',
})
export class TagBulkUpdateService {
  constructor(
    private readonly ngbModal: NgbModal,
    private readonly toastr: ToastrService,
    private readonly signalsService: SignalsService,
    private readonly promisesService: PromisesService,
  ) {
  }

  public selectTagsAndUpdate(modelIds: string[], apiMethod: BulkPatchTaskCallbackType, labelSingular: string, labelPlural?: string): Promise<void> {
    const deferred = this.promisesService.defer();

    const modal = this.ngbModal.open(TagUpdateSelectionModalComponent, { backdrop: 'static' });

    // Make sure the plural label is always defined
    labelPlural = labelPlural || `${labelSingular}s`;

    modal.componentInstance.labelSingular = labelSingular;
    modal.componentInstance.labelPlural = labelPlural;
    modal.componentInstance.selectedCount = modelIds.length;

    modal.result
      .then((data: ITagBulkUpdate) => {
        const taskName = 'Mise à jour des tags en masse';

        apiMethod(modelIds, data.addedTags, data.removedTags, taskName)
          .then((res: ITagBulkPatchResponse) => {
            this.signalsService.broadcastJobStart(taskName, res.job_id);
            this.toastr.success(`La mise à jour des tags des ${labelPlural} a bien été prise en compte.`);
            deferred.resolve();
          })
          .catch(err => {
            this.toastr.error('Impossible de démarrer la mise à jour des tags en masse. Veuillez réessayer.');
            console.error(err);
            deferred.reject(err);
          });
      },
      (error: unknown) => deferred.reject(error),
    );

    return deferred.promise;
  }
}
