import { AfterContentChecked, ChangeDetectorRef, Component, Injector, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import * as moment from 'moment';
import { MarkdownService } from 'ngx-markdown';
import { EditorOption } from 'angular-markdown-editor';
import { Subscription } from 'rxjs';

import { ApiShivaService } from '@core/apis/api-shiva.service';
import { ApiLLPService } from '@core/apis/api-llp.service';
import { GenericDetailComponent } from '@core/globals/generic-detail/generic-detail.component';
import { WcmModalsService } from '@core/globals/wcm-modals/wcm-modals.service';
import { AtLeastOne, isArray, omit } from '@core/helpers';
import {
  IBreadcrumbsData,
  IFilters,
  IInterventionTag,
  IProducts,
  ITinyEntity,
  ITinyEntityWithLocation,
  ITraceabilityWithEntity,
  IWorkOrderItems,
  IWorkOrders,
} from '@core/interfaces';
import { PromisesService } from '@core/services/promises.service';
import { WaycomHttpErrorResponse } from '@core/services/waycom-http-error-response';

import { WorkOrderItemsStateService } from './work-order-items-state.service';
import { ITransitionPayload } from './work-order-items-completion-date-modal.component';

@Component({
  selector: 'app-work-order-items-detail',
  templateUrl: './work-order-items-detail.component.html',
  styleUrls: ['./work-order-items-detail.component.less']
})
export class WorkOrderItemsDetailComponent extends GenericDetailComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('f', {static: true}) public detailForm: NgForm;
  @ViewChild('infoForm', {static: false}) public infoForm: { f: NgForm, metadataForm?: { f: NgForm }};
  @Input() public disableCreateRedirection: boolean;
  @Input() public entityFilters: IFilters;

  public viewName = 'work-order-items';
  public stateConfirmFunctMap: Record<string, (transitionName?: string | undefined) => Promise<unknown>>;
  public stateErrorFuncMap: Record<string, (transitionName?: string | undefined) => Promise<unknown>>;
  public disableState: boolean;
  public provisionalEndMinDate: Date;
  public localLastCommentDateStr: string;
  public productField: IFilters;
  public entityField: IFilters;
  public entityAlerts = [];
  public missingTraceabilityForSDA = false;
  public entityIsBlocked = false;
  public providerField: IFilters;
  public traceabilityField: IFilters;
  public showMetadata = false;
  public woiRelationRate: {done_wois: number, total_wois: number};
  public linkedWoi: IFilters = {};
  public editorOptions: EditorOption;
  public readonly contractorFailOptions = {
    customer: 'Cause Client',
    waycom: 'Cause Waycom',
    contractor: 'Cause Prestataire'
  };
  public workOrderEntityBackup: ITinyEntity;
  public attachmentsCount: number;
  public commentsCount: number;
  public isAbleToDuplicate: boolean;
  public invalidWeight: boolean = false;

  private readonly defaultBreadcrumbsData: IBreadcrumbsData[];
  private readonly listUrl = '#/work-order-items/list/?state__nin=cancelled&' +
            'state__nin=error&state__nin=done&' +
            'work_order__order__state__nin=new&' +
            'work_order__order__state__nin=cancelled';
  private quickEditShorcutFunc: (event: KeyboardEvent) => void;
  // WOIs for products listed here will have their metadata copied when duplicated, not just the entity / contacts etc.
  private productMetadataDuplication = [
    'P-QPEWV'
  ];
  private hasToCancelErdv: boolean;
  private signalSubscriptions: Subscription[] = [];
  private api: ApiShivaService['work_order_items'];

  constructor(
    public injector: Injector,
    private markdownService: MarkdownService,
    private promisesService: PromisesService,
    private apiShiva: ApiShivaService,
    private apiLLPService: ApiLLPService,
    private wcmModalsService: WcmModalsService,
    private workOrderItemsStateService: WorkOrderItemsStateService,
    private router: Router,
    private cdRef: ChangeDetectorRef
  ) {
    super(injector);
    this.defaultBreadcrumbsData =  [{label: 'Tâches', url: this.listUrl}];
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];
    // Default values for creation
    this.detail = {
      processing: 'manual',
      quantity: 1,
      state: {
        name: 'new',
        style: 'primary',
        label: 'Nouveau'
      },
      work_order: {}
    };
    // Api used for fetch, update and create
    this.api = this.apiShiva.work_order_items;
    // This enables the live update (websocket)
    this.liveUpdateChannel = 'workOrderItem';

    this.productField = {
      disabledColumns: {periodicity_affinity: true},
      filters: {for_purchase: 'true'}
    };

    this.providerField = {
      filters: {is_contractor: true, is_active: true},
      disabledColumns: {is_contractor: true}
    };

    this.traceabilityField = {
      disabledColumns: {
        relatedInvoices: true,
        entity__name_or_code: true,
      }
    };
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.editorOptions = {
      additionalButtons: [],
      parser: (val: string): string => {
        // remove xss vulnerabilities
        return this.markdownService.compile(val);
      }
    };

    this.detail = {
      processing: 'manual',
      quantity: 1,
      state: {
        name: 'new',
        style: 'primary',
        label: 'Nouveau'
      },
      work_order: {},
      ...this.detail
    };

    // entityFilters is not defined in the constructor
    this.entityField = {
      disabledButtons: {resetFilters: false},
      filters: this.entityFilters
    };

    this.detail.metadata = (this.defaults && this.defaults.metadata) ? this.defaults.metadata : {};
    this._initInfoTab();
    this._initSubscriptions();
    this._registerQuickEditShorcut();

    //   /!\ If you add a confirm function here, you also have to add it
    //        to stateConfirmFunctMap in work-order-items-list
    this.stateConfirmFunctMap = {
      'start': this._sendEmail.bind(this),
      'waiting-check-network': this._waitingCheckNetworkStateTransition.bind(this),
      'in-progress': this._sendEmail.bind(this),
      'first-recovery': this._sendEmail.bind(this),
      'last-recovery': this._sendEmail.bind(this),
      'finish': this._setCompletionDateOnFinish.bind(this),
      'order': this._orderStateTransition.bind(this),
      'in-progress-to-validate': this._interventionTagSelector.bind(this),
      'in-progress-validate-to-validate': this._interventionTagSelector.bind(this),
      'in-progress-validate-to-done-ko': this._setCauseKo.bind(this),
      'in-progress-validate-to-in-progress': this._setInProgress.bind(this),
      'done-ko': this._setCauseKo.bind(this),
      'done': this._doneStateTransition.bind(this),
      'in-progress-validate-to-done': this._setCompletionDateOnFinish.bind(this),
      'cancel': this._cancelStateTransition.bind(this),
      'ordered-to-waiting-client': this._orderedToWaitingClient.bind(this),
      'waiting-client-to-ordered': this._waitingClientToOrdered.bind(this),
    };

    this.stateErrorFuncMap = {
      'first-recovery': this._handleEmailError.bind(this),
      'last-recovery': this._handleEmailError.bind(this),
    };

    // allow the save and refresh method to be called from other components
    this.save = this.save.bind(this);
    this.refresh = this.refresh.bind(this);
  }

  public weightPackageValidation(event: { invalid: boolean, weightValue: number }){
    this.invalidWeight = event.invalid;
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
    this.signalSubscriptions.forEach(sub => sub.unsubscribe());
    this._cancelQuickEditShortcut();
  }

  public ngAfterContentChecked(): void {
    this.cdRef.detectChanges();
  }

  public edit(): void {
    super.edit();
    this.workOrderEntityBackup = this.detail?.work_order?.entity ? {...this.detail.work_order.entity} : null;
    this.signalsService.broadcast('technician-list-refresh', this.detail.contractor);
  }

  public cancel(): void {
    super.cancel();
    if (this.detail.work_order) {
      this.detail.work_order.entity = this.workOrderEntityBackup;
    }
    if (this?.defaults?.is_duplication)
    {
      // we need to clean old data from duplication to not import them on creation of a new woi
      this.defaults.is_duplication = false;
      this.defaults.title = '';
      this.defaults.product = null;
      this.defaults.product_config = null;
      this.defaults.processing = 'manual';
      this.defaults.old_woi_id = '';
      this.defaults.work_order = {};
    }
    this.signalsService.broadcast('woi-edition-cancelled');
  }

  public onStateUpdate(): void {
    const templateName = this.detail?.metadata_spec?.template_name;
    if (templateName ===  'generic_line') {
      this.signalsService.broadcast('generic-line-metadata-refresh');
    } else if (templateName === 'sfr_ftth_line') {
      this.signalsService.broadcast('sfr-ftth-metadata-refresh');
    } else if (templateName ===  'adsl_line' && this.detail.state.name === 'cancelled') {
      this._cancelErdv();
    } else if (templateName ===  'pmerak' && this.detail.state.name === 'in-progress') {
      this.signalsService.broadcast('pmerak-metadata-refresh');
    } else if (templateName ===  'pmamer') {
        this.signalsService.broadcast('pmamer-metadata-refresh');
    } else if (templateName ===  'pprovi' && this.detail.state.name === 'accepted-logistics') {
      this.signalsService.broadcast('provi-metadata-refresh');
    } else if (templateName ===  'poprov' && this.detail.state.name === 'in-progress') {
      this.signalsService.broadcast('poprov-metadata-refresh');
    } else if (templateName ===  'pmanul' && this.detail.state.name === 'in-progress') {
      this.signalsService.broadcast('notification-list:refresh');
      this.signalsService.broadcast('pmanul-metadata-refresh');
    } else if (templateName === 'pflash' && this.detail.state.name === 'in-progress') {
      this.signalsService.broadcast('pflash-metadata-refresh');
    } else if (templateName === 'provi' && this.detail.state.name === 'done-manual') {
      this.apiShiva.work_order_items.relations_list(this.detail.id)
        .then((res) => {
          const woi_found = res['siblings'].find(woi => woi.product.code === 'P-MANUL');
          this.router.navigateByUrl('/work-order-items/detail/' + woi_found.code);
        });
    } else if (templateName === 'pmerak' && this.detail.state.name === 'done-manual') {
      this.apiShiva.work_order_items.relations_list(this.detail.id)
        .then((res) => {
          const woi_found = res['siblings'].find(woi => woi.product.code === 'P-MAMER');
          this.router.navigateByUrl('/work-order-items/detail/' + woi_found.code);
        });
    }
  }

  public onChangeProvisionalDate(provisionalStartDate: string): void {
    if (!provisionalStartDate) { return; }

    const serializationFormat = 'YYYY-MM-DDTHH:mm:ssZ';
    const startDate = this.detail.provisional_start_date;

    // Update the provisional_end_date min date bound
    this.provisionalEndMinDate = startDate ? moment(startDate, serializationFormat).toDate() : null;

    // If the value was already set, we check if it satisfies the in bound
    if (this.provisionalEndMinDate && this.detail.provisional_end_date) {
      const momentEndDate = moment(this.detail.provisional_end_date, serializationFormat);
      const momentStartDate = moment(this.detail.provisional_start_date, serializationFormat);

      if (momentStartDate.isAfter(momentEndDate)) {
        this.detail.provisional_end_date = null;
      }
    }
  }

  public onChangeContractor(): void {
    this.signalsService.broadcast('technician-list-refresh', this.detail.contractor);
    delete this.detail.metadata.technician;
  }

  public onChangeFullName(event: string): void {
    const fullNameCapitalized = event.split(' ')[0] + ' ' + event.split(' ')[1].toUpperCase();
    this.detail.metadata.assignee_full_name = fullNameCapitalized;
  }

  public productUpdated(newProduct: IProducts): void {
    // only clearing the existing tags and setting the product's ones if we are creating a new woi
    if (newProduct && !this.detail.code) {
      this.detail.tags = newProduct.tags;
    }

    if (newProduct) {
      // updating the woi valorization based on the product valorization
      this.detail.valorization_value = newProduct.valorization_value;
    }

    this.detail.processing = (newProduct && newProduct.procurement_method === 'auto') ? 'automatic' : 'manual';

    if (!this.detail.id) {
      // we are currently creating a new WOI,
      // so we accept to update the metadata spec when the product change
      // checking if the product is the same as the one given with the default values
      if (newProduct && this.defaults?.product?.code === newProduct.code) {
        // if it's the same, we set the `defaults` metadata if there are any
        this.detail.metadata = this.defaults.metadata || {};
      } else {
        this.detail.metadata = {};
      }
      // Copying the metadata spec from the product to the woi and enabling the info tab if necessary
      this._initInfoTab();
    }
  }

  public onEntityUpdate(newVal: ITinyEntityWithLocation, oldVal?: ITinyEntityWithLocation): void {
    if (this.detail.work_order) {
      this.detail.work_order.entity = newVal;
    }
    this.detail.location = newVal ? newVal.location : null;

    // specific case for the provi metadata: we reset the network device code when the entity code changes
    const productCode = this.detail?.product?.code;

    if (newVal !== oldVal) {
      // this is used by p-orfop / p-opc2e, for example to erase some value when entity change
      if (newVal) {
        this.signalsService.broadcast('woi-entity-change', newVal);
      } else {
        this.signalsService.broadcast('woi-entity-change');
      }

      if (productCode === 'P-PROVI') {
        this.detail.metadata = {
          ...this.detail.metadata,
          network_device: {code: null},
        };
        // sending a signal to the provi metadata component to let it refresh its data linked to the ND code
        this.signalsService.broadcast('provi-metadata-refresh');
      }
      this._getEntityAlerts();
    }
  }

  public traceabilityUpdated(newVal: ITraceabilityWithEntity): void {
    if (newVal && newVal.entity && this.detail.work_order && !this.detail.work_order.entity) {
      this.detail.work_order.entity = newVal.entity;
      this.onEntityUpdate(newVal.entity, null);
    }
  }

  public cancelAndDuplicate(item: IWorkOrderItems): void {
    const title = 'Annuler et dupliquer la tâche';
    const msg = `
      Vous êtes sur le point d'annuler cette tâche et d'en créer une nouvelle.<br>
      Le produit sera alors éditable.<br>
      Confirmez-vous l'opération ?
    `;

    // set cancelTask as True because we want the task to be cancelled here.
    const cancelTask = true;
    this._showDuplicateTaskDialog(title, msg, item, cancelTask);
  }

  public duplicate(item: IWorkOrderItems): void {
    const title = 'Dupliquer la tâche';
    const msg = `
      Vous êtes sur le point de créer une nouvelle tâche à partir de celle-ci.<br>
      Le produit sera alors éditable.<br>
      Confirmez-vous l'opération ?
    `;

    this._showDuplicateTaskDialog(title, msg, item);
  }

  public setAttachmentsCount(event: number): void {
    this.attachmentsCount = event;
  }

  public cancelOrder(): void {
    this.wcmModalsService.confirm(
      'Annulation',
      'Voulez-vous annuler la commande chez Orange ?',
      'Oui',
      'Non',
    )
      .then(() => this._cancelOrder())
      .catch(() => {});
  }

  private _cancelOrder(): void {
    this.api.cancelOrder(this.detail.id)
      .then(() => {
        this.toastr.success(`Tâche d'annulation de commande créée avec succès, elle se trouve dans l'onglet Relations.`);
      })
      .catch((error: unknown) => {
        if (error instanceof WaycomHttpErrorResponse) {
          this.toastr.error(error.message);
        } else {
          Promise.reject(error);
        }
      });
  }

  public save(bypassFormCheck = false): Promise<unknown> {
    if (!bypassFormCheck && (this.detailForm && this.detailForm.invalid)) {
      const deferred = this.promisesService.defer();
      deferred.reject();
      return deferred.promise;
    }
    this.loading = true;

    if (this.hasToCancelErdv) { this._cancelErdv(); }

    const payload = {
      ...this.detail,
      note: this.detail.note || '',
      valorization_value: this.detail.valorization_value || null,
    };

    // never update the metadata_spec, it comes from the product
    delete payload.metadata_spec;

    let promise: Promise<unknown>;
    if (this.defaults?.is_duplication) {
      promise = this.api.duplicate(this.defaults.old_woi_id, payload);
      this.defaults.is_duplication = false;
    } else if (this.detail.code) {
      promise = this.api.update(this.detail.code, payload);
    } else {
      promise = this.api.create(payload);
    }

    promise
      .then((res: any) => {
        this.mode = 'normal';
        this.modeChanged.emit(this.mode);
        if (!this.detail.code) {
          // it was a creation
          // this prevents redirecting after a creation in a modal
          if (!this.disableCreateRedirection) {
            const codes = res['code'] || res.map(item => item.code).join(',');
            this.router.navigateByUrl(`/work-order-items/list/?code__in=${codes}`);
          }
        }
        // In case of creation res is an array of all the created woi (procurment)
        this.detail = isArray(res) ? res[0] : res;
        this._initTabs();
        this._updateBreadcrumbs();
        this.detailSaved.emit(this.detail);
        this.signalsService.broadcast('model-history-list-refresh');
        this.refresh();
      })
      .catch(err => {
        if (err instanceof WaycomHttpErrorResponse) {
          if (err.getFirstErrorMessage() === 'PROCUREMENT_IGNORE') {
            this.toastr.error(`La nomenclature du produit sélectionné ne permet pas la création de tâche.`);
            return;
          }
        }
        Promise.reject(err);
      })
      .finally(() => this.loading = false);

    return promise;
  }

  public refresh(): void {
    this._fetch();
  }

  protected _fetch(): void {
    this.loading = true;
    this.api.detail(this.pk)
      .then(res => {
        this.detail = res;
        this._updateBreadcrumbs();
        this._initTabs();
        this._getEntityAlerts();
        this._getMissingTraceabilityForSDA();
      })
      .catch(err => Promise.reject(err))
      .finally(() => this.loading = false);
  }

  private _showDuplicateTaskDialog(title, msg, item, cancelTask = false): void {
    // allows to duplicate but also cancel a task if needeed
    this.wcmModalsService.confirm(title, msg, 'Confirmer', 'Annuler')
      .then(() => {
        const excludedFields: AtLeastOne<string> = [
          'code', 'id', 'tags', 'valorization_value', 'created_by', 'date', 'modified_at', 'work_order'
        ];

        // If our product is not listed in productMetadataDuplication, we ignore the metadata for the duplication
        if (!this.productMetadataDuplication.includes(this.detail.product.code)) {
          excludedFields.push('metadata');
        }

        const defaults: Partial<IWorkOrderItems> = omit(
          this.detail,
          ...excludedFields
        );

        // TODO This isn't clean because work_order should have an id and a code (according to the interface)
        defaults.work_order = {
          id: this.detail.work_order?.id,
          code: this.detail.work_order?.code,
          entity: this.detail.work_order?.entity,
        } as IWorkOrders;
        defaults.old_woi_id = this.detail['id'];

        let commentMsg = 'Cette tâche a été dupliquée.';
        if (cancelTask) {
          this.apiShiva.transition('work-order-item', item.id, 'cancel')
            .then(() => {
              commentMsg = 'Cette tâche a été annulée et dupliquée.';
              this._commentAndDuplicate(defaults, commentMsg);
            });
        } else {
          this._commentAndDuplicate(defaults, commentMsg);
        }
      },
      () => {});
  }

  private _commentAndDuplicate(defaults: Partial<IWorkOrderItems>, commentMsg: string): void {
    // post a comment on the cancelled woi then send the duplicate signal to refresh the view with a new woi.
    // we don't care about the promise result because now that we have cancelled the woi, we must duplicate it,
    // even if the comments couldn't be created
    this.apiShiva.comments('work-order-item', this.detail.id).create(commentMsg);
    this.signalsService.broadcast('work-order-items:duplicate', defaults);
  }

  /**
   * initialise the various signal subscriptions and add them to the list to be unsubscribed on ngOnDestroy()
   */
  private _initSubscriptions(): void {
    this._registerCommentSignals();
    this._registerWorkflowignal();
    this._registerRefreshSignal();
    this._registerErdvSignal();
  }

  private _registerCommentSignals(): void {
    const subCreated = this.signalsService.subscribe('comments:created', createdComment => this.localLastCommentDateStr = createdComment.date);
    this.signalSubscriptions.push(subCreated);

    const subCount = this.signalsService.subscribe('comments:count', count => this.commentsCount = count);
    this.signalSubscriptions.push(subCount);
  }

  private _registerWorkflowignal(): void {
    const sub = this.signalsService.subscribe('workflow:updated:work-order-item', () => {
      // refreshing the woi after the transition has been played because
      // the tags, assignee or other fields may have been updated by the transition
      this._fetch();
      // refresh certain tabs' contents following workflow advancement
      this.signalsService.broadcast('woi-relations-list-refresh');
      this.signalsService.broadcast('workflow-histories-list-refresh');
    });
    this.signalSubscriptions.push(sub);
  }

  private _registerRefreshSignal(): void {
    const sub = this.signalsService.subscribe('woi-detail:refresh', () => this._fetch());
    this.signalSubscriptions.push(sub);
  }

  private _registerErdvSignal(): void {
    const sub = this.signalsService.subscribe('cancel-erdv', hasToCancelErdv => this.hasToCancelErdv = hasToCancelErdv);
    this.signalSubscriptions.push(sub);
  }

  // This function will add the F2 key shorcut to switch to edition mode
  private _registerQuickEditShorcut(): void {
    this.quickEditShorcutFunc = (event: KeyboardEvent): void => {
      if (event.code === 'F2' && this.mode === 'normal') {
        this.edit();
      }
    };
    // add quick edit shortcut
    document.addEventListener('keyup', this.quickEditShorcutFunc);
  }

  private _cancelQuickEditShortcut(): void {
    document.removeEventListener('keyup', this.quickEditShorcutFunc);
  }

  private _initTabs(): void {
    // If any tab filter must be initialized, it's done here
    this._initInfoTab();
    this._fetchWoiRelationsRatio();
    this._initLinkedWoiTab();
    this._checkShipmentTransitions();
    this._disableAutomaticTransitions();
    this._disableDuplicateButton();
    this.signalsService.broadcast('attachments-list-refresh');
  }

  private _initInfoTab(): void {
    // checking if the woi product has a metadata form
    // if so, we enable the info tab
    const metadataSpec = this.detail.product?.metadata_spec;
    if (metadataSpec && Object.keys(metadataSpec).length > 0) {
      this.detail.metadata_spec = metadataSpec;
      this.showMetadata = true;
    } else {
      this.detail.metadata_spec = {};
      this.showMetadata = false;
    }
    this.cdRef.detectChanges();
  }

  /**
   * fetch entity alerts for this entity, which may block the creation / advancement of WOIs if the
   * alert is of type 'accounting' (blocage compta)
   */
  private _getEntityAlerts(): void {
    this.entityIsBlocked = false;
    this.entityAlerts = [];
    if (!this.detail.work_order || !this.detail.work_order.entity) { return; }

    const filter = {
      is_active: true,
      date_active_now: true,
      entity_code_compat: this.detail.work_order.entity.code
    };
    this.apiShiva.entity_alerts.list(filter).then((res) => {
      this.entityAlerts = res['results'];
      this.entityIsBlocked = this.entityAlerts.some(entityAlert => entityAlert.type === 'accounting');
    });
  }

  private _getMissingTraceabilityForSDA(): void {
    if (!this.detail.traceability && ['P-PNUME', 'P-CNUME'].includes(this.detail.product?.code ) && this.detail?.state?.name === 'new') {
      this.missingTraceabilityForSDA = true;
    } else {
      this.missingTraceabilityForSDA = false;
    }
  }

  private _fetchWoiRelationsRatio(): void {
    this.apiShiva.work_order_items.relations_ratio(this.detail.id)
      .then(res => this.woiRelationRate = res as {done_wois: number, total_wois: number});
  }

  private _initLinkedWoiTab(): void {
    const entityCode = this.detail.work_order?.entity?.code;
    if (!entityCode) {
      return;
    }

    this.linkedWoi = {
      filters: {
        work_order__entity__code: this.detail.work_order.entity.code,
        code__nin: this.detail.code,
        ordering: '-modified_at'
      },
      disableButtons: {
        create: true
      },
      disabledColumns: {
        modified_at: false,
        selection: true,
        work_order__entity__name: true,
        product: true,
        tag: true
      }
    };
  }

  private _checkShipmentTransitions(): void {
    const isShipment = this.detail.product?.code === 'P-EXPED';
    const isInError = this.detail.state.name === 'error';
    if (isShipment && isInError && !this.detail.metadata?.shipment_ref) {
      // If our woi is a shipment in error without a shipment_ref, we disable the resume_tracking transition
      this.detail.state.transitions = this.detail.state.transitions.filter((t) => t.name !== 'resume_tracking');
    }
  }

  private _cancelErdv(): void {
    if (!this.detail.metadata.erdv_ref) { return; }

    const data = { referenceeRDV: this.detail.metadata.erdv_ref };
    this.apiLLPService.cancelAppointment(data)
      .then(() => {
        this.toastr.success('Rendez-vous annulé.');
        this._removeErdvRef();
      });
  }

  private _removeErdvRef(): void {
    delete this.detail.metadata.erdv_ref;
    delete this.detail.metadata.erdv_comment;
    delete this.detail.metadata.erdv_date;
    delete this.detail.metadata.erdv_code_ui;
    delete this.detail.metadata.erdv_statut;
    this.apiShiva.work_order_items.update(this.detail.id, this.detail);
  }

  /**
   * Some product have their workflow handled by the SI (llp, hedwig)
   *  so we must disable the state button for these states when the woi has an automatic processing
   */
  private _disableAutomaticTransitions(): void {
    const productCode = this.detail.product?.code;
    const stateName = this.detail.state?.name;
    const processing = this.detail.processing;

    if (productCode === 'P-EXPED') {
      this.disableState = (processing === 'automatic' && (stateName === 'ready' || stateName === 'ordered'));
    } else if (productCode === 'P-ORC2E') {
      this.disableState = (processing === 'automatic' && (stateName === 'ready'));
    } else if (productCode === 'P-ORFOP') {
      this.disableState = (processing === 'automatic' && (stateName === 'ready'));
    }
  }

  private _disableDuplicateButton(): void {
    this.isAbleToDuplicate = this.detail.product?.code !== 'P-FLASH';
  }

  private _updateBreadcrumbs(): void {
    this.breadcrumbsData = [...this.defaultBreadcrumbsData];

    const entityCode: string = this.detail.work_order?.entity?.code;
    if (entityCode) {
      this.breadcrumbsData.push({
        label: this.detail.work_order.entity.name,
        routerLink: `/entities/detail/${entityCode}`
      });
    }

    const orderCode: string = this.detail.work_order?.order?.code;
    if (orderCode) {
      this.breadcrumbsData.push({
        label: 'Commande',
        routerLink: `/orders-mrp/detail/${orderCode}`
      });
    }

    const woCode: string = this.detail.work_order?.code;
    if (woCode) {
      this.breadcrumbsData.push({
        label: 'Ordre de production',
        routerLink: `/work-orders/detail/${woCode}`
      });
    }

    if (this.detail.code) {
      this.breadcrumbsData.push({
        label: this.detail.code,
        routerLink: `/work-order-items/detail/${this.detail.code}`,
        active: true
      });
    }
  }

  public attachmentsDisabledColumns(): object {
    let attachmentsDisabledColumns = {};
    const productCode = this.detail.product?.code;
    const canManageAttachments = this.userService.hasPermissions('Antoine:CarrierAccountAdmin');
    if (productCode === 'P-EXPED' && !canManageAttachments) {
      attachmentsDisabledColumns = {
        action: true
      };
    }
    return attachmentsDisabledColumns;
  }

  // ----------------------------------------
  // State field confirm function
  // ----------------------------------------
  private _orderStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.orderStateTransition(this.detail);
  }

  private _setCauseKo(): Promise<ITransitionPayload> {
    return this.workOrderItemsStateService.setCauseKo(this.detail);
  }

  private _setInProgress(): Promise<ITransitionPayload> {
    return this.workOrderItemsStateService.setInProgress(this.detail);
  }

  private _setCompletionDateOnFinish(): Promise<ITransitionPayload> {
    return this.workOrderItemsStateService.setCompletionDateOnFinish(this.detail);
  }

  private _sendEmail(): Promise<Record<string, unknown>> {
    return this.workOrderItemsStateService.sendEmail(this.detail);
  }

  private _doneStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.doneStateTransition(this.detail);
  }

  private _waitingCheckNetworkStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.waitingCheckNetworkStateTransition(this.detail);
  }

  private _cancelStateTransition(): Promise<void> {
    return this.workOrderItemsStateService.cancelStateTransition(this.detail);
  }

  private _interventionTagSelector(transitionName: string): Promise<IInterventionTag> {
    return this.workOrderItemsStateService.interventionTagSelector(this.detail, transitionName);
  }

  private _handleEmailError(error: unknown): void {
    this.workOrderItemsStateService.handleEmailError(error);
  }

  private _orderedToWaitingClient(): Promise<unknown> {
    return this.workOrderItemsStateService.orderedToWaitingClientTransition(this.detail);
  }

  private _waitingClientToOrdered(): Promise<unknown> {
    return this.workOrderItemsStateService.waitingClientToOrderedTransition(this.detail);
  }
}
